
import Cookies from 'js-cookie'

const id = Cookies.get('vendor_id')

const state = {
  list: [],
  priceLevels: [],
  
  fetched: true,
  title: null,
  type: null,
  name: null,
  id,
  logo: null,
  show_attr_list: 1,
}


export default state