<template>
  <a-menu-item
    :key="menuInfo.key"
    :disabled="menuInfo.disabled"
    class="d-flex align-items-center"
    :class="{
      'ant-menu-item-selected': selectedMenu,
    }"
    v-bind="$props"
    v-on="$listeners"
  >
    <router-link 
      :to="to(menuInfo.url || '/dashboard')"
    >
      <a-icon :type="setIcon(menuInfo.icon)" class="pr-2" style="font-size: 18px" />

      <span>
        {{ menuInfo.key === 'list-inventoryv2' ? menuInfo.title : titleMenu }} 
      </span>
    </router-link>
  </a-menu-item>
</template>

<script>
import { Menu } from 'ant-design-vue'
// import { getCurrentInstance, reactive } from 'vue'
import useQueryParam from '@/composable/useQueryParam'

import dataItemMenu from './../composables/item'

export default {
  name: 'SubMenu',
  props: {
    ...Menu.Item.props,
    menuInfo: Object,
    styles: Object,
  },
  setup(props) {
    // const instance = getCurrentInstance().proxy

    const { settings, titleMenu, selectedMenu, store_url, setIcon } = dataItemMenu(props)
    const { to } = useQueryParam()
    // const to = () => {
    //   // console.log(props.menuInfo.url)
    //   const { url } = props.menuInfo
    //   const optional_query = props.menuInfo.url.params?.query
    //   const route = typeof url === 'object' ? url : { path: url }

    //   route.query = {
    //     ...to.query,
    //     ...getQueryParam({ ...instance.$route.query }),
    //     ...optional_query,
    //   }

    //   return route
    // }

    return { settings, titleMenu, selectedMenu, store_url, setIcon, to }
  },
}
</script>
