<script>
import { defineComponent, computed } from 'vue'
import { useRoute } from '@/composable/router';

export default defineComponent({
  props: {
    to: {
      type: [Object, String],
      required: true,
    },
    // tag: {
    //   type: String,
    //   default: 'a',
    // },
    // custom: Boolean,
    // exact: Boolean,
    // exactPath: Boolean,
    // append: Boolean,
    // replace: Boolean,
    // activeClass: String,
    // exactActiveClass: String,
    // ariaCurrentValue: {
    //   type: String,
    //   default: 'page',
    // },
  },
  setup(props) {
    const route = useRoute()
    const isExternalLink = computed(() => typeof props.to === 'string' && props.to.startsWith('http'))
    const location = computed(() => {
      if (isExternalLink.value) return props.to
      
      const loc = typeof props.to === 'string' ? { path: props.to, query: {} }  :  { ...props.to, query: { ...(props.to.query || {})}}
      
      // eslint-disable-next-line no-prototype-builtins
      if (!loc.query.hasOwnProperty('workspace_id') && route.query.workspace_id) {
        loc.query.workspace_id = route.query.workspace_id
      }

      // eslint-disable-next-line no-prototype-builtins
      if (!loc.query.hasOwnProperty('business_id') && route.query.business_id) {
        loc.query.business_id = route.query.business_id
      }

      return loc
    })

    return {
      isExternalLink,
      location,
    }
  },
})
</script>

<template>
  <component
    :is="isExternalLink ? 'a' : 'RouterLink'"
    :href="isExternalLink ? location : undefined"
    :to="!isExternalLink ? location : undefined"
  >
    <slot />
  </component>
</template>
