import axiosPB from '@/services/axios'
import { requestToken } from '@/services/web-platform/image/token'
import * as ImageKit from '@/services/web-platform/image/imagekit'

export async function GET_BANNER({ commit }, payload) {
  commit('FETCHING', true)
  commit('SET', {
    key: 'images',
    payload: [],
  })
  const { id } = payload
  const except = ['limit', 'page', 'business_id', 'type_id']
  Object.keys(payload).forEach(
    key => except.includes(key) || delete payload[key],
  )

  const options = {
    method: 'get',
    url: '/channel/store/data/query/images',
    headers: {
      'Content-Type': 'application/json',
      'business-id': payload.business_id,
    },
    params: {
      channel_id: id,
      ...payload,
    },
  }

  try {
    const { data: response } = await axiosPB(options)
    commit('FETCHING', false)
    commit('SET', {
      key: 'images',
      payload: response.data,
    })
    return Promise.resolve(response.data)
  } catch (err) {
    console.error('error get image ', err)
    commit('FETCHING', false)
    commit('SET', {
      key: 'images',
      payload: [],
    })
    return Promise.reject(new Error(err?.response?.message || err?.message))
  }
}

export async function REMOVE_BANNER({ commit }, payload) {
  commit('FETCHING', true)
  const { id, type_id, img_id } = payload
  const except = ['limit', 'page', 'business_id', 'type_id']
  Object.keys(payload).forEach(
    key => except.includes(key) || delete payload[key],
  )

  const options = {
    method: 'delete',
    url: `/channel/store/images/cmd/image/${img_id}/${type_id}`,
    headers: {
      'Content-Type': 'application/json',
      'business-id': payload.business_id,
    },
    params: {
      channel_id: id,
      ...payload,
    },
  }
  try {
    await axiosPB(options)
    commit('FETCHING', false)
    Promise.resolve(true)
  } catch (err) {
    commit('FETCHING', false)
    Promise.reject(err)
  }
}

export async function UPLOAD_BANNER({ rootState }, payload) {
  try {
    const { id, file, type, redirectUrl, business_id } = payload
    const image_redirecturl = redirectUrl
    const except = ['limit', 'page', 'type_id']
    Object.keys(payload).forEach(
      key => except.includes(key) || delete payload[key],
    )

    delete payload.file
    delete payload.redirectUrl

    const payload_img = {
      ...payload,
      business_id,
      channel_id: id,
    }

    const [token] = await requestToken(payload_img)
    // // eslint-disable-next-line no-unused-vars
    const imageKitResponse = await ImageKit.upload({
      file,
      expire: token.expire,
      folder: token.image_path,
      signature: token.signature,
      token: token.token,
      publicKey: token.public_key,
      payload,
    })

    const imageData = {
      image_name: imageKitResponse.name,
      image_size: imageKitResponse.size,
      image_type: imageKitResponse.fileType,
      image_path: token.image_path,
      ref_id: imageKitResponse.fileId,
      image_redirecturl,
    }

    let options
    // Logo
    if (type === 1) {
      const method =
        rootState.web_platform_channel.store_settings?.images?.length === 1
          ? 'put'
          : 'post'
      options = {
        method,
        url: '/channel/store/images/cmd/logo',
        data: {
          ...imageData,
        },
        headers: {
          'Content-Type': 'application/json',
          'business-id': business_id,
        },
        params: {
          channel_id: id,
          ...payload,
        },
      }
    } else {
      options = {
        method: 'post',
        url: '/channel/store/images/cmd/images',
        data: {
          type_id: type,
          image_list: [imageData],
        },
        headers: {
          'Content-Type': 'application/json',
          'business-id': business_id,
        },
        params: {
          channel_id: id,
          ...payload,
        },
      }
    }

    const { data: response } = await axiosPB(options)

    return Promise.resolve(response)
  } catch (err) {
    return Promise.reject(err)
  }
}

export async function CHECKIMAGEBANNER({ commit }, payload) {
  const { id } = payload
  const except = ['limit', 'page', 'business_id', 'type_id']
  Object.keys(payload).forEach(
    key => except.includes(key) || delete payload[key],
  )

  const options = {
    method: 'get',
    url: '/channel/store/data/query/images',
    headers: {
      'Content-Type': 'application/json',
      'business-id': payload.business_id,
    },
    params: {
      channel_id: id,
      ...payload,
    },
  }

  try {
    const { data: response } = await axiosPB(options)
    return Promise.resolve(response.data)
  } catch (err) {
    console.error('error get image ', err)
    return Promise.reject(new Error(err?.response?.message || err?.message))
  }
}