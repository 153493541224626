import * as MutationTypes from './mutation-types'
import Cookies from 'js-cookie'

export default {
  [MutationTypes.SET_CHANNELS]: (state, payload) => {
    state.list = payload
  },
  [MutationTypes.SET_PRICE_LEVEL_DEFAULT_VALUE]: (state, payload) => {
    state.priceLevels = payload
  },

  [MutationTypes.FETCHED]: (state) => {
    state.fetched = false
  },
  [MutationTypes.SET]: (state, { title, type, name, id, logo, show_attr_list }) => {
    Object.assign(state, {
      title,
      type,
      name,
      id,
      logo,
      show_attr_list,
    })
    Cookies.set('vendor_id', id)
  },
}
