import Vue from 'vue'
import VueI18n from 'vue-i18n'
import * as english from './locales/en-US'
import * as indonesian from '@/locales/id-ID'

export const locales = {
  'en-US': english,
  'id-ID': indonesian,
}

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'id-ID',
  fallbackLocale: 'id-ID',
  messages: {
    'en-US': locales['en-US'].default,
    'id-ID': locales['id-ID'].default,
  },
})
