import axios from 'axios'
import store from '@/store'
// import config from '@/config'
import { notification } from 'ant-design-vue'

const client = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  /** @NOTE : Custom options */
  notifications: false,
  consoles: true,
  // customError: '',
})

const openClient = axios.create({
  baseURL: process.env.VUE_APP_OPEN_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  /** @NOTE : Custom options */
  notifications: false,
  consoles: true,
  // customError: '',
})

const initApiClientFastify = axios.create({
  baseURL: process.env.VUE_APP_API_URL_FASTIFY,
  headers: { 'Content-Type': 'application/json' },
})

function normalizeKeysToLowerCase(obj) {
  const normalizedObj = {};
  Object.keys(obj).forEach(key => {
    normalizedObj[key.toLowerCase()] = obj[key];
  });
  return normalizedObj;
}

client.interceptors.request.use(
  request => {
    const setHeader = (key, value) => {
      if (!request.headers[key] && value) {
        request.headers[key] = value
      }
    }
    if (store?.state?.user?.accessToken) {
      setHeader('Authorization', `Bearer ${store.state.user.accessToken}`)
    }
    // setHeader('Business-Id', store.state.user?.businessList?.map(v => v.business_id).join(';'))
    // setHeader('Platform-Id', store.state.user.user_workspaces[0]?.workspace_id)
    
    /* === Di hapus karna error "String contains non ISO-8859-1 code point" === */
    // if (store.state.user.businessList.length) {
    //   request.headers['Business-Id'] = store.state.user.businessList
    //     .map((v) => v.business_id)
    //     .join(';')
    // }

    if (
      store.state.user.workspace_id_active
    ) {
      request.headers['Workspace-Id'] =
      store.state.user.workspace_id_active
    }

    // if (store.state.user.id) {
    //   request.headers['User-Id'] = store.state.user.id
    // }

    const urlParams = new URLSearchParams(window.location.search);
    const business_id = urlParams.get('business_id');
    const normalizeRequestHeaders = normalizeKeysToLowerCase(request.headers)
    if (!normalizeRequestHeaders['business-id'] && business_id) {
      setHeader('business-id', business_id)
    }

    return request
  },
  error => {
    return Promise.reject(error)
  },
)

openClient.interceptors.request.use(
  request => {
    const setHeader = (key, value) => {
      if (!request.headers[key] && value) {
        request.headers[key] = value
      }
    }
    if (store?.state?.user?.accessToken) {
      setHeader('Authorization', `Bearer ${store.state.user.accessToken}`)
    }
    // setHeader('Business-Id', store.state.user?.businessList?.map(v => v.business_id).join(';'))
    // setHeader('Platform-Id', store.state.user.user_workspaces[0]?.workspace_id)
    
    /* === Di hapus karna error "String contains non ISO-8859-1 code point" === */
    // if (store.state.user.businessList.length) {
    //   request.headers['Business-Id'] = store.state.user.businessList
    //     .map((v) => v.business_id)
    //     .join(';')
    // }

    if (
      store.state.user.workspace_id_active
    ) {
      request.headers['Workspace-Id'] =
      store.state.user.workspace_id_active
    }

    // if (store.state.user.id) {
    //   request.headers['User-Id'] = store.state.user.id
    // }

    const urlParams = new URLSearchParams(window.location.search);
    const business_id = urlParams.get('business_id');
    const normalizeRequestHeaders = normalizeKeysToLowerCase(request.headers)
    if (!normalizeRequestHeaders['business-id'] && business_id) {
      setHeader('business-id', business_id)
    }

    return request
  },
  error => {
    return Promise.reject(error)
  },
)

initApiClientFastify.interceptors.request.use(request => {
  if (store.state.user.accessToken) {
    request.headers.Authorization = `Bearer ${store.state.user.accessToken}`
  }

  return request
})

client.interceptors.response.use(
  response => {
    return response
  },
  error => {
    const { config, response } = error || {}
    const { notifications, consoles, customError } = config || {}
    // const { status, error: title, notifications, consoles } = error.response?.data || {}
    
    if (notifications) {
      const { data, statusText, status: state } = response || {}
      const { status, message, error: title } = data || {}
      notification.error({
        message: status || state || error.message,
        description: customError || (title || message ? title || message : message || statusText),
      })
    }

    if (consoles) {
      console.error(error)
    }
    return Promise.reject(error)
  },
)

openClient.interceptors.response.use(
  response => {
    return response
  },
  error => {
    const { config, response } = error || {}
    const { notifications, consoles, customError } = config || {}
    // const { status, error: title, notifications, consoles } = error.response?.data || {}
    
    if (notifications) {
      const { data, statusText, status: state } = response || {}
      const { status, message, error: title } = data || {}
      notification.error({
        message: status || state || error.message,
        description: customError || (title || message ? title || message : message || statusText),
      })
    }

    if (consoles) {
      console.error(error)
    }
    return Promise.reject(error)
  },
)

export const apiClient = client
export const apiClientFastify = initApiClientFastify
export const apiOpenClient = openClient
export default client
