import apiClient from '@/services/axios'

export const downloadFile = ({ businessId, params }) => {
  return apiClient({
    method: 'get',
    responseType: 'blob',
    url: `product/query/${businessId}/export-template`,
    params,
  })
}

export const getProductDetail = ({ id, params }) => {
  return apiClient({
    method: 'get',
    url: `/product/query/${id}/product/detail`,
    params,
  })
}

export const getProductDraft = (business_id) => {
  return apiClient({
    method: 'get',
    url : `/product/query/draft/${business_id}`,
  })
}

export const getProductCategories = (business_id) => {
  return apiClient({
    method: 'get',
    url : '/product/query/categories',
    headers: {
      'Business-Id': business_id,
    },
    params: {
      business_id,
    },
  })
}

export const getProductBrands = (business_id) => {
  return apiClient({
    method: 'get',
    url: '/product/query/brands',
    headers: {
      'Business-Id': business_id,
    },
  })
}

export const getProductCatalog = ({ type, params }) => {
  return apiClient({
    method: 'get',
    url: `/product/query/${type}`,
    params,
  })
}

export const getCategories = (business_id) => {
  return apiClient({
    method: 'get',
    url : 'product/query/multi-mapped-category',
    params: {
      business_id,
    },
  })
}

export const getCatalogList = (params) => {
  return apiClient({
    method: 'get',
    url: 'product/query/catalog',
    headers: {
      'Business-Id': params.business_id,
    },
    params,
  })
}

export const getCategoryList = ({ path = '', params }) => {
  return apiClient({
    method: 'get',
    url: `product/query/channel-categories${path}`,
    headers: {
      'Business-Id': params.business_id,
    },
    params,
  })
}

export const getMappingByCategoryId = (params) => {
  return apiClient({
    method: 'get',
    url: 'product/query/category-master-mapping',
    headers: {
      'Business-Id': params.business_id,
    },
    params,
  })
}

export const getOrderCatalog = ({ signal, params }) => {
  return apiClient({
    signal,
    method: 'get',
    url: '/product/query/orderCatalogs',
    params,
  })
}

export const getProductMasterList = ({ business_id, params }) => {
  return apiClient({
    method: 'get',
    url: '/product/query/',
    headers: {
      'Business-Id': business_id,
    },
    params,
  })
}

export const uploadFile = ({ workspaceId, businessId, formData }) => {
  return apiClient({
    method: 'post',
    url: `product/cmd/${businessId}/upload`,
    headers: {
      'Workspace-Id': workspaceId,
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  })
}

export const uploadProductImage = ({ business_id, product_id, file }) => {
  const form = new FormData()
  form.append('image_file', file)

  return apiClient({
    method: 'post',
    url: `/product/cmd/api/v1/products/${product_id}/upload/image`,
    headers: {
      'Content-Type': 'multipart/form-data',
      'Business-Id': business_id,
    },
    data: form,
    params: { business_id },
  })
}

export const validateSKU = ({ business_id, data }) => {
  return apiClient({
    method: 'post',
    url: 'product/cmd/validate/sku',
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}

export const productWarehouseRelation = (data) => {
  return apiClient({
    method: 'post',
    url: 'product/cmd/product-warehouse-relation',
    data,
  })
}

export const productPublish = (data) => {
  return apiClient({
    method: 'post',
    url: '/product/cmd/api/v1/products/',
    data,
  })
}

export const updateCatalogsProduct = (data) => {
  return apiClient({
    method: 'post',
    url: `/product/cmd/api/v1/products/${data.product_id}/catalog-bundling`,
    data,
    params: {
      business_id: data.business_id,
    },
  })
}

export const crudProduct = ({ is_new, ...data }) => {
  const method = is_new ? 'post' : 'put'
  const path = is_new ? 'details' : `${data.id}/product/update`

  return apiClient({
    method,
    url: `/product/cmd/api/v1/products/${path}`,
    headers: {
      'Business-Id': data.business_id,
    },
    data,
    params: {
      business_id: data.business_id,
    },
  })
}

export const addProductChannel = ({ business_id, product_id, data }) => {
  return apiClient({
    method: 'put',
    url: `product/cmd/api/v1/products/${product_id}/channel/update`,
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}

export const saveProductImages = (data) => {
  return apiClient({
    method: 'put',
    url: `/product/cmd/api/v1/products/${data.product_id}/image-update`,
    data,
    params: {
      business_id: data.business_id,
    },
  })
}

export const deleteProduct = async (product_id) => {
  return apiClient({
    method: 'DELETE',
    url: `/product/cmd/${product_id}`,
  })
}
