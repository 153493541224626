import { types as MutationTypes } from '@/store/user/mutations'
import Cookies from 'js-cookie'
import { i18n } from '@/i18n'
import router from '@/router'
import { decode } from 'jsonwebtoken'
import { signUp, signIn, userVerification, userInit, inviteUserConfirmation, requestOtp, getUserMenu } from '@/api/user'
import { getBusiness } from '@/api/business'
import { getWarehouses } from '@/api/warehouse'

export const SIGNUP = async ({ commit }, values) => {
  try {
    commit(MutationTypes.SET_LOADING, true)

    const response = await signUp({
      user: values.user_login,
      password: values.password,
      name: values.name,
      business_name: values.business_name ? values.business_name : '',
    })

    const { data, otp } = response.data

    Cookies.set('user', JSON.stringify(data.user))
    Cookies.set(
      'otpResendDelay',
      values.typeRegister === 'phone' ? JSON.stringify(otp.resend_delay) : null,
    )
    Cookies.set('typeRegister', values.typeRegister)

    commit(MutationTypes.SET_USER_STORE, {
      uuid: data.user.uuid,
      email: data.user.email,
      phone: data.user.phone,
      firstName: data.user.first_name,
      lastName: data.user.last_name,
      typeRegister: values.typeRegister,
      otpResendDelay: values.typeRegister === 'phone' ? otp.resend_delay : null,
    })

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_LOADING, false)
  }
}

export const VERIFICATIONEMAIL = async (
  { commit },
  { uuid, code },
) => {
  try {
    commit(MutationTypes.SET_LOADING, true)
    const response = await userVerification({
      type: 'email',
      data: {
        user_uuid: uuid,
        code,
      },
    })

    const { data } = response.data

    commit(MutationTypes.SET_USER_STORE, {
      uuid: data.login.user.uuid,
      email: data.login.user.email,
      phone: data.login.user.phone,
      firstName: data.login.user.first_name,
      lastName: data.login.user.last_name,
      accessToken: data.login.token,
    })

    Cookies.set('user', JSON.stringify(data.login.user))
    Cookies.set('accessToken', data.login.token)

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_LOADING, false)
  }
}

export const VALIDATEPHONENUMBER = async (
  { commit },
  { user_uuid, otp_code, login = true },
) => {
  try {
    commit(MutationTypes.SET_LOADING, true)
    const response = await userVerification({
      type: 'phone',
      data: {
        user_uuid,
        otp_code,
        login,
      },
    })

    const { data } = response.data

    commit(MutationTypes.SET_USER_STORE, {
      uuid: data.login.user.uuid,
      email: data.login.user.email,
      phone: data.login.user.phone,
      firstName: data.login.user.first_name,
      lastName: data.login.user.last_name,
      accessToken: data.login.token,
    })

    Cookies.set('user', JSON.stringify(data.login.user))
    Cookies.set('accessToken', data.login.token)

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_LOADING, false)
  }
}

export const RESENDOTP = async (
  { commit },
  { user_uuid },
) => {
  try {
    commit(MutationTypes.SET_LOADING, true)
    const response = await userVerification({
      type: 'resend-otp',
      data: {
        user_uuid,
      },
    })

    const { data } = response.data

    commit(MutationTypes.SET_USER_STORE, {
      otpResendDelay: data.resend_delay,
    })
    Cookies.set('otpResendDelay', data.resend_delay)
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_LOADING, false)
  }
}

export const LOGOUT = async ({ commit }) => {
  try {
    Cookies.remove('user')
    Cookies.remove('accessToken')
    Cookies.remove('otpResendDelay')
    Cookies.remove('typeRegister')
    Cookies.remove('business')
    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.LOGOUT, {
      loading: false,
      accessToken: null,
      expireIn: 0,
    })
  }
}

export const LOGINPROCESSING = async (
  { commit, dispatch }, 
  { token, key = '', routeId = '' },
) => {
  try {
    commit(MutationTypes.SET_LOADING, true)

    commit(MutationTypes.SET_USER_STORE, {
      accessToken: token,
    })

    commit(MutationTypes.SET_USER_KEY, key)
    // decode token from keycloak to get uid
    const decodeToken = await decode(token)
    const uid = decodeToken.sub
    const param = Cookies.get('invite-user')

    if (param !== 'true') {
      const { data: response } = await userInit(uid)

      // remove duplicate workspace_id
      if (response?.data?.user_workspaces) {
        const seen = new Set()
        response.data.user_workspaces = response.data.user_workspaces.filter(el => {
          const duplicate = seen.has(el.workspace_id)
          seen.add(el.workspace_id)
          return !duplicate
        })
      }

      // set to store
      commit(MutationTypes.SET_USER_STORE, {
        ...response?.data,
      })

      const search = new URLSearchParams(window.location.search)
      const workspace_id = key ? key : search.get('workspace_id')
      let findWorkspace = response.data.user_workspaces?.find(val => {
        if (workspace_id) {
          return val.workspace_id === workspace_id
        } else {
          return val.is_default
        }
      })

      if (!findWorkspace.workspace_id) findWorkspace = response.data.user_workspaces[0]

      dispatch('SETACTIVEUSER', findWorkspace)

      dispatch('GETBUSINESSLISTDATA', { ...findWorkspace, key, routeId, uid: response?.data?.id })

      Cookies.set('invite-user', null)
      Cookies.set('accessToken', token)
      Cookies.set(
        'user',
        JSON.stringify({
          ...response?.data,
        }),
      )

      return Promise.resolve(response)
    } else {
      Cookies.set('accessToken', token)
    }

    // set to cookie.
    Cookies.set('accessToken', token)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_LOADING, false)
  }
}

export const SIGNINV2 = async ({ commit, dispatch }, values) => {
  try {
    commit(MutationTypes.SET_LOADING, true)

    const { data: response } = await signIn({
      username: values.user_login,
      password: values.password,
    })

    await dispatch(
      'user/LOGINPROCESSING',
      { token: response.access_token },
      { root: true },
    )

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_LOADING, false)
  }
}

export const SUBMITREGISTERUSERINVITATION = async (
  { commit },
  payload,
) => {
  try {
    commit(MutationTypes.SET_USER_STORE, {
      loading: true,
    })

    const { data: response } = await inviteUserConfirmation(payload)

    const { data } = response

    const dataUserInvited = {
      id: data.id,
      expired_at: data.expired_at,
      uuid: payload.uuid,
      password: payload.password,
      invited_via: data.invited_via,
      email: data.email,
      phone_number: data.phone_number,
    }

    Cookies.set('dataUserInvited', JSON.stringify(dataUserInvited))

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_USER_STORE, {
      loading: false,
    })
  }
}

export const REQUESTFORGOTPASSWORD = async (
  { commit },
  { username, type, setup_password_url },
) => {
  try {
    commit(MutationTypes.SET_USER_STORE, {
      loading: true,
    })

    const { data: response } = await requestOtp({
      type: 'request-otp',
      data: {
        username,
        type,
        setup_password_url,
      },
    })

    Cookies.set(
      'dataResponseRequestForgotPassword',
      JSON.stringify({
        ...response.data,
        type,
        otpSendTo: username,
      }),
    )

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_USER_STORE, {
      loading: false,
    })
  }
}

export const VERIFYOTPFORGOTPASSWORD = async (
  { commit },
  { username, otp },
) => {
  try {
    commit(MutationTypes.SET_USER_STORE, {
      loading: true,
    })

    const { data: response } = await requestOtp({
      type: 'verify-otp',
      data: {
        username,
        otp,
      },
    })

    commit(MutationTypes.SET_USER_STORE, {
      accessToken: response.data.token,
      username: response.data.username,
    })

    Cookies.set('accessToken', response.data.token)

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_USER_STORE, {
      loading: false,
    })
  }
}

export const GETUSERMENU = async ({ commit }, payload) => {
  try {
    commit(MutationTypes.SET_USER_STORE, {
      loading: true,
    })

    const { data: response } = await getUserMenu(payload)

    commit(MutationTypes.SET_USER_MENU, response.data?.menus || [])

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_USER_STORE, {
      loading: false,
    })
  }
}

/* Set Business list after change workspace */
export const GETBUSINESSLISTDATA = async ({ commit, dispatch }, payload) => {
  try {
    commit(MutationTypes.SET_USER_STORE, {
      loading: true,
    })

    const params = {
      [`${payload?.restriction_base.toLowerCase()}_id`]:
        payload?.restriction_list.toString(),
    }
    const { data } = payload?.restriction_base !== 'Business'
      ? await getWarehouses({ params })
      : await getBusiness(params)
    
    let result =
      payload?.restriction_base !== 'Business' ? data.data.data : data.data

    let inject = []

    if (payload?.restriction_level !== 'Business') {
      /* Inject Data */
      inject = [
        {
          business_id: 'all',
          business_name: i18n.t(`topBar.all_${payload?.restriction_base}`),
        },
      ]

      /* If Not business restictiction base */
      if (payload?.restriction_base !== 'Business') {
        // result = result.data
        inject = [
          {
            warehouse_id: 'all',
            name: i18n.t(`topBar.all_${payload?.restriction_base}`),
          },
        ]
      }

      if (result?.length === 1) {
        inject = []
      }
    }
    
    const res = !result ? [] : result.concat(inject)
    const current = new URL(window.location.href)
    const isBusiness = !payload.key ? current.search.includes('business_id') : false
    const isWarehouse = !payload.key ? current.search.includes('warehouse_id') : false
    const businessId = isBusiness
      ? current.searchParams.get('business_id')
      : result[0]?.business_id
    const warehouseId = isWarehouse
      ? current.searchParams.get('warehouse_id')
      : result[0]?.warehouse_id

    let type = payload.restriction_base === 'Business' 
      ? 'business_id' 
      : 'warehouse_id'
        
    /* If role name as store Operator redirect to default business */
    if (payload?.role_name === 'Store Operator' && !isBusiness) {
      type = 'business_id'
    }

    /* If role name as warehouse Operator redirect to default warehouse */
    if (payload?.role_name === 'Warehouse Operator' && !isWarehouse) {
      type = 'warehouse_id'
    }

    /* if only one restriction list, auto redirect to business or warehouse */
    if (result?.length === 1) {
      if (payload.restriction_base === 'Business' && isWarehouse) {
        type = 'business_id'
      } else if (payload.restriction_base === 'Warehouse' && isBusiness) {
        type = 'warehouse_id'
      } else if (isBusiness && result[0]?.business_id !== current.searchParams.get('business_id')) {
        type = 'business_id'
      } else if (isWarehouse && result[0]?.warehouse_id !== current.searchParams.get('warehouse_id')) {
        type = 'business_warehouse'
      }
    } else if (result?.length > 1) {
      if (!isWarehouse && !isBusiness) {
        type = ''
      }
    }

    const property = type === 'business_id' || type === 'business_warehouse'
      ? 'business_id'
      : type === 'warehouse_id'
      ? 'warehouse_id'
      : ''
    const value = type === 'warehouse_id' || type === 'business_warehouse'
      ? warehouseId 
      : businessId
    const query = { workspace_id: payload?.workspace_id }

    dispatch('GETUSERMENU', {
      user_id: payload?.uid,
      workspace_id: payload?.workspace_id,
      all_business:
        (property && value) ||
        payload?.restriction_base === 'Warehouse'
          ? false
          : true,
    })
    
    if (property) {
      query[property] = value
      const business = property === 'business_id'
        ? value
        : res.filter(val => val.warehouse_id === value)[0]?.business_id
      if (business) dispatch('business/LISTCHANNEL', { business_id: business }, { root: true })
    }
    
    if (
      current.searchParams.get('workspace_id') !== query.workspace_id || 
      current.searchParams.get(property) !== query[property]
    ) {
      const path = current.pathname === '/' || payload.routeId
        ? '/dashboard'
        : current.pathname.includes('integration') || current.pathname.includes('/confirmation/registered/success') || current.pathname.includes('/inventory/')
        ? current.pathname
        : `/${current.pathname.split('/')[1]}`
      router.push({ 
        path,
        query,
      })
    }
    commit(MutationTypes.SET_BUSINESS_LIST, res)
    return Promise.resolve(res)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_USER_STORE, {
      loading: false,
    })
  }
}

/* Set active workspace id & Role name */
export const SETACTIVEUSER = async ({ commit }, payload) => {
  commit(MutationTypes.SET_WORKSPACE_ID_ACTIVE, payload?.workspace_id)
  commit(MutationTypes.SET_WORKSPACE_ID_ACTIVE_NAME, payload?.workspace_name)
  commit(MutationTypes.SET_RESTRICTION_BASE, payload?.restriction_base)
  commit(MutationTypes.SET_ROLE_ID, payload?.role_id)
  commit(MutationTypes.SET_ROLE_NAME, payload?.role_name)
}
