/**
 * Moment Plugin Install
 *
 * @param {import("vue/types/umd").VueConstructor} Vue Vue instance
 * @param {*} opts moment options
 */
function install (Vue, opts = {}) {
  const moment = opts && opts.moment ? opts.moment : require('moment')

  Object.defineProperties(Vue.prototype, {
    $moment: {
      get() {
        return moment
      },
    },
  })

  Vue.moment = moment
}

export default {
  install,
}
