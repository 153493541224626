import { extend } from 'vee-validate'
import {
  required,
  alpha,
  numeric,
  alpha_num,
  email,
  ext,
  size,
  length,
  max,
  min,
  min_value,
  max_value,
  excluded,
  regex,
} from 'vee-validate/dist/rules'
import { i18n } from './i18n'

const rules = {
  required,
  alpha,
  numeric,
  alpha_num,
  email,
  ext,
  size,
  length,
  max,
  min,
  excluded,
  min_value,
  max_value,
  regex,
  no_space: {
    validate: (value) => /^\S+$/.test(String(value)),
    params: [],
  },
}

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule],
    message: (_, values) =>  i18n.t(`validations.${rule}`, values),
  })
})
