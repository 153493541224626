import apiClient from '@/services/axios'

export const shopeeImageAttribute = ({ business_id, channel_code, channel_id, params }) => {
  return apiClient({
    method: 'get',
    url: `/channel/${channel_code}/product/query/${channel_id}/draft/image/`,
    headers: {
      'Business-Id': business_id,
    },
    params,
  })
}

export const getProductVariantShopee = ({ channel_id, business_id, params }) => {
  return apiClient({
    method: 'get',
      url: `channel/shopee_id/product/query/${channel_id}/attributes`,
      headers: {
        'Business-Id': business_id,
      },
      params,
  })
}

export const getProductBrandShopee = ({ channel_id, business_id, params }) => {
  return apiClient({
    method: 'get',
      url: `channel/shopee_id/product/query/${channel_id}/brand-list`,
      headers: {
        'Business-Id': business_id,
      },
      params,
  })
}

export const getShopeeWarehouseChannelList = ({ channel_id }) => {
  return apiClient({
    method: 'get',
    url: `/shopee-int/warehouse/list/${channel_id}`,
  })
}

export const getWarehouseShopee = ({ channel_id, business_id }) => {
  return apiClient({
    method: 'get',
    url: `/shopee-int/warehouse/map/list/${channel_id}`,
    headers: {
      'Business-Id': business_id,
    },
  })
}