<template>
  <div
    :class="{
      cui__layout__grayBackground: settings.isGrayBackground,
    }"
  >
    <a-layout
      class="cui__layout__grayBackground"
      :class="{
        cui__layout__contentMaxWidth: settings.isContentMaxWidth,
        cui__layout__appMaxWidth: settings.isAppMaxWidth,
        cui__layout__squaredBorders: settings.isSquaredBorders,
        cui__layout__cardsShadow: settings.isCardShadow,
        cui__layout__borderless: settings.isBorderless,
      }"
    >
      <a-layout>
        <!-- ========= New Sidebar ======== -->
        <sidebar-v2 :collapsed="collapsed" @change="onCollapse" />

        <a-layout-content>
          <!-- ========= New Navbar ======== -->
          <navbar-v2 :collapsed="collapsed" @change="onCollapse" />
          <div class="content">
            <transition :name="settings.routerAnimation" mode="out-in">
              <router-view />
            </transition>
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import { mapState } from 'vuex'
/* === New Block === */
import NavbarV2 from '@/components/cleanui/layout/Navbar-v2'
import SidebarV2 from '@/components/cleanui/layout/Sidebar-v2'

export default {
  name: 'MainLayout',
  components: {
    NavbarV2,
    SidebarV2,
  },
  data: function () {
    return {
      touchStartPrev: 0,
      touchStartLocked: false,
      widthContent: 0,
      collapsed: window.screen.width < 800 ? true : false,
    }
  },
  computed: mapState(['settings']),
  watch: {
    $route() {
      this.$store.commit('settings/CHANGE_SETTING', {
        setting: 'isMobileMenuOpen',
        value: false,
      })
      if(window.screen.width < 800) this.collapsed = true
    },
    'settings.isMenuCollapsed'() {
      this.calculateWidthContent()
    },
  },
  mounted() {
    this.bindMobileSlide()
    this.detectViewPort(true)
    window.addEventListener('resize', this.detectViewPortListener)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.detectViewPortListener)
  },
  methods: {
    onCollapse(value) {
      this.collapsed = value
    },
    calculateWidthContent() {
      this.widthContent = this.settings.isMenuCollapsed
        ? window.innerWidth - 80
        : window.innerWidth - 230
    },
    toggleMobileMenu() {
      const value = !this.settings.isMobileMenuOpen
      this.$store.commit('settings/CHANGE_SETTING', {
        setting: 'isMobileMenuOpen',
        value,
      })
    },
    changeSetting: function (setting, value) {
      this.$store.commit('settings/CHANGE_SETTING', { setting, value })
    },
    setViewPort: function (isMobileView = false, isTabletView = false) {
      this.$store.commit('settings/CHANGE_SETTING', {
        setting: 'isMobileView',
        value: isMobileView,
      })
      this.$store.commit('settings/CHANGE_SETTING', {
        setting: 'isTabletView',
        value: isTabletView,
      })
    },
    detectViewPortListener: function () {
      this.detectViewPort(false)
    },
    detectViewPort: function (firstLoad = false) {
      const isMobile = this.settings.isMobileView
      const isTablet = this.settings.isTabletView
      const width = window.innerWidth
      const state = {
        next: {
          mobile: width < 768,
          tablet: width < 992,
          desktop: !(width < 768) && !(width < 992),
        },
        prev: {
          mobile: isMobile,
          tablet: isTablet,
          desktop: !isMobile && !isTablet,
        },
      }
      // desktop
      if (
        state.next.desktop &&
        (state.next.desktop !== state.prev.desktop || firstLoad)
      ) {
        this.setViewPort(false, false)
      }
      // tablet & collapse menu
      if (
        state.next.tablet &&
        !state.next.mobile &&
        (state.next.tablet !== state.prev.tablet || firstLoad)
      ) {
        this.setViewPort(false, true)
        this.$store.commit('settings/CHANGE_SETTING', {
          setting: 'isMenuCollapsed',
          value: false,
        }) // TODO: to be updated. its just for dummy for set this to false
      }
      // mobile
      if (
        state.next.mobile &&
        (state.next.mobile !== state.prev.mobile || firstLoad)
      ) {
        this.setViewPort(true, false)
      }

      this.calculateWidthContent()
    },
    bindMobileSlide() {
      // mobile menu touch slide opener
      const unify = (e) => {
        return e.changedTouches ? e.changedTouches[0] : e
      }
      document.addEventListener(
        'touchstart',
        (e) => {
          const x = unify(e).clientX
          this.touchStartPrev = x
          this.touchStartLocked = x > 70
        },
        { passive: false },
      )
      document.addEventListener(
        'touchmove',
        (e) => {
          const x = unify(e).clientX
          const prev = this.touchStartPrev
          if (x - prev > 50 && !this.touchStartLocked) {
            this.toggleMobileMenu()
            this.touchStartLocked = true
          }
        },
        { passive: false },
      )
    },
  },
}
</script>

<style lang="scss" module>
@import './style-v2.module.scss';
</style>

<style lang="scss">
#navbar {
  .col-2 {
    display: none;
  }
}

@media (max-width: 800px) {
  #navbar {
    .col-2 {
      display: block;
    }

    .col-4 {
      width: 50%;
      max-width: 50%;
      flex: 0 0 50%;
      -ms-flex: 0 0 50%;
      padding-left: 0 !important;
      padding-right: 0 !important;

      & > div {
        width: 100% !important;
      }
    }

    .col-8 {
      display: block !important;
      width: 33.3333%;
      max-width: 33.3333%;
      flex: 0 0 33.3333%;
      -ms-flex: 0 0 33.3333%;

      .ant-btn {
        width: 100%;

        .ant-icon-down {
          margin: 0;
        }
      }
    }
  }  

  .content {
    padding: 10px 18px !important;
  }

  .sidebar {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;

    &.is-collapsed {
      display: none;
    }

    .ant-layout-sider {
      width: 100% !important;
      min-width: 100% !important;
      max-width: 100% !important;
      padding: 10px 15px !important;

      &-trigger {
        width: 100% !important;
      }
    }
  }
}
</style>
