const INIT_VALUE = {
  loading: false,
  isAddChannel: false,
  channel: null,
  model: null,
  count: 0,
  existingChannels: [],
  channels: [],
  success: [],
  failed: [],
  status: {
    code: '',
    message: '',
  },
}

export const types = {
  SET_ADD_CHANNEL: 'SET_ADD_CHANNEL',
  SET_CHANNEL: 'SET_CHANNEL',
  SET_MODEL: 'SET_MODEL',
  SET_COUNT: 'SET_COUNT',
  SET_EXISTING_CHANNELS: 'SET_EXISTING_CHANNELS',
  SET_CHANNELS: 'SET_CHANNELS',
  SET_SUCCESS: 'SET_SUCCESS',
  SET_FAILED: 'SET_FAILED',
  SET_STATUS: 'SET_STATUS',
  RESET_ALL: 'RESET_ALL',
}
  
const mutations = {
  [types.SET_ADD_CHANNEL]: (state, payload) => {
    state.isAddChannel = payload
  },
  [types.SET_CHANNEL]: (state, payload) => {
    state.channel = payload
  },
  [types.SET_MODEL]: (state, payload) => {
    state.model = payload
  },
  [types.SET_COUNT]: (state, payload) => {
    state.count = payload
  },
  [types.SET_EXISTING_CHANNELS]: (state, payload) => {
    state.existingChannels = payload
  },
  [types.SET_CHANNELS]: (state, payload) => {
    state.channels = payload
  },
  [types.SET_SUCCESS]: (state, payload) => {
    state.success = payload
  },
  [types.SET_FAILED]: (state, payload) => {
    state.failed = payload
  },
  [types.SET_STATUS]: (state, payload) => {
    state.status = payload
  },
  [types.RESET_ALL]: (state) => {
    Object.assign(state, {
      ...INIT_VALUE,
    })
  },

}
  
export default mutations
  