export const types = {
  SET_BUSINESS_STORE: 'SET_BUSINESS_STORE',
}

const mutations = {
  [types.SET_BUSINESS_STORE]: (state, payload) => {
    Object.assign(state, {
      ...payload,
    })
  },
}

export default mutations
