import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import * as actions from './actions'
import Cookies from 'js-cookie'

Vue.use(Vuex)

const businessCookies = Cookies.get('business')
  ? JSON.parse(Cookies.get('business'))
  : null

const state = {
  businesses: [],
  business_list: businessCookies?.business_list,
  business_id_active: businessCookies?.business_id_active,
  platform_id: 'p6GYvPqk1J',
  loading: false,
  fetchingListChannel: false,
  listChannels: [],
  detailBusiness: {},
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
