import state from './state'
import * as actions from './actions'
import * as mutations from './mutations'

/**
 * @type {import('vuex').Module} store
 */
const store = {
  namespaced: true,
  state,
  actions,
  mutations,
}

export default store
