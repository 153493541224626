import Vue from 'vue'
import Vuex from 'vuex'
import * as actions from './actions'
import mutations from './mutations'

Vue.use(Vuex)

const state = {
  fromSuccessRegister: false,
  loadingSync: false,
  modalAddShop: false,
  modalSuccessAddShop: false,
  modalIntegrationAddWarehouse: false,
  marketplace: {},
  listShop: null,
  message: null,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
