import Vue from 'vue'
import Vuex from 'vuex'
import * as actions from '@/store/product-channel/actions'
import mutations from '@/store/product-channel/mutations'

Vue.use(Vuex)

const state = {
  loading: false,
  productId: null,
  products: [],
  props: {},
  syncProducts: [],
}

const getters = {
  getProductById: state => id => state.products.find(product => product.id === id || product.product_id === id),
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
