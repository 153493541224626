import Vue from 'vue'
import Vuex from 'vuex'
import * as actions from './actions'
import mutations from './mutations'

Vue.use(Vuex)

const state = {
  loading: false,
  exportFileNotification: false,
  showModalExportSales: false,
  loadingExport: false,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
