import { types as MutationTypes } from './mutations'
import debounce from 'lodash/debounce'
import { getWarehouseList } from '@/api/warehouse'
import { getInventories, getInboundList, syncStock, checkSyncStock } from '@/api/inventory'
import { getWarehouseChannelList, getConnectedWarehouseChannelList } from '@/api/channels/index'
import { getShopeeWarehouseChannelList } from '@/api/channels/shopee'
import { getLazadaWarehouseChannelList } from '@/api/channels/lazada'
import { getBlibliPickupPoint } from '@/api/channels/blibli'

export const GETSALESWAREHOUSE = async ({ commit }, params) => {
  let data = ''
  try {
    commit(MutationTypes.SET_WAREHOUSE_STORE, {
      loading: true,
    })

    const response = await getWarehouseList(params)
    data = response?.data || {}

    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_WAREHOUSE_STORE, {
      warehouse: data?.data || {},
      loading: false,
    })
  }
}

export const GETWAREHOUSELIST = async (
  { commit }, { business_id = '', warehouse_id = undefined, channel_code = '' },
) => {
  let data = ''
  try {
    commit(MutationTypes.SET_WAREHOUSE_STORE, {
      loading: true,
    })
    const { data: response} = await getWarehouseList({ business_id, warehouse_id, channel_code })
    data = await response.data.map((obj) => {
      return {
        ...obj,
        value: obj.id,
        label: obj.name,
        checked: true,
      }
    })
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_WAREHOUSE_STORE, {
      loading: true,
      warehouseList: data ?? [],
    })
  }
}

export const GETINVENTORYLIST = async (
  { commit }, { page = 1, size = 10, status, warehouseId, business_id, sort },
) => {
  try {
    commit(MutationTypes.SET_WAREHOUSE_STORE, {
      loading: true,
    })

    const { data: response} = await getInventories({
      page,
      size,
      status,
      warehouseId,
      business_id,
      sort,
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_WAREHOUSE_STORE, {
      loading: false,
    })
  }
}

export const GETINBOUNDLIST = async (
  { commit }, { page = 1, size = 10, type, warehouseId, business_id, catalog_id, sort },
) => {
  try {
    commit(MutationTypes.SET_WAREHOUSE_STORE, {
      loading: true,
    })

    let parameter = {
      page,
      size,
      type,
      warehouse_id: warehouseId,
      business_id,
      catalog_id,
      sort,
    }

    if (!parameter.catalog_id) {
      delete parameter.catalog_id
    }

    const { data: response} = await getInboundList(parameter)
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_WAREHOUSE_STORE, {
      loading: false,
    })
  }
}

export const GETWAREHOUSECHANNELLIST = async (
  { commit }, { channel_id, sales_channel },
) => {
  let data = ''
  try {
    commit(MutationTypes.SET_WAREHOUSE_STORE, {
      loading: true,
    })
    const { data: response} = await getWarehouseChannelList({ sales_channel, channel_id })
    if (sales_channel === 'tiktok') {
      data = response?.data?.warehouses.map((obj) => {
        return {
          value: String(obj.warehouse_id),
          label: obj.warehouse_name,
        }
      })
    } else {
      data = response[0]?.warehouses.map((obj) => {
        return {
          value: String(obj.warehouse_id),
          label: obj.warehouse_name,
        }
      })
    }
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_WAREHOUSE_STORE, {
      loading: true,
      warehouseChannelList: data ?? [],
    })
  }
}

export const GETSHOPEEWAREHOUSECHANNELLIST = async (
  { commit }, { channel_id },
) => {
  let data = ''
  try {
    commit(MutationTypes.SET_WAREHOUSE_STORE, {
      loading: true,
    })
    const { data: response} = await getShopeeWarehouseChannelList({ channel_id })
    data = response[0]?.warehouses.map((obj) => {
      return {
        value: String(obj.locationId),
        label: obj.warehouseName,
      }
    })
    if (!data) {
      data = [
        {
          value: '',
          label: 'Default Warehouse',
        },
      ]
    }
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_WAREHOUSE_STORE, {
      loading: true,
      warehouseChannelList: data ?? [],
    })
  }
}

export const GETLAZADAWAREHOUSECHANNELLIST = async (
  { commit }, { channel_id },
) => {
  let data = ''
  try {
    commit(MutationTypes.SET_WAREHOUSE_STORE, {
      loading: true,
    })
    const { data: response} = await getLazadaWarehouseChannelList({ channel_id })
    data = response?.data.map((obj) => {
      return {
        value: String(obj.channel_warehouse_id),
        label: obj.name || 'Default Warehouse',
      }
    })
    if (!data) {
      data = [
        {
          value: '',
          label: 'Default Warehouse',
        },
      ]
    }
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_WAREHOUSE_STORE, {
      loading: true,
      warehouseChannelList: data ?? [],
    })
  }
}

export const GETBLIBLIWAREHOUSECHANNELLIST = async (
  { commit }, { business_id, channel_id },
) => {
  let data = ''
  try {
    commit(MutationTypes.SET_WAREHOUSE_STORE, {
      loading: true,
    })
    const { data: response} = await getBlibliPickupPoint({ business_id, channel_id })
    data = response?.data.map((obj) => {
      return {
        value: String(obj.id),
        label: obj.display_name || 'Default Warehouse',
      }
    })
    if (!data) {
      data = [
        {
          value: '',
          label: 'Default Warehouse',
        },
      ]
    }
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_WAREHOUSE_STORE, {
      loading: true,
      warehouseChannelList: data ?? [],
    })
  }
}

export const GETCONNECTEDWAREHOUSECHANNELLIST = async (
  { commit }, { channel_id, sales_channel, business_id },
) => {
  let data = ''
  try {
    commit(MutationTypes.SET_WAREHOUSE_STORE, {
      loading: true,
    })
    const { data: response} = await getConnectedWarehouseChannelList({ sales_channel, channel_id, business_id })
    data = response
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_WAREHOUSE_STORE, {
      loading: true,
      warehouseChannelList: data ?? [],
    })
  }
}

export const SYNCWAREHOUSESTOCK = async ({ commit, dispatch }, payload) => {
  commit(MutationTypes.SET_SYNCING, true)

  let requests = []
  let warehouses = []
  payload.warehouses.forEach(warehouse => {
    warehouses.push(warehouse.key)
    requests.push(syncStock({
      business_id: payload.business_id,
      params: {
        warehouse_id: warehouse.key,
      },
    }))
  })

  commit(MutationTypes.SET_WAREHOUSES, warehouses)
  await Promise.all(requests)
    .then(() => dispatch('CHECKSYNCWAREHOUSESTOCK', payload))
    .catch(err => {
      commit(MutationTypes.SET_SYNCING, false)
      console.error(err)
    })
}

export const CHECKSYNCWAREHOUSESTOCK = debounce(async ({ commit, dispatch }, payload) => {
  let requests = []
  payload.warehouses.forEach(warehouse => {
    requests.push(checkSyncStock({
      business_id: payload.business_id,
      params: {
        warehouse_id: warehouse.key,
      },
    }))
  })
  await Promise.all(requests)
    .then(response => {
      if(response.some(res => res.data.status === 'ONPROGRESS')) {
        dispatch('CHECKSYNCWAREHOUSESTOCK', payload)
      } else {
        let success = []
        let error = []
        response.forEach((res, index) => {
          if(res.data.status === 'SUCCESS') {
            success.push(payload.warehouses[index].label)
          } else {
            error.push(payload.warehouses[index].label)
          }
        })
        commit(MutationTypes.SET_STATUS, {
          success,
          error,
        })
        commit(MutationTypes.SET_WAREHOUSES, [])
        commit(MutationTypes.SET_MESSAGE, true)
        commit(MutationTypes.SET_SYNCING, false)
      }
    })
    .catch(err => {
      commit(MutationTypes.SET_SYNCING, false)
      console.error(err)
    })
}, 1000)
