export const types = {
  SET_STATE_EXPORT_DATA: 'SET_STATE_EXPORT_DATA',
}

const mutations = {
  [types.SET_STATE_EXPORT_DATA]: (state, payload) => {
    Object.assign(state, {
      ...payload,
    })
  },
}

export default mutations
