import Vue from 'vue'
import Vuex from 'vuex'
import * as actions from '@/store/products/actions'
import mutations from '@/store/products/mutations'

Vue.use(Vuex)

const state = {
  loading: false,
  channel: false,
  request_id: false,
  products: [],
  categories: [],
  brands: [],
  productDetailToAdd: {},
  productCatalogToAdd: [],
  productImagesToAdd: [],
  productSuggestion: [],
  detailProducts: {},
  productChannelToAdd: {},
  productCatalogAttributes: {},
  productCatalogOptions: [],
  backgroundNotificationAdjustWarehouseProduct: false,
  showModalAdjustWarehouseProduct: false,
  loadingAdjustWarehouseProduct: false,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
