export default {
  methods: {
    getQueryParam(param) {
      let filtered = {}
      const allowed = ['workspace_id', 'business_id', 'warehouse_id']

      for(const prop in param) {
        if(allowed.includes(prop)) filtered = { ...filtered, [prop]: param[prop] }
      }
      
      return filtered
    },
  },
}
