import { i18n } from '@/i18n';
const CURRENCIES = [
  {
    value: 'IDR',
    label: 'Rupiah',
    symbol: 'Rp',
    id: 111,
  },
  {
    value: 'MYR',
    label: 'Ringgit Malaysia',
    symbol: 'RM',
    id: 144,
  },
  {
    value: 'SGD',
    label: 'Singapore Dollar',
    symbol: 'S$',
    id: 212,
  },
  {
    value: 'USD',
    label: 'Dollar',
    symbol: '$',
    id: 5,
  },
  {
    value: 'EUR',
    label: 'Euro',
    symbol: '€',
    id: 2,
  },
]

export const useCurrency = () => {
  const format = (value, currency) => {
    if (value === '-') return '-'
    if (!value && value !== 0) return ''

    if (!currency) return new Intl.NumberFormat(i18n.locale).format(value)

    const currencyData = CURRENCIES.find(c => c.value === currency || c.symbol === currency)

    if (!currencyData) return `${currency} ${new Intl.NumberFormat(i18n.locale).format(value)}`

    return new Intl.NumberFormat(i18n.locale, { style: 'currency',  currency: currencyData.value, currencyDisplay: 'symbol' }).format(value)
  }

  const parseLocaleNumber = (stringNumber) => {
    if (stringNumber === '-') return '-'
    var thousandSeparator = Intl.NumberFormat(i18n.locale).format(11111).replace(/\p{Number}/gu, '');
    var decimalSeparator = Intl.NumberFormat(i18n.locale).format(1.1).replace(/\p{Number}/gu, '');

    return parseFloat(stringNumber
        .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
        .replace(new RegExp('\\' + decimalSeparator), '.'),
    );
}
  
  return { format, parseLocaleNumber, currencies: CURRENCIES }
}
