import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'
import { getExpireIn } from '@/services/jwt'

import * as actions from './actions'
import mutations from './mutations'

import findMenu from '@/utils/authority'

const cookieUser = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null

Vue.use(Vuex)

const state = {
  loading: false,
  email: cookieUser?.email,
  phone: cookieUser?.phone,
  firstName: cookieUser?.given_name,
  lastName: cookieUser?.family_name,
  typeRegister: Cookies.get('typeRegister'),
  accessToken: localStorage.getItem('vue-token'),
  otpResendDelay: Cookies.get('otpResendDelay'),
  platformList: cookieUser?.platformList,
  platformActive: cookieUser?.platformActive,
  businessList: cookieUser?.businessList || [],
  workspace_id_active: '',
  workspace_id_active_name: '',
  role_id: '',
  role_name: '',
  restriction_base: '',
  channelNavigation: cookieUser?.channelNavigation,
  user_workspaces: [],
  userMenu: [],
  key: '',
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters: {
    expireIn: state => getExpireIn(state.accessToken),
    businessListObjectByKey: state => state.businessList.reduce((acc, business) => {
      acc[business.business_id] = business
      return acc
    }, {}),
    authorized: (state) => {
      return !!state.accessToken
    },
    can: (state) => (ability, order = '') => {
      const menuData = JSON.parse(JSON.stringify(state.userMenu))
      const data = findMenu(menuData, ability, order)
      return data
    },
    menuData: state => state.userMenu.filter(item => item.key !== 'group3'),
  },
}
