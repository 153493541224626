import {
  onMounted,
  reactive,
  toRefs,
  onBeforeUnmount,
  getCurrentInstance,
} from 'vue'
import store from '@/store'

export default () => {
  /* State */
  const state_ = reactive({
    fixedNavbar: false,
  })

  const $el = getCurrentInstance()

  /* Logout User */
  const handleLogout = () => {
    store
      .dispatch('user/LOGOUT')
      .then(() => {
        const url = new URL(window.location.href)
        url.pathname = '/'
        
        if ($el.proxy.$root.$options.keycloak.token) {
          $el.proxy.$root.$options.keycloak.logout({
            redirectUri: url.href,
          })
        } else {
          window.location.href = window.location.origin
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  /* As mounted in Options API */
  onMounted(() => {
    window.addEventListener('scroll', onScroll)
  })

  /* As beforeDestroy in Options API */
  onBeforeUnmount(() => {
    window.removeEventListener('scroll', onScroll)
  })

  /* Scroll for show box shadow in navbar */
  const onScroll = () => {
    if (window.scrollY < document.getElementById('navbar').clientHeight) {
      state_.fixedNavbar = false
    } else {
      state_.fixedNavbar = true
    }
  }

  return {
    ...toRefs(state_),
    handleLogout,
  }
}

/* === Lifecycle Options API And Composition API === */
/* 
beforeCreate -> use setup()
created -> use setup()
beforeMount -> onBeforeMount
mounted -> onMounted
beforeUpdate -> onBeforeUpdate
updated -> onUpdated
beforeDestroy -> onBeforeUnmount
destroyed -> onUnmounted
errorCaptured -> onErrorCaptured
*/
