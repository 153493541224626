<template>
  <a-sub-menu
    :key="menuInfo.key"
    :class="{
      'selected-parent-menu': selectedMenu,
    }"
    v-bind="$props"
    v-on="$listeners"
  >
    <span
      v-if="!settings.isMenuCollapsed"
      slot="title"
      class="d-flex align-items-center"
    >
      <img 
        v-if="menuInfo.sales_channel?.code"
        class="pr-2"
        :src="isChannel ? require(`@/assets/store/${menuInfo.sales_channel?.code.toLowerCase()}.png`) : require('@/assets/images/logo_small.png')"
        :class="{ 'not-channel': !isChannel }"
        alt=""
      />
      <a-icon v-if="!menuInfo.sales_channel?.code" :type="setIcon(menuInfo.icon)" class="pr-2" style="font-size: 18px" />
      <a-tooltip v-if="titleMenu.length > 17" placement="bottomLeft">
        <template slot="title">
          {{ titleMenu }}
        </template>
        {{ slicingWording(titleMenu) }}
      </a-tooltip>
      <div v-else>{{ titleMenu }}</div>
    </span>

    <template v-for="item in menuInfo.children">
      <item
        v-if="!item.children && !item.divider"
        :key="item.key"
        :menu-info="item"
      />
      <sub-menu v-if="item.children" :key="item.key" :menu-info="item" />
    </template>
  </a-sub-menu>
</template>

<script>
import { Menu } from 'ant-design-vue'
import Item from './item'

import dataItemMenu from './../composables/item'

export default {
  name: 'SubMenu',
  components: {
    Item,
  },
  isSubMenu: true,
  props: {
    ...Menu.SubMenu.props,
    menuInfo: Object,
    styles: Object,
  },
  setup(props) {
    const { settings, titleMenu, selectedMenu, slicingWording, setIcon } = dataItemMenu(props)

    return { settings, titleMenu, selectedMenu, slicingWording, setIcon }
  },
  computed: {
    isChannel() {
      return ['shopee_id','tokopedia_id', 'tiktok', 'tiktok_id', 'blibli_id', 'lazada_id'].includes(this.menuInfo.sales_channel?.code)
    },
  },
}
</script>

<style>
.not-channel {
  width: 28px;
  height: 28px;
  margin-left: 4px;
  margin-right: 4px;
  object-fit: contain;
}
</style>
