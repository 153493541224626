import apiClient from '@/services/axios'

export const userInit = (uuid) => {
  return apiClient({
    method: 'get',
    url: `user/cmd/v2/api/users/init/${uuid}`,
  })
}

export const getWorkspaceRoles = () => {
  return apiClient({
    method: 'get',
    url: '/user/query/workspace-roles/find',
  })
}

export const findUser = (params) => {
  return apiClient({
    method: 'get',
    url: '/user/query/v2/api/users/find',
    params,
  })
}

export const getUserDetailById = () => {
  return apiClient({
    method: 'get',
    url: '/user/query/v2/api/users/internal/me',
  })
}

export const getUserHistory = (uuid) => {
  return apiClient({
    method: 'get',
    url: `/user/query/v2/api/user_history/get/${uuid}`,
  })
}

export const getUserMenu = (params) => {
  return apiClient({
    method: 'get',
    url: '/user/query/menu-sets/find/',
    headers: {
      'Workspace-Id': params.workspace_id,
    },
    params,
  })
}

export const checkRegistration = ({ invite_token, token }) => {
  return apiClient({
    method: 'get',
    url: `/user/query/v2/api/users/invitation/${invite_token}`,
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
}

export const getDefaultWorkspaceRole = (workspaceId) => {
  return apiClient({
    method: 'get',
    url: 'user/query/workspace-roles/find/default',
    headers: {
      'Workspace-Id': workspaceId,
    },
  })
}

export const signUp = (data) => {
  return apiClient({
    method: 'post',
    url: '/user/cmd/api/register',
    data,
  })
}

export const signIn = (data) => {
  return apiClient({
    method: 'post',
    url: '/user/cmd/v2/api/users/login',
    data,
  })
}

export const userVerification = ({ type, data }) => {
  return apiClient({
    method: 'post',
    url: `/user/cmd/api/verification/${type}`,
    data,
  })
}

export const inviteUser = (data) => {
  return apiClient({
    method: 'post',
    url: '/user/cmd/v2/api/users/invite',
    data,
  })
}

export const inviteUserConfirmation = (data) => {
  return apiClient({
    method: 'post',
    url: '/user/cmd/v2/api/users/invite/confirmation',
    data,
  })
}

export const inviteUserRegister = ({ type, data, token }) => {
  return apiClient({
    method: 'post',
    url: `/user/cmd/v2/api/users/invite/${type}`,
    headers: {
      'Authorization': `Bearer ${token}`,
    },
    data,
  })
}

export const inviteUserVerification = (data) => {
  return apiClient({
    method: 'post',
    url: '/user/cmd/v2/api/users/invite/verification',
    data,
  })
}

export const changePassword = (data) => {
  return apiClient({
    method: 'post',
    url: '/user/cmd/v2/api/users/change-password',
    data,
  })
}

export const requestOtp = ({ type, data }) => {
  return apiClient({
    method: 'post',
    url: `/user/cmd/forgot-password/${type}`,
    data,
  })
}

export const selfRegistration = (data) => {
  return apiClient({
    method: 'post',
    url: '/user/cmd/v2/api/users/me/invite-owner',
    data,
  })
}

export const updateUser = ({ type, data }) => {
  return apiClient({
    method: 'put',
    url: `/user/cmd/v2/api/users/update${type}`,
    data,
  })
}

export const deleteUser = (uuid) => {
  return apiClient({
    method: 'delete',
    url: `/user/cmd/v2/api/users/delete/${uuid}`,
  })
}
