function findMenu(array, matched) {
  let result
  let length = array.length
  for (let i = 0; i < length; i++) {
    if (array[i].key === matched) {
      return array[i].access || ['READ']
    } else if (array[i].children) {
      result = findMenu(array[i].children, matched)
      if (result && result.length) {
        return result
      }
    }
  }
  return []
}

export default findMenu
