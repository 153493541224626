import { getCurrentInstance } from 'vue'

export default () => {
  const instance = getCurrentInstance().proxy

  const getQueryParam = (param) => {
    let filtered = {}
    const allowed = ['workspace_id', 'business_id', 'warehouse_id']

    for(const prop in param) {
      if(allowed.includes(prop)) filtered = { ...filtered, [prop]: param[prop] }
    }
    
    return filtered
  }


  const to = (url) => {
    const route = typeof url === 'object' ? url : { path: url }

    route.query = {
      ...to.query,
      ...getQueryParam({ ...instance.$route.query }),
      ...url?.query,
    }
    
    return route
  }

  return { getQueryParam, to }
}
