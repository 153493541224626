var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    cui__layout__grayBackground: _vm.settings.isGrayBackground,
  }},[_c('a-layout',{staticClass:"cui__layout__grayBackground",class:{
      cui__layout__contentMaxWidth: _vm.settings.isContentMaxWidth,
      cui__layout__appMaxWidth: _vm.settings.isAppMaxWidth,
      cui__layout__squaredBorders: _vm.settings.isSquaredBorders,
      cui__layout__cardsShadow: _vm.settings.isCardShadow,
      cui__layout__borderless: _vm.settings.isBorderless,
    }},[_c('a-layout',[_c('sidebar-v2',{attrs:{"collapsed":_vm.collapsed},on:{"change":_vm.onCollapse}}),_c('a-layout-content',[_c('navbar-v2',{attrs:{"collapsed":_vm.collapsed},on:{"change":_vm.onCollapse}}),_c('div',{staticClass:"content"},[_c('transition',{attrs:{"name":_vm.settings.routerAnimation,"mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }