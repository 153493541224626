const mutations = {
  SET_VOUCHER_LIST (state, payload) {
    state.voucherList = payload
  },
  SET_LOADING_DISCOUNT (state, payload) {
    state.isLoadingDiscount = payload
  },
  SET_SEARCH_DISCOUNT (state, payload) {
    state.search = payload
  },
  SET_PRODUCT_LIST (state, payload) {
    state.productList = payload
  },
  SET_RESELLER_LIST (state, payload) {
    state.resellerList = payload
  },
  SET_LOADING_TABLE (state, payload) {
    state.isLoadingTable = payload
  },
  SET_PAGINATION (state, payload) {
    state.pagination = payload
  },
  SET_PAGINATE_PARAMS (state, payload) {
    state.paginateParams.push(payload)
  },
  SET_TABLE_ACTIVE (state, payload) {
    state.tableActive = payload
  },
  CLEAR_STATE_PAGINATION (state) {
    state.pagination = {}
    state.paginateParams = []
  },
  SET_RANGE_DATE (state, payload) {
    state.rangeDate = payload
  },
  SET_KEYWORD_SEARCH (state, payload) {
    state.keywordSearch = payload
  },
  SET_BRANDS (state, payload) {
    state.brands = payload
  },
  SET_COURIER_LIST (state, payload) {
    state.courierList = payload
  },
}

export default mutations
