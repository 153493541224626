import axios from '@/services/axios'

/**
 * @typedef {{
    public_key: String,
    image_path: String,
    token: String,
    signature: String,
    expire: Number
  }} Token
 *
 * @returns {Promise<Token[]>}
 */
export const requestToken = async (payload, { qty = 1 } = {}) => {
  if (qty <= 0) {
    return Promise.resolve([])
  }

  try {
    const { data: response } = await axios.get('/channel/store/data/query/token', {
      headers: {
        'business-id': payload.business_id,
      },
      params: {
        qty,
        ...payload,
      },
    })
    return Promise.resolve(response.data)
  } catch (err) {
    return Promise.reject(err)
  }
}
