<template>
  <a-layout-header
    id="navbar"
    :class="{ [$style.navbar]: true, [$style.navbarFixed]: fixedNavbar }"
  >
    <div
      :class="$style.contentNavbar"
      class="px-3 py-3"
    >
      <div class="row align-items-center">
        <div class="col-2 text-center">
          <a-icon
            class="trigger"
            :type="isCollapsed ? 'menu-unfold' : 'menu-fold'"
            style="font-size: 20px"
            @click="onCollapse"
          />
        </div>

        <div class="col-4 pl-4">
          <search-business />
        </div>

        <div class="col-8 d-flex justify-content-end align-items-center">
          <a-alert
            v-if="isSyncStock"
            type="warning"
            style="font-size: 12px; padding: 5px 10px 4px 35px;"
            show-icon
            banner
          >
            <template slot="message">
              <span style="font-weight: bold">
                Sedang mensinkronisasi stok gudang <br />
                Mohon tidak me-refresh / menutup browser.
              </span>
              <span>
                <a-spin style="margin-top: -20px; margin-left: 5px">
                  <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
                </a-spin>
              </span>
            </template>
          </a-alert>
          <a-alert
            v-if="isAddChannel"
            type="warning"
            style="margin-left: 10px; font-size: 12px; padding: 5px 5px 4px 35px;"
            show-icon
            banner
          >
            <template slot="message">
              <span style="font-weight: bold">
                Sedang menyimpan {{ channelName }} <br />
                Mohon tidak me-refresh / menutup browser.
              </span>
              <span>
                <a-spin style="margin-top: -20px; margin-left: 5px">
                  <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
                </a-spin>
              </span>
            </template>
          </a-alert>
          <user />
        </div>
      </div>
    </div>
  </a-layout-header>
</template>

<script>
import dataNavbar from './composables/navbar'
import SearchBusiness from './Business'
import User from './User'

export default {
  name: 'NavbarV2',
  components: {
    SearchBusiness,
    User,
  },
  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const { fixedNavbar } = dataNavbar(ctx)
    return { fixedNavbar }
  },
  data() {
    return {
      isCollapsed: this.collapsed,
    }
  },
  computed: {
    isSyncStock() {
      return this.$store.state.warehouse.syncing
    },
    isAddChannel() {
      return this.$store.state.marketplace.isAddChannel
    },
    channelName() {
      return this.$store.state.marketplace.channel
        ? this.$t('channel.product.' + this.$store.state.marketplace.channel)
        : ''
    },
  },
  watch: {
    collapsed(value) {
      this.isCollapsed = value
    },
  },
  methods: {
    onCollapse() {
      this.isCollapsed = !this.isCollapsed
      this.$emit('change', this.isCollapsed)
    },
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
