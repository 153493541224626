import axios from 'axios'
// import config from '@/config'

const apiClient = axios.create({
  baseURL: "https://sit.adm-api.dev-powerbiz.asia/fastify/",
  headers: { 'Content-Type': 'application/json' },
})

apiClient.interceptors.request.use(request => {
  // if (store.state.user.accessToken) {
  //   request.headers.Authorization = `Bearer ${store.state.user.accessToken}`
  // }

  /* Inject Token from query param */
  request.headers = { ...query(request.params).headers }
  delete request.params.workspace_id
  delete request.params.business_id
  delete request.params.sales_channel_id
  delete request.params.channel_code
  delete request.params.web_platform
  delete request.params.app_id

  if(request.params?.api === 'banner') {
    delete request.params?.type
    delete request.params.api;
  }

  return request
})

export const query = (payload) => {
  return {
    headers: {
      Authorization: `Bearer ${payload.web_platform}`,
    },
    appId: payload.app_id,
  }
}

export default apiClient
