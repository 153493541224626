export const types = {
  SET: 'SET',
  SET_PRODUCTS: 'SET_PRODUCTS',
  SET_CATEGORIES: 'SET_CATEGORIES',
  SET_PRODUCT_ATTRIBUTES: 'SET_PRODUCTS_ATTRIBUTE',
  SET_VENDOR_PRODUCT_ATTRIBUTES: 'SET_VENDOR_PRODUCT_ATTRIBUTES',
}
const mutations = {
  [types.SET_PRODUCTS] (state, payload) {
    state.products = payload.data
    state.totalRows = payload.total_row
  },
  [types.SET_CATEGORIES] (state, payload) {
    state.categories = payload
  },
  [types.SET_PRODUCT_ATTRIBUTES](state, payload) {
    state.productAttributes = payload
  },
  [types.SET_VENDOR_PRODUCT_ATTRIBUTES](state, payload) {
    state.vendorProductAttributes = payload
  },
  SET_LOADING_PRODUCT (state, payload) {
    state.isLoadingProduct = payload
  },
}

export default mutations
