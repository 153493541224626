import axios from '@/services/axios'

export default {
  namespaced: true,
  state: {
    fetching: false,
    list: [],
  },
  getters: {
    list: state =>
      state.list.map(item => ({
        id: item.warehouse_id,
        name: item.warehouse_name,
        contact: item.warehouse_contact,
        phone: item.warehouse_phone,
        address: [item.address_line1, item.address_line2]
          .filter(i => !!i)
          .join(', '),
        country: item.area_country,
        province: item.area_province,
        city: item.area_city,
        district: item.area_district,
        subDistrict: item.area_subdistrict,
      })),
  },
  mutations: {
    SET_FETCHING(state, payload = undefined) {
      state.fetching = payload === undefined ? !state.fetching : payload
    },
    SET_WAREHOUSES(state, payload) {
      state.list = payload
    },
  },
  actions: {
    get: async ({ commit, state }, { force = false, ...payload }) => {
      commit('SET_FETCHING', true)

      if (!state.list || !state.list.length || force) {
        try {
          const { data: response } = await axios.get('/warehouses/private', {
            params: payload,
          })

          commit('SET_WAREHOUSES', response.data)
          return Promise.resolve(response.data)
        } catch (err) {
          return Promise.reject(err)
        } finally {
          commit('SET_FETCHING', false)
        }
      } else {
        return Promise.resolve(state.list)
      }
    },
  },
}
