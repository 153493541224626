import apiClient from '@/services/axios'

export const getWarehouses = ({ business_id, params }) => {
  return apiClient({
    method: 'get',
    url: '/warehouse/query/list',
    headers: business_id
      ? { 'Business-Id': business_id }
      : {},
    params,
  })
}

export const getWarehouseList = (payload) => {
  const { channel_code, ...params } = payload
  const url = channel_code?.includes('tokopedia') ? '/internal' : ''
  return apiClient({
    method: 'get',
    url: `/warehouse/query/v2${url}/list`,
    params,
  })
}

export const getWarehouseInternal = ({ business_id, params }) => {
  return apiClient({
    method: 'get',
    url: '/warehouse/query/internal',
    headers: {
      'Business-Id': business_id,
    },
    params,
  })
}

export const getWarehouseById = (warehouse_id) => {
  return apiClient({
    method: 'get',
    url: `/warehouse/query/find-by-id/${warehouse_id}`,
  })
}

export const crudWarehouseInternal = ({ type, data }) => {
  const method = type === 'save' ? 'post': 'put'
  return apiClient({
    method,
    url: `/warehouse/cmd/api/warehouse/${type}`,
    data,
  })
}
