import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import * as actions from './actions'

Vue.use(Vuex)

const state = {
  loading: false,
  isAddChannel: false,
  channel: null,
  model: null,
  count: 0,
  existingChannels: [],
  channels: [],
  success: [],
  failed: [],
  status: {
    code: '',
    message: '',
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
