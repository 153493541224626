import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import * as actions from './actions'

Vue.use(Vuex)

const state = {
  loading: false,
  syncing: false,
  message: false,
  warehouse: {},
  warehouses: [],
  productInventoryShippingUnit: {},
  productInventoryToAdd: {},
  warehouseList: [],
  syncWarehouses: [],
  status: {
    success: [],
    error: [],
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
