<template>
  <a-config-provider :locale="locale">
    <router-view />
  </a-config-provider>
</template>

<script>
import { locales } from './i18n'

import { mapState } from 'vuex'

export default {
  name: 'Localization',
  computed: {
    ...mapState(['settings']),
    locale() {
      return locales[this.settings.locale]?.localeAntd ? locales[this.settings.locale].localeAntd : {}
    },
  },
  watch: {
    'settings.locale': function (value) {
      this.$i18n.locale = value
    },
    '$store.state.marketplace.model'(value) {
      if(value?.id) this.$store.dispatch('marketplace/ADD_PRODUCT_CHANNEL')
    },
    '$store.state.marketplace.status'(value) {
      if(value?.code && value?.message) {
        const type = value.code === 'SUCCESS'
          ? 'success'
          : 'error'
        this.$notification[type]({
          message: this.$t(`utils.${value.code.toLowerCase()}`),
          description: value.message,
        })
      }
    },
    '$store.state.marketplace.success'(value) {
      this.resetValue(value.length, this.$store.state.marketplace.failed.length)
    },
    '$store.state.marketplace.failed'(value) {
      this.resetValue(this.$store.state.marketplace.success.length, value.length)
    },
    '$store.state.warehouse.message'(value) {
      if(value) {
        for(const prop in this.$store.state.warehouse.status) {
          if(this.$store.state.warehouse.status[prop].length) {
            const msg = prop === 'success' ? this.$t('utils.success') : this.$t('utils.failed')
            this.$notification[prop]({
              message: `${this.$t('adjustment.sync')} ${msg}`,
              description: `Proses ${this.$t('adjustment.sync')} ${this.$store.state.warehouse.status[prop].join(', ')} ${msg}`,
            })
          }
        }
        this.$store.commit('warehouse/SET_STATUS', {
          success: [],
          failed: [],
        })
        this.$store.commit('warehouse/SET_MESSAGE', false)
      }
    },
  },
  mounted() {
    this.$i18n.locale = this.settings.locale
  },
  methods: {
    resetValue(success, failed) {
      const sum = success + failed
      if(sum == this.$store.state.marketplace.count) this.$store.commit('marketplace/RESET_ALL')
    },
  },
}
</script>
