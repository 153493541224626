<template>
  <div style="width: 250px; line-height: 0">
    <a-select
      v-model="activeBusiness"
      size="large"
      class="select-antd-navbar"
      show-search
      :placeholder="placeholder"
      :options="options"
      option-filter-prop="children"
      :filter-option="filterOption"
      :show-arrow="options.length > 1"
      :disabled="options.length < 2"
      @change="onChangeBusiness"
      @focus="onFocus(true)"
      @blur="onFocus(false)"
    >
      <a-icon
        v-if="isFocus"
        slot="suffixIcon"
        type="search"
        style="font-size: 14px"
        :rotate="360"
      />
      <div slot="dropdownRender" slot-scope="menu">
        <v-nodes :vnodes="menu" />
        <a-divider v-if="options.length > 1" style="margin: 4px 0 0;" />
        <div
          v-if="options.length > 1"
          style="padding: 8px 12px; background-color: #f8f9fa"
          @mousedown="e => e.preventDefault()"
        >
          {{ $t(`topBar.total_${$store.state.user.restriction_base}`, { value: totalData(menu) }) }}
        </div>
      </div>
    </a-select>
  </div>
</template>

<script>
export default {
  components: {
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
  data() {
    return {
      isFocus: false,
      activeBusiness: '',
    }
  },
  computed: {
    placeholder() {
      return this.$t(`topBar.search_${this.$store.state.user.restriction_base}`)
    },
    options() {
      let list = []
      const value = this.$store.state.user.restriction_base === 'Business'
        ? 'business_id'
        : 'warehouse_id'
      const label = this.$store.state.user.restriction_base === 'Business'
        ? 'business_name'
        : 'name'
      this.$store.state.user.businessList.forEach(business => {
        if(business.business_id !== 'all') {
          list.push({
            value: business[value],
            label: business[label],
          })
        }
      })

      if(list.length > 1) list.unshift({ value: '', label: this.$t(`topBar.all_${this.$store.state.user.restriction_base}`) })
      return list
    },
  },
  watch: {
    options(opt) {
      const type = this.$store.state.user.restriction_base === 'Business'
        ? 'business_id'
        : 'warehouse_id'
      this.activeBusiness = this.$route.query[type]
        ? this.$route.query[type]
        : opt.length == 1
        ? opt[0].value
        : ''
    },
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().includes(input.toLowerCase())
    },
    totalData(menu) {
      const menuLength = menu.data.props.menuItems.length
      if(menuLength == 1 && menu.data.props.menuItems[0].key === 'NOT_FOUND') return 0
      return menuLength
    },
    onFocus(value) {
      this.isFocus = value
    },
    onChangeBusiness(value) {
      const baseId = this.$store.state.user.restriction_base === 'Business'
        ? 'business_id'
        : 'warehouse_id'

      let newQuery
      if (this.$store.state.user.restriction_base === 'Business') {
        newQuery = {
          workspace_id: this.$store.state.user.workspace_id_active,
          [baseId]: !value ? undefined : value,
        }
      } else {
        newQuery = {
          ...this.$route.query,
          workspace_id: this.$store.state.user.workspace_id_active,
          [baseId]: !value ? undefined : value,
        }
      }
      this.$router.push({
        query: newQuery,
      })
      .then(() => {
        this.setInitialMenu()
        this.setChannelMenu()
      })
    },
    setInitialMenu() {
      this.$store.dispatch('user/GETUSERMENU', {
        user_id: this.$store.state.user.id,
        workspace_id: this.$route.query.workspace_id
          ? this.$route.query.workspace_id
          : this.$store.state.user.user_workspaces.find(
              item => item.is_default,
            ).workspace_id,
        all_business: this.$route.query.business_id
          || this.$route.query.warehouse_id
          || this.$store.state.user.restriction_base === 'Warehouse'
            ? false
            : true,
        })
    },
    setChannelMenu() {
      const business_id = this.$route.query.business_id
        ? this.$route.query.business_id
        : this.$store.state.user.businessList.filter(
            val => val.warehouse_id === this.$route.query.warehouse_id,
          )[0]?.business_id

      if (business_id) {
        this.$store.dispatch('business/GETBUSINESS', { business_id })
        this.$store.dispatch('business/LISTCHANNEL', { business_id })
      }
    },
  },
}
</script>

<style lang="scss">
.select-antd-navbar {
  &.ant-select-lg {
    width: 100% !important;
    height: 45px !important;

    .ant-select-selection--single {
      height: 45px !important;
    }
    
    .ant-select-selection__rendered {
      line-height: 42px !important;
    }
  }

  &.ant-select-disabled .ant-select-selection {
    background-color: #fff;
    color: #1a1a1a; 
    cursor: auto;
  }
}
</style>
