export const types = {
  SET_LOADING: 'SET_LOADING',
  SET_PRODUCTS_STORE: 'SET_PRODUCTS_STORE',
  SET_CHANNEL: 'SET_CHANNEL',
  SET_REQUEST: 'SET_REQUEST',
  SET_STATE_ADJUST_WAREHOUSE_PRODUCT: 'SET_STATE_ADJUST_WAREHOUSE_PRODUCT',
}

const mutations = {
  [types.SET_LOADING]: (state, payload) => {
    state.loading = payload
  },
  [types.SET_PRODUCTS_STORE]: (state, payload) => {
    Object.assign(state, {
      ...payload,
    })
  },
  [types.SET_CHANNEL]: (state, payload) => {
    state.channel = payload
  },
  [types.SET_REQUEST]: (state, payload) => {
    state.request_id = payload
  },
  [types.SET_STATE_ADJUST_WAREHOUSE_PRODUCT]: (state, payload) => {
    Object.assign(state, {
      ...payload,
    })
  },
}

export default mutations
