import { getCurrentInstance } from 'vue'

export const useRoute = () => {
  const $el = getCurrentInstance()
  const $root = $el.proxy.$root

  return $root.$route
}

export const useRouter = () => {
  const $el = getCurrentInstance()
  const $root = $el.proxy.$root

  return $root.$router
}
