<template>
  <div :class="[isFirst || isCollapsed ? 'is-collapsed' : '', 'sidebar']">
    <a-layout-sider
      v-model="isCollapsed"
      collapsible
      :width="settings.leftMenuWidth"
      :collapsed="settings.isMenuCollapsed && !settings.isMobileView"
      :class="[$style.menu]"
    >
      <div v-if="collapsed" class="text-center">
        <img
          src="@/assets/images/logo_small.png"
          alt="Powerbiz"
        />
      </div>
      <img
        v-else
        src="@/assets/images/logo.svg"
        :class="[$style.logo]"
        alt="Powerbiz"
        draggable="false"
      />

      <a-skeleton
        active
        :title="false"
        :loading="isLoading"
        :paragraph="{ rows: 10, width: '100%' }"
        :class="{ 'mt-4': isLoading }"
      >
        <a-menu
          force-sub-menu-render
          :mode="'inline'"
          :selected-keys="selectedMenu"
          :open-keys="openKeys"
          :class="$style.navigation"
          :inline-indent="9"
          :style="{
            paddingBottom: '15rem',
          }"
          @click="handleClick"
          @openChange="handleOpenChange"
        >
          <!-- === For Default Menu === -->
          <template v-for="item in menuData">
            <!-- Grouping Menu -->
            <a-menu-item :key="item.key" class="group-menu">
              {{ item.title }}
            </a-menu-item>
            <template v-for="menu in item.children">
              <item
                v-if="!menu.children && !menu.category"
                :key="menu.key"
                :menu-info="menu"
              />
              <sub-menu
                v-if="menu.children"
                :key="menu.key"
                :menu-info="menu"
              />
            </template>
          </template>

          <!-- === For List Store or channel === -->
          <template
            v-if="$route.query['business_id'] || $route.query['warehouse_id']"
          >
            <a-menu-item
              v-if="permission.includes('WRITE')"
              key="add-store"
              class="store mt-4"
            >
              <router-link
                v-if="
                  $route.query['business_id'] || $route.query['warehouse_id']
                "
                :to="to()"
                class="wrap-btn mb-4"
              >
                <a-icon type="plus-square" style="font-size: 18px" />
                {{
                  isWarehouse
                    ? $t(`sideBar.add_warehouse`)
                    : $t(`sideBar.add_store`)
                }}
              </router-link>
            </a-menu-item>
            <a-menu-item key="search-store" class="store-search">
              <div class="wrap-input-search">
                <input
                  v-model="valSearch"
                  type="text"
                  :placeholder="
                    isWarehouse
                      ? $t(`sideBar.type_warehouse_name`)
                      : $t(`sideBar.type_shop_name`)
                  "
                />
                <a-icon type="search" />
              </div>
            </a-menu-item>
            <!-- Looping channel or store -->
            <template v-if="channelData">
              <template v-for="menu in channelData">
                <item
                  v-if="!menu.children && !menu.category"
                  :key="menu.key"
                  :menu-info="menu"
                  :selected-keys="selectedMenu"
                />
                <sub-menu
                  v-if="menu.children"
                  :key="menu.key"
                  :selected-keys="selectedMenu"
                  :menu-info="menu"
                />
              </template>
            </template>
            <template v-if="isFetch && (!channelData || !channelData.length)">
              {{ $t(`sideBar.no_shop`) }}
            </template>
          </template>
        </a-menu>
      </a-skeleton>
    </a-layout-sider>
  </div>
</template>

<script>
import { getCurrentInstance } from 'vue'

import SubMenu from './partials/submenu'
import Item from './partials/item'

import dataItemMenu from './composables/item'

export default {
  name: 'MenuLeft',
  components: {
    SubMenu,
    Item,
  },
  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const instance = getCurrentInstance().proxy
    const { getQueryParam } = dataItemMenu(props)
    const to = () => {
      const route = { path: '/add-store' }
      route.query = {
        ...to.query,
        ...getQueryParam({ ...instance.$route.query }),
      }

      return route
    }

    return { to }
  },
  data() {
    return {
      isCollapsed: this.collapsed,
      isFirst: window.screen.width < 800 ? true : false,
      valSearch: '',
      isFetch: false,
      list: [
        'channel-fulfillment',
        'channel-sales',
        'channel-product',
        'channel-promotion',
        'channel-customer',
      ],
    }
  },
  computed: {
    isLoading() {
      return this.$store.state.user.loading
    },
    settings() {
      return this.$store.state.settings
    },
    openKeys() {
      return this.$store.state.settings.openedMenu
    },
    isWarehouse() {
      return this.$store.state.user.restriction_base === 'Warehouse'
    },
    menuData() {
      return this.$store.getters['user/menuData']
    },
    channelData() {
      return this.$store.getters.channelData(this.valSearch)
    },
    setSelectedMenu() {
      return this.$store.getters.setSelectedMenu
    },
    selectedMenu() {
      return this.$store.state.settings.selectedMenu
    },
    permission() {
      return this.$store.getters['user/can']('store-list')
    },
  },
  watch: {
    setSelectedMenu(value) {
      this.$store.commit('settings/CHANGE_SETTING', {
        setting: 'selectedMenu',
        value,
      })
    },
    collapsed(value) {
      this.isCollapsed = value
      this.isFirst = false
    },
    isCollapsed(value) {
      this.$emit('change', value)
    },
  },
  methods: {
    handleOpenChange(openKeys) {
      this.$store.commit('settings/CHANGE_SETTING', {
        setting: 'openedMenu',
        value: openKeys ? openKeys : [],
      })
    },
    handleClick(e) {
      if (!e.key.includes('group') && e.key !== 'search-store') {
        this.$store.commit('settings/CHANGE_SETTING', {
          setting: 'selectedMenu',
          value: e.keyPath.reverse(),
        })
      }
    },
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>

<style lang="scss">
.sidebar {
  .ant-layout-sider-trigger {
    left: 0;
    background: #2196f3;
  }

  .ant-layout-sider-collapsed {
    padding-right: 0 !important;
    padding-left: 0 !important;

    .group-menu, .store, .store-search {
      display: none !important;
    }

    .ant-menu-item {
      padding: 0 25px !important;
      border-radius: 0 !important;

      span {
        display: none;
      }
    }

    .ant-menu-submenu {
      .ant-menu-submenu-title {
        padding: 0 25px !important;
        border-radius: 0 !important;

        img {
          padding-top: 6px;
          padding-bottom: 6px;
        }

        div {
          display: none;
        }
      }
    }
  }
}
</style>
