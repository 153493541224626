import moment from 'moment'
import router from '@/router'
import apiClient, { query } from '@/services/web-platform'
import apiClientPB from '@/services/axios'
import { i18n } from '@/i18n'
import Vue from 'vue'
import _ from 'lodash'

const actions = {
  async GETVOUCHERLIST({ commit, rootState }, payload) {
    try {
      commit('SET_LOADING_TABLE', true)
      // const { from, to } = rootState.web_platform_channel.discounts.rangeDate
      // const voucherCode = rootState.web_platform_channel.discounts.keywordSearch
      const { page = 1, limit = 20, voucher_code, from, to, id } = payload
      const { data: response } = await apiClientPB({
        method: 'get',
        url: `channel/store/promotions/query/vouchers?channel_id=${id}`,
        params: {
          page,
          limit,
          from,
          to,
          voucher_code,
        },
        headers: {
          'business-id': payload.business_id,
        },
      })
      const pagination = { ...rootState.web_platform_channel.discounts.pagination }
      pagination.total = response.total_rows
      pagination.pageSize = limit
      pagination.current = page
      commit('SET_PAGINATION', pagination)
      commit('SET_VOUCHER_LIST', response.data)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit('SET_LOADING_TABLE', false)
    }
  },

  async getProductList({ commit }, payload) {
    try {
      const { id } = payload;
      const except = ['limit', 'page', 'business_id'];
      Object.keys(payload).forEach((key) => except.includes(key) || delete payload[key])

      commit('SET_LOADING_DISCOUNT', true)
      const options = {
        method: 'get',
        url: '/channel/store/product/query/labels',
        headers: {
          'Content-Type': 'application/json',
          'business-id': payload.business_id,
        },
        params: {
          channel_id: id,
          ...payload,
        },
      }
      const { data: response } = await apiClientPB(options)
      commit('SET_PRODUCT_LIST', response.data)
      // dispatch('getResellerList')
    } catch (err) {
      console.log(err)
    }
  },

  async getResellerList({ commit }, payload) {
    try {
      const options = {
        method: 'get',
        url: `/admin/resellers/${query(payload).appId}`,
        params: {
          ...payload,
        },
      }
      const response = await apiClient(options)
      commit('SET_RESELLER_LIST', response.data.data)
    } catch (e) {
      console.log(e)
    } finally {
      commit('SET_LOADING_DISCOUNT', false)
    }
  },

  async addDiscountCode({ commit, rootState }, payload) {
    commit('SET_LOADING_DISCOUNT', true)
    const product = []
    const allStore = []
    const selectedStore = []
    const sliceProduct = []
    const brands = []
    const courier_list = []
    let sliceBrand = null
    for (const i in payload.product_list) {
      const index = payload.product_list[i].indexOf('_')
      sliceProduct.push(payload.product_list[i].slice(0, index))
    }

    /** set product */
    for (const i in rootState.web_platform_channel.discounts.productList) {
      if (
        _.find(sliceProduct, function (o) {
          return o === rootState.web_platform_channel.discounts.productList[i].product_title
        })
      ) {
        product.push(rootState.web_platform_channel.discounts.productList[i].uuid)
      }
    }

    /** set all_store */
    for (const i in rootState.web_platform_channel.discounts.resellerList) {
      allStore.push(rootState.web_platform_channel.discounts.resellerList[i].seller_hashid)
    }
    /** set selected store */
    for (const i in rootState.web_platform_channel.discounts.resellerList) {
      if (
        _.find(payload.store_list, function (o) {
          return o === rootState.web_platform_channel.discounts.resellerList[i].store_name
        })
      ) {
        selectedStore.push(rootState.web_platform_channel.discounts.resellerList[i].seller_hashid)
      }
    }

    for (const i in rootState.web_platform_channel.discounts.courierList) {
      if (
        _.find(payload.courier_list, function (o) {
          return o === rootState.web_platform_channel.discounts.courierList[i].label
        })
      ) {
        courier_list.push(rootState.web_platform_channel.discounts.courierList[i].value)
      }
    }

    const setReferenceList = type => {
      switch (type) {
        case 2:
          return product

        case 4:
          // eslint-disable-next-line
          const index = payload.brand_list.indexOf('_')
          sliceBrand = payload.brand_list.slice(0, index)
          /** Set brands */
          for (const i in rootState.web_platform_channel.discounts.brands) {
            if (rootState.web_platform_channel.discounts.brands[i].brand_title === sliceBrand) {
              brands.push(rootState.web_platform_channel.discounts.brands[i].uuid)
            }
          }
          return brands

        default:
          return []
      }
    }
    const { id, business_id } = payload

    try {
      const voucher_code = payload.voucher_code
      const type_id = payload.type_id
      const value = Number(payload.discount_value) // transaction_data
      const type = payload.discount_type // // transaction_data
      const min_transaction = Number(payload.min_transaction) // // transaction_data
      const max_discount = Number(payload.max_discount) // transaction_data
      const from = moment(payload.validity[0]).unix() // validity
      const until = moment(payload.validity[1]).unix() // validity
      const f_combined = payload.f_combined
      const f_allstore = payload.f_allstore
      const store_list = !payload.f_allstore ? selectedStore : allStore
      const qty = Number(payload.qty)
      const reference_list = setReferenceList(payload.type_id)
      const channel_id = id
      const preparingData = {
        voucher_code,
        type_id,
        transaction_data: {
          value,
          type,
          min_transaction,
          max_discount,
        },
        validity: {
          from,
          until,
        },
        f_combined,
        f_allstore,
        store_list,
        qty,
        reference_list,
        courier_list: payload.type_id === 3 ? courier_list : [],
        channel_id,
      }
      const except = []
      Object.keys(payload).forEach(
        key => except.includes(key) || delete payload[key],
      )
      const options = {
        method: 'post',
        url: '/channel/store/promotions/cmd/vouchers',
        data: {
          channel_id: id,
          items: [preparingData],
        },
        headers: {
          'Content-Type': 'application/json',
          'business-id': business_id,
          'user-id': rootState.user.id,
        },
        params: {
          ...payload,
        },
      }
      await apiClientPB(options)
      router.push({ name: 'promotion-channel.web-voucher', query: router.currentRoute.query })
      Vue.prototype.$notification.success({
        message: i18n.t('notification.successAddDiscount'),
      })
    } catch (err) {
      console.error(err)
      switch (err?.response?.data?.status_code) {
        case 409:
          Vue.prototype.$notification.error({
            message: i18n.t('notification.duplicateVoucherCode'),
          })
          break
        case 400:
          Vue.prototype.$notification.error({
            message:
              err.response?.data?.message || i18n.t('authForm.systemBusy'),
          })
          break
        default:
          Vue.prototype.$notification.error({
            message: i18n.t('authForm.systemBusy'),
          })
          break
      }
    } finally {
      commit('SET_LOADING_DISCOUNT', false)
    }
  },

  async deleteVoucher({ commit, rootState }, payload) {
    try {
      commit('SET_LOADING_TABLE', true)
      const { id, business_id, uuid } = payload
      const except = [];
      Object.keys(payload).forEach((key) => except.includes(key) || delete payload[key])

      const options = {
        method: 'delete',
        url: '/channel/store/promotions/cmd/vouchers',
        headers: {
          'Content-Type': 'application/json',
          'business-id': business_id,
          'user-id': rootState.user.id,
        },
        data: {
          channel_id: id,
          "voucher_ids": [uuid],
        },
        params: {
          ...payload,
        },
      }
      const response = await apiClientPB(options)
      return Promise.resolve(response)
    } catch (err) {
      console.log(err)
    } finally {
      commit('SET_LOADING_TABLE', false)
    }
  },

  async getBrands({ commit }, payload) {
    try {
      const { id } = payload
      const except = ['limit', 'page', 'business_id'];
      Object.keys(payload).forEach((key) => except.includes(key) || delete payload[key])
      
      commit('SET_LOADING_DISCOUNT', true)
      const options = {
        method: 'get',
        url: '/channel/store/product/query/brands',
        headers: {
          'Content-Type': 'application/json',
          'business-id': payload.business_id,
        },
        params: {
          channel_id: id,
          ...payload,
        },
      }
      const { data: response } = await apiClientPB(options)
      commit('SET_BRANDS', response.data)
    } catch (err) {
      console.log(err)
    } finally {
      commit('SET_LOADING_DISCOUNT', false)
    }
  },

  async GETCOURIERLIST({ commit }, payload) {
    try {
      commit('SET_LOADING_DISCOUNT', true)
      const { id } = payload
      const except = ['limit', 'page', 'business_id'];
      Object.keys(payload).forEach((key) => except.includes(key) || delete payload[key])
      
      delete payload.id
      const { data: response } = await apiClientPB({
        method: 'get',
        url: '/channel/store/delivery/query/courier',
        headers: {
          'Content-Type': 'application/json',
          'business-id': payload.business_id,
        },
        params: {
          channel_id: id,
          ...payload,
        },
      })
      commit('SET_COURIER_LIST', response.data)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit('SET_LOADING_DISCOUNT', false)
    }
  },

  async GETVOUCHERDETAIL({ commit }, payload) {
    try {
      const { uuid, id } = payload
      const except = ['limit', 'page', 'business_id'];
      Object.keys(payload).forEach((key) => except.includes(key) || delete payload[key])

      commit('SET_LOADING_DISCOUNT', true)
      const { data: response } = await apiClientPB({
        method: 'get',
        url: `/channel/store/promotions/query/voucher/${uuid}`,
        headers: {
          'Content-Type': 'application/json',
          'business-id': payload.business_id,
        },
        params: {
          channel_id: id,
          ...payload,
        },
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit('SET_LOADING_DISCOUNT', false)
    }
  },

  async EDITVOUCHER({ commit, rootState }, payload) {
    const product = []
    const allStore = []
    const selectedStore = []
    const sliceProduct = []
    const brands = []
    let sliceBrand = null
    const courier_list = []
    for (const i in payload.product_list) {
      const index = payload.product_list[i].indexOf('_')
      sliceProduct.push(payload.product_list[i].slice(0, index))
    }

    /** set product */
    for (const i in rootState.web_platform_channel.discounts.productList) {
      if (
        _.find(sliceProduct, function (o) {
          return o === rootState.web_platform_channel.discounts.productList[i].product_title
        })
      ) {
        product.push(rootState.web_platform_channel.discounts.productList[i].uuid)
      }
    }

    /** set all_store */
    for (const i in rootState.web_platform_channel.discounts.resellerList) {
      allStore.push(rootState.web_platform_channel.discounts.resellerList[i].seller_hashid)
    }
    /** set selected store */
    for (const i in rootState.web_platform_channel.discounts.resellerList) {
      if (
        _.find(payload.store_list, function (o) {
          return o === rootState.web_platform_channel.discounts.resellerList[i].store_name
        })
      ) {
        selectedStore.push(rootState.web_platform_channel.discounts.resellerList[i].seller_hashid)
      }
    }

    /** set courier list */
    for (const i in rootState.web_platform_channel.discounts.courierList) {
      if (
        _.find(payload.courier_list, function (o) {
          return o === rootState.web_platform_channel.discounts.courierList[i].label
        })
      ) {
        courier_list.push(rootState.web_platform_channel.discounts.courierList[i].value)
      }
    }

    const setReferenceList = type => {
      switch (type) {
        case 2:
          return product

        case 4:
          // eslint-disable-next-line
          const index = payload.brand_list.indexOf('_')
          sliceBrand = payload.brand_list.slice(0, index)
          /** Set brands */
          for (const i in rootState.web_platform_channel.discounts.brands) {
            if (rootState.web_platform_channel.discounts.brands[i].brand_title === sliceBrand) {
              brands.push(rootState.web_platform_channel.discounts.brands[i].uuid)
            }
          }
          return brands

        default:
          return []
      }
    }

    try {
      const { id, business_id } = payload

      commit('SET_LOADING_DISCOUNT', true)
      const uuid = payload.uuid
      const voucher_code = payload.voucher_code
      const type_id = payload.type_id
      const value = Number(payload.discount_value) // transaction_data
      const type = payload.discount_type // // transaction_data
      const min_transaction = Number(payload.min_transaction) // // transaction_data
      const max_discount = Number(payload.max_discount) // transaction_data
      const from = moment(payload.validity[0]).unix() // validity
      const until = moment(payload.validity[1]).unix() // validity
      const f_combined = payload.f_combined
      const f_allstore = payload.f_allstore
      const store_list = !payload.f_allstore ? selectedStore : allStore
      const qty = Number(payload.qty)
      const reference_list = setReferenceList(payload.type_id)
      const channel_id = id
      const preparingData = {
        id: uuid,
        voucher_code,
        type_id,
        transaction_data: {
          value,
          type,
          min_transaction,
          max_discount,
        },
        validity: {
          from,
          until,
        },
        f_combined,
        f_allstore,
        store_list,
        qty,
        reference_list,
        courier_list: payload.type_id === 3 ? courier_list : [],
        channel_id,
      }
      const except = []
      Object.keys(payload).forEach(
        key => except.includes(key) || delete payload[key],
      )
      const options = {
        method: 'put',
        url: '/channel/store/promotions/cmd/vouchers',
        data: {
          channel_id: id,
          items: [preparingData],
        },
        headers: {
          'Content-Type': 'application/json',
          'business-id': business_id,
          'user-id': rootState.user.id,
        },
        params: {
          ...payload,
        },
      }
      await apiClientPB(options)
      // return Promise.resolve(response)
      router.push({ name: 'promotion-channel.web-voucher', query: router.currentRoute.query })
      Vue.prototype.$notification.success({
        message: i18n.t('success edit voucher'),
      })
    } catch (err) {
      console.error(err)
      switch (err?.response?.data?.status_code) {
        case 409:
          Vue.prototype.$notification.error({
            message: i18n.t('notification.duplicateVoucherCode'),
          })
          break
        case 400:
          Vue.prototype.$notification.error({
            message:
              err.response?.data?.message || i18n.t('authForm.systemBusy'),
          })
          break
        default:
          Vue.prototype.$notification.error({
            message: i18n.t('authForm.systemBusy'),
          })
          break
      }
      // return Promise.reject(error)
    } finally {
      commit('SET_LOADING_DISCOUNT', false)
    }
  },

  // eslint-disable-next-line no-unused-vars
  async GETUSEDVOUCHER({ commit }, payload) {
    try {
      const { voucher_code, page, limit, id } = payload
      const except = ['limit', 'page', 'business_id', 'voucher_code'];
      Object.keys(payload).forEach((key) => except.includes(key) || delete payload[key])

      // commit('SET_LOADING_DISCOUNT', true)
      const { data: response } = await apiClientPB({
        method: 'get',
        url: '/channel/store/promotions/query/vouchers/used',
        params: {
          ...payload,
          channel_id: id,
          voucher_code,
          page,
          limit,
        },
        headers: {
          'Content-Type': 'application/json',
          'business-id': payload.business_id,
        },
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      // commit('SET_LOADING_DISCOUNT', false)
    }
  },
}

export default actions
