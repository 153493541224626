/**
 * @type {import('vuex').Mutation} SET
 */
export const SET = (state, { key, payload }) => {
  state[key] = payload
}

export const FETCHING = (state, val) => {
  state.fetching = val !== undefined ? val : !state.fetching
}
