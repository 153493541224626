import apiClientPB from '@/services/axios'

export const getResellerList = async ({ commit }, payload) => {
  try {
    const { id, with_loading } = payload
    const except = ['limit', 'page', 'business_id', 'q']
    Object.keys(payload).forEach(
      key => except.includes(key) || delete payload[key],
    )

    commit('SET_LOADING_RESELLER', with_loading)
    const { data: response } = await apiClientPB.get(
      `/channel/store/reseller/query`,
      {
        headers: {
          'Content-Type': 'application/json',
          'business-id': payload.business_id,
        },
        params: {
          channel_id: id,
          ...payload,
        },
      },
    )
    commit('SET_RESELLER_LIST', response.data)
    return Promise.resolve(response)
  } catch (err) {
    return Promise.reject(err)
  } finally {
    commit('SET_LOADING_RESELLER', false)
  }
}

// eslint-disable-next-line no-unused-vars
export const getResellerDetail = async ({ commit, rootState }, payload) => {
  try {
    const { id } = payload
    const except = ['limit', 'page', 'business_id', 'seller_id']
    Object.keys(payload).forEach(
      key => except.includes(key) || delete payload[key],
    )

    const { data: response } = await apiClientPB({
      method: 'get',
      url: `/channel/store/reseller/query/data/${payload.seller_id}`,
      headers: {
        'Content-Type': 'application/json',
        'business-id': payload.business_id,
      },
      params: {
        channel_id: id,
        ...payload,
      },
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}
