export const types = {
  SET_WAREHOUSE_STORE: 'SET_WAREHOUSE_STORE',
  SET_WAREHOUSES: 'SET_WAREHOUSES',
  SET_STATUS: 'SET_STATUS',
  SET_MESSAGE: 'SET_MESSAGE',
  SET_SYNCING: 'SET_SYNCING',
}

const mutations = {
  [types.SET_WAREHOUSE_STORE]: (state, payload) => {
    Object.assign(state, {
      ...payload,
    })
  },
  [types.SET_WAREHOUSES]: (state, payload) => {
    state.syncWarehouses = payload
  },
  [types.SET_STATUS]: (state, payload) => {
    state.status = payload
  },
  [types.SET_MESSAGE]: (state, payload) => {
    state.message = payload
  },
  [types.SET_SYNCING]: (state, payload) => {
    state.syncing = payload
  },
}

export default mutations
