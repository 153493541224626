import antd from 'ant-design-vue/lib/locale-provider/en_US'
import validation from 'vee-validate/dist/locale/id.json'

export default {
  language: 'Language',
  topBar: {
    profileMenu: {
      name: 'Name',
      email: 'Email',
      phone: 'Phone',
      logout: 'Logout',
      account_setting: 'Account Setting',
      switch_to: 'Switch to',
    },
    subscribeNow: 'Subscribe Now',
    no_Business: 'Business not found!',
    no_Warehouse: 'Warehouse not found!',
    search_Business: 'Search business',
    search_Warehouse: 'Search warehouse',
    further_data: 'Search for further data.',
    total_Business: '{value} business',
    total_Warehouse: '{value} warehouse',
    all_: 'All',
    all_Business: 'All Business',
    all_Warehouse: 'All Warehouse',
    all_Channel: 'All Channel',
  },

  snackBar: {
    notYetConnected: 'Your account not yet connect with your store.',
    storeConnected: 'Your account connected with',
    store: 'Store',
    ConnectToYourStore: 'Connect to Store',
  },

  auth: {
    login: 'Sign In',
    register: 'Register',
    notRegister: 'Dont have an account yet?',
    signupNow: 'Sign Up Now!',
    emailPhoneLabel: 'Email or Phone Number',
    emailAndPhoneBlank: 'email or phone is required',
    password: 'Password',
    invalidPassword: 'Invalid Password',
    confirmPassword: 'Password Confirmation',
    forgetPassword: 'Forgot Password',
    resetPass: 'Reset Password',
    requiredEmail: 'Email Required',
    goToSignIn: 'Go to Sign In page',
    name: 'Name',
    businessName: 'Business Name',
    anotherRegister: 'Try another way to Sign Up',
    anotherSign: 'Try another way to Sign In',
    passwordNotMatch: 'Confirm Password Not Match',
    requiredName: 'Nama tidak boleh kosong',
    minimumLengthPassword: 'Minimum password length 6 characters',
    atLeastOneUppercase: 'Password at least have 1 uppercase character',
    atLeastOneNumber: 'Password at least have 1 number',
    atLeastOneSpecialChar: 'Password at least have 1 special character',
    verifyAccount: 'Please verify your registration',
    sendOtpTo: 'Kami telah mengirimkan Kode OTP ke',
    resendOtp: 'Resend OTP',
    successVerification: 'Verification Success',
    userNotRegistered: 'User Not Registered',
    goToDashboard: 'Go To Dashboard',
    goToLogin: 'Go To Login',
    needVerification: 'Harap lakukan verifikasi',
    changeEmail: 'Change Email',
    emailAddress: 'Email Address',
    emailExpired: 'Oops, there is a problem with your link',
    expiredDescription:
      'Looks like your link has expired or not copied correctly. Please check and try again or ask your friend to resend the invitation.',
    resendEmail: 'Resend email',
    resetMyPass: 'Reset My Password',
    sendAgain: 'Kirim ulang',
    invalidEmailPhoneNumber: 'Invalid phone number or email',
    successRegister: 'Registrasi Berhasil',
    loginFailed: 'Login Failed',
    hasAccount: 'Already have an account?',
    loginHere: 'Sign In',
    signUpWithGoogle: 'Sign Up With Google',
    signUpWithFacebook: 'Sign Up With Facebook',
    signInWithGoogle: 'Sign In with Google',
    signInWithFacebook: 'Sign In with Facebook',
  },
  authForm: {
    systemBusy: 'System seems to be busy, please try again later or contact administrator!',
  },
  notification: {
    successAddDiscount: 'Add Discount Voucher Success',
    successDeleteVoucher: 'Delete Voucher Success',
    duplicateVoucherCode: 'Voucher code already exist',
    failedSearchSalesOrder: 'Please select parameter for search sales order',
    failedSearchVoucher: 'Please select parameter for search voucher',
  },
  sideBar: {
    dashboard: 'Dashboard',
    sales: 'Sales',
    fulfillment: 'Fulfillment',
    product: 'Product',
    product_master: 'Master Product',
    productCategory: 'Kategori Produk',
    management_category: 'Category Management',
    price_setting: 'Price Setting',
    global_variable: 'Global Variable',
    formula_variable: 'Formula Variable',
    product_variable: 'Product Variable',
    price_formula: 'Price Formula',
    inventory: 'Inventory',
    stock: 'Stock',
    stock_alocation: 'Stock Alocation',
    inventory_list: 'Inventory List',
    inventory_movement: 'Inventory Movement',
    setup_stock: 'Stock Setup',
    transfer_stock: 'Stock Transfer',
    promotion: 'Promotion',
    customer: 'Customer',
    channel: 'Channel',
    analytic: 'Analytic',
    finance: 'Finance',
    user: 'User',
    account: 'Account',
    user_management: 'User Management',
    setting: 'Setting',
    integration: 'Integration',
    business: 'Business',
    warehouse: 'Warehouse',
    shipping: 'Shipping',
    customer_setting: 'Customer',
    sales_setting: 'Sales Setting',
    account_setting: 'Account Setting',
    distributorship: 'Distributorship',
    sales_order: 'Sales Order',
    product_list: 'Product',
    distributorship_management: 'Distributor Manage..',
    store_management: 'Store Management',
    store_setting: 'Store Setting',
    price_group_setting: 'Price-Group Setting',
    export_history: 'Export History',
    add_store: 'Add Store',
    add_warehouse: 'Add Warehouse',
    type_shop_name: 'Type shop name',
    type_warehouse_name: 'Type Warehouse Name',
    no_shop: 'No shop list yet!',
    reseller: 'Reseller',
    resellerdetail: 'Detail Reseller',
    voucher: 'Voucher',
    voucherdetail: 'Detail Voucher',
    addvoucher: 'Add Voucher',
    editvoucher: 'Edit Voucher',
    storesetting: 'Store Settings',
    adjustment: 'Stock Adjustment',
    'goods-receipt': 'Goods Receipt',
    // product_category: "Product Category",
    distributor: 'Distributor',
    distributor_list: 'Distributor List',
    customer_category: 'Customer Category',
    discount: 'Discount',
    approval: 'Approval',
    withdrawal: 'Withdrawal',
    payment_channel: 'Payment Channel',
    payment_history: 'Payment History',
    invoice: 'Invoice',
    retur: 'Retur',
    'retur-request-list': 'Retur Request List',
    'retur-list': 'Retur List',
    web_management: 'Web Management',
    banner: 'Banner',
    redeem: 'Loyalty',
    redeem_sales_channel: 'Redeem',
    redeem_product_channel: 'Product',
    liststore: 'Store List',
    product_collection: 'Product Collection',
    // "redeem-setting": "Redeem Setting",
    'credit-notes': 'Credit Notes',
    'customer-list': 'Customer List',
    'price-area': 'Price Area',
    'product-list': 'Product List',
    do: 'Delivery Order',
  },
  settingsStore: {
    logo: 'Logo',
    banner: 'Home Banner',
    categoryBanner: 'Category Banner',
    resellerBanner: 'Reseller Banner',
    uploadImage: 'Upload Image',
  },
  dashboardContent: {
    orderStatus: 'Order Status',
    orderStatusDescription:
      'Summary of current order status that needs to be followed up',
    orderStatusNew: 'New',
    orderStatusConfirmed: 'Confirmed',
    orderStatusSent: 'Sent',
    orderStatusSuccess: 'Success',
    orderStatusCancel: 'Canceled',
    summaryItemInventory: 'Item Inventory',
    summaryBusiness: 'Business',
    summaryClient: 'Client',
    summaryPO: 'Pending Order',
    summaryItemNoStock: 'Out of Stock',
    summarySalesToday: 'Sales Today',
    summaryLogisticToday: 'Logistics Expenditure Today',
    summaryItemSoldToday: 'Item Sold Today',
    summarySales: 'Sales',
    summaryLogistic: 'Logistics Expenditure',
    summaryItemSold: 'Item Sold',
    paramToday: 'Today',
    paramLast7day: 'Last 7 Day',
    paramLast30day: 'Last 30 Day',
    paramThisMonth: 'This Month',
    paramLastYear: 'Last 1 Year',
    paramThisYear: 'This Year',
    salesOverview: 'Sales Overview',
    section_description_inventory: 'Total Items in Inventory',
    section_description_business: 'Total Items in Business',
    section_description_client: 'Total Partnered Clients',
    section_description_PO: 'Total Items in Pending Order',
    section_description_nostock: 'Total Items Out of Stock',
    section_description_salesToday: 'Total Items in Sales Today',
    section_description_logisticToday: 'Total Logistics Expenditure Today',
    section_description_itemsoldToday: 'Total Items Sold Today',
    section_description_sales: 'Total Items in Sales',
    section_description_logistic: 'Total Logistics Expenditure',
    section_description_itemsold: 'Total Items Sold',
    top_region: 'Top 10 Region',
    top_region_description: 'Top 10 Region with the Most Sales',
    shipping: 'Shipping',
    shipping_description: 'Top 10 Courier Delivery',
    channel: 'Channel',
    channel_description: 'Channel Summary',
    business: 'Business',
    business_description: 'Business Summary',
    warehouse: 'Warehouse',
    courier: 'Logistic',
    payment: 'Payment',
    payment_method: 'Payment Method',
    payment_description: 'Payment Summary',
    no_data: 'No data',
    start_date: 'Start Date',
    end_date: 'End Date',
    today: 'Today',
    is_today: 'today',
    not_today: '',
    today_month: 'This Month',
    today_year: 'This Year',
    last_week: 'Last 7 Days',
    last_month: 'Last 30 Days',
    last_year: 'Last 1 Year',
    transaction_status: 'Transaction Status',
    detail_status: 'Summary of delivery order transaction status to date that needs to be followed up',
    today_summary: 'Today Summary',
    sales: 'Sales',
    logistic: 'Logistic',
    logistic_expenses: 'Logistic Expenses',
    item_sold: 'Item Sold',
    report: 'Report',
    date_difference: 'End date min. 7 days from start date',
    sales_overview_graph: 'Sales Overview Graph',
    compare: 'Compare',
    detail_channel: 'List of top 10 channels based on transactions',
    province: 'Province',
    detail_province: 'List of top 10 provinces based on number of orders',
    city: 'City / Regency',
    detail_city: 'List of top 10 cities/regencies based on number of orders',
    product: 'Best Selling Product',
    detail_product: 'List of top 10 products based on qty',
    catalog: 'Best Selling Item',
    detail_catalog: 'List of top 10 items based on qty',
    shopee_id: 'Shopee',
    tokopedia_id: 'Tokopedia',
    tiktok: 'Tiktok',
    tiktok_id: 'Tiktok',
    blibli_id: 'Blibli',
    lazada_id: 'Lazada',
    distributor: 'Distributorship',
    store: 'Webcommerce',
    total: {
      sku: 'Total item in the warehouse',
      oos: 'Total empty item in the warehouse',
      sales: 'Total',
      canceled_sales: 'Total canceled',
      logistic_expenses: 'Total shipping cost',
      item_sold: 'Total item sold',
    },
  },
  add_store: {
    channel_integration: 'Integrasi Saluran',
    mandatory_selected_warehouse:
      'Make sure you have selected a warehouse, then select a channel to integrate into your store',
    marketplace_integration: 'Marketplace Integration',
    fulfillment_integration: 'Fulfillment Integration',
    coming_soon: 'Coming soon',
    sync_tips: 'Sync Tips',
    note_tips:
      'When it starts syncing you will be redirected to the {name} page for login and authorization. After successful authorization you can arrange delivery service in Powerbiz Delivery Service Settings.',
    back: 'Back',
    sync_store_sucess: 'Store Integration Success',
    des_sync_store_sucess:
      'Please synchronize the products on your Store Product List by clicking the Sync button. Product.',
    add_more_store: 'Add More Stores',
    done_sync: 'Finish',
    cancel: 'Cancel',
    next: 'Next',
    choose_chanel: 'Choose a channel to integrate into your store',
    start_sync: 'Start Sync',
    complite_detail_information: 'Complete the following detailed information',
    select_shop: 'Select Shop',
    error_sync: 'An error occurred, please try again',
    complete_information: 'Complete the following detailed information',
    sync_warehouse: 'Warehouse Synchronization',
    main_warehouse: 'Main Warehouse',
    select_main_warehouse: 'Select Main Warehouse',
    channel_warehouse: 'Channel Warehouse',
    select_channel_warehouse: 'Select Channel Warehouse',
  },
  distributor: {
    list_distributor: 'List Distributor',
    search: 'Search Distributor',
    export_data: 'Export Data',
    add_distributor: 'Add Distributor',
    name_distributor: 'Name Distributor',
    category_distributor: 'Category',
    address: 'Address',
    action: 'Action',
    country: 'Country',
    city: 'City',
    district: 'District',
    subdistrict: 'Subdistrict',
    mobile_phone: 'Mobile Phone',
    active: 'Active',
    inactive: 'Not Active',
    detail_distributor: 'Detail Distributor',
    active_condition: 'Deactivate',
    inactive_condition: 'Activate',
    status_distributor: '{value} Distributor',
    confirm_deactivate: 'Are you sure you want to {value} the distributor ?',
    code: 'Distributor Code',
    name: 'Distributor Name',
    npwp_id: 'NPWP ID',
    npwp_name: 'NPWP Name',
    postal_code: 'Postal Code',
    warehouse_code: 'Warehouse Code',
    warehouse_name: 'Warehouse Name',
    warehouse_address: 'Warehouse Address',
    use_distributor_address: 'Use Distributor Address',
    choose_city: 'Select City first',
    choose_district: 'Select District first',
    choose_coverage: 'Select Coverage Area City first',
    rules: {
      code: 'Distributor Code can not be empty',
      name: 'Distributor Name can not be empty',
      categories: 'Product Category must be selected',
      phone_number: 'Mobile Phone can not be empty',
      taxid: 'NPWP ID can not be empty',
      taxname: 'NPWP Name can not be empty',
      bank_name: 'Bank Name must be selected',
      account_number: 'Account Number can not be empty',
      account_name: 'Account Name can not be empty',
      sla_shipping_day: 'Day SLA Complain can not be empty',
      city: 'City must be selected',
      district: 'District must be selected',
      postal_code: 'Postal Code can not be empty',
      address: 'Address can not be empty',
      warehouse_name: 'Warehouse Name can not be empty',
      coverage: 'Coverage Area must be selected',
    },
  },
  reseller: {
    titleHead: 'Reseller',
    search: 'Search reseller..',
  },
  customer: {
    customer_category: "Customer Category",
    list: 'Customer List',
    search: 'Search Customer Data',
    export: 'Export Data',
    invite: 'Invite Customer',
    name: 'Customer Name',
    category: 'Customer Category',
    email: 'Email',
    mobile: 'Mobile Phone',
    distributor: 'Distributor',
    city: 'City',
    status: 'Status',
    action: 'Action',
    all: 'All Status',
    not_approved: 'Not Approved',
    active: 'Active',
    inactive: 'Inactive',
    approval: 'Approval',
    new: 'New',
    edit: 'Edit',
    credit_limit: 'Credit Limit',
    transaction_limit: 'Transaction Limit',
    payment_limit: 'Payment Limit',
    edit_customer: 'Edit Customer',
    delete: 'Delete',
    empty_title: 'No data',
    empty_msg: "You don't have any data yet",
    add: 'Add Customer',
    password: 'Password',
    confirm_password: 'Confirm Password',
    distributor_city: 'Distributor City',
    select_category: 'Select Customer Category',
    select_city: 'Select Distributor City',
    select_distributor: 'Select Distributor',
    select_all: 'Select All',
    save: 'Save',
    cancel: 'Cancel',
    approve: 'Approve',
    not_approve: 'Not Approve',
    registration_via: 'Registration via Email / Mobile Phone',
    shop_code: 'Shop Code',
    buyer_code: 'Buyer Code',
    sales_code: 'Sales Code',
    store_name: 'Store Name',
    store_type: 'Store Type',
    payment_model: 'Payment Model',
    transaction_limits: 'Transaction Limits',
    coin_usage: 'Coin Usage',
    pay_time_limit: 'Pay Time Limit',
    setup_credit_limit: 'Setup Credit Limit',
    user_status: 'User Status',
    phone: 'Phone Number',
    rules: {
      name: 'Store name is required',
      namecustomer: 'Customer name is required',
      category: 'Category must be selected',
      email: 'Email is required',
      email_invalid: 'Email is invalid',
      mobile: 'Mobile phone is required',
      distributor: 'Distributor must be selected',
      city: 'Distributor city must be selected',
      password: 'Password is required',
      confirm_password: 'Confirm password is required',
      match_password: 'Confirm password must be the same as password',
      sales_code: 'Sales code must be selected',
      coin_usage: 'Coin Usage must be selected',
      shop_code: 'Shop Code is required',
    },
  },
  order: {
    storeName: 'Store Name',
    customerDetail: 'Customer Detail',
    customerName: 'Name',
    customerPhone: 'Phone Number',
    customerEmail: 'Email',
    paymentDetail: 'Payment Detail',
    totalPriceOrder: 'Total Price Order',
    paymentStatus: 'Payment Status',
    paymentMethod: 'Payment Method',
    orderShippingDetail: 'Shipping Detail',
    courier: 'Courier',
    warehouse: 'Warehouse',
    detail: 'Order Detail',
    voucher: 'Voucher',
    salesTitle: 'Sales',
    shippingCost: 'Shipping Cost',
    itemsSold: 'Items Sold',
    cleanFilter: 'Clean Filter',
    totalAllSales: 'Total All Sales',
    exportTitle: 'Export',
    orderDate: 'Orders Date',
    business: 'Business',
    channel: 'Channels',
    customer: 'Customers',
    orderNumber: 'Orders No',
    itemTotal: 'Total Item',
    priceTotal: 'Price Total',
    productName: 'Product name',
    skuTitle: 'SKU',
    quantityTitle: 'Qty',
    shippingTitle: 'Shipping Details',
    priceTitle: 'Price',
    discountTilte: 'Discount',
    totalTitle: 'Sub Total Order',
    detailsTitle: 'Details',
    paymentsTotalTitle: 'Total Bill',
    moreDetailsTitle: 'Show More Details',
    lessDetailsTitle: 'Show Less Details',
    sortBy: 'Sort By',
    sortByNewest: 'Newest',
    sortByOldest: 'Oldest',
    dropshipperInformation: 'Send as dropshipper',
    dropshipperAddress: 'Dropshipper Address',
    shippingCourierTitle: 'Courier',
    shippingReceiptNumber: 'Receipt No',
    shippingLabel: 'Shipping Label',
    shippingWarehouseTitle: 'Send From',
    shippingHistoryTitle: 'Shipping History',
    shippingShowMoreDetailsTitle: 'Show More Details',
    changesHistory: 'History',
    ordersPeriod: 'Order Period',
    ordersPeriodFrom: 'Order Period Start',
    ordersPeriodUntil: 'Order Period End',
    productCategory: 'Product Category',
    printStatus: 'Print Status',
    filterState: {
      all: 'All',
      verified: 'Unpaid',
      new: 'Created',
      new_: 'Created',
      confirmed: 'Pending',
      onprocess: 'On Process',
      on_process: 'On Process',
      cancelrequested: 'Confirm cancellation',
      shipped: 'On Delivery', // Shipped
      send: 'Delivered', // Completed
      delivered: 'Delivered', // Completed
      canceled: 'Canceled',
      completed: 'Completed',
      ready_to_ship: 'Ready to Send',
      cancel_requested: 'Req to Cancel',
      // rejected: 'Order cancellation',
    },
    filterState: {
      all: 'All',
      verified: 'Unpaid',
      new: 'Created',
      new_: 'Created',
      confirmed: 'Pending',
      onprocess: 'On Process',
      on_process: 'On Process',
      cancelrequested: 'Conf. cancellation',
      shipped: 'On Delivery', // Shipped
      send: 'Delivered', // Completed
      delivered: 'Delivered', // Completed
      canceled: 'Canceled',
      completed: 'Completed',
      ready_to_ship: 'Rdy to Send',
      cancel_requested: 'Req to Cancel',
      // rejected: 'Order cancellation',
    },
    status: {
      confirmOrder: 'Confirm Order',
      completedOrder: 'Completed Order',
    },
    require_order_period: 'Please fill in the order period',
    empty_title: 'No data',
    empty_msg: "You don't have any data yet",
    courier_name: 'Courier name',
    accept_order: 'Accept order',
    reject_order: 'Reject Order',
    cancel_order: 'Cancel Order',
    cancel_request: 'Refuse Cancellation',
    cancel_reason: 'Cancellation Reason',
    cancel_reason_sub: 'Cancel Reason',
    cancel_detail: 'Cancellation details',
    select_empty_stock: 'Select empty stock',
    placeholder_cancel_detail: 'Explain the reason for canceling your order',
    info_cancel_detail: 'Minimum {value} characters',
    novalid_reason: 'Please choose a reason',
    accept_confirm_msg: 'Are you sure you want to accept this order?',
    transaction_discount: 'Transaction Discount',
    transaction_deposit: 'Deposit',
    transaction_loyalty: 'Loyalty',
    transaction_voucher: 'Voucher',
    transaction_shipping: 'Shipping Cost',
    transaction_admin: 'Total Admin Fees',
    discount_transaction: 'Transaction Discount by',
    discount_shipping: 'Shipping Discount by',
    pickup_address: 'Pickup Address',
  },
  integration: {
    integration: 'Integration',
    name: 'Name',
    channelManagement: 'Channel Management',
    warehouseManagement: 'Warehouse Management',
    shippingManagement: 'Shipping Management',
    pointOfSales: 'Point Of Sales',
    paymentGateway: 'Payment Gateway',
    accounting: 'Accounting',
    addChannel: 'Add Channel',
    addWarehouse: 'Add Warehouse',
    addShipping: 'Add Shipping',
    addPointOfSales: 'Add Point Of Sales',
    addPaymentGateway: 'Add Payment Gateway',
    addAccountingTool: 'Add Accounting Tool',
    startIntegration: 'Start Integration',
    confirmProduct: 'Product Confirmation',
    editChannel: 'E d i t',
    deleteChannel: 'Delete Channel',
  },
  product: {
    title: 'Product',
    listProduct: 'List Product Master',
    priceArea: 'Price Area',
    searchBarPlaceholder: 'Search',
    addProduct: 'Add Product',
    editProduct: 'Edit Product',
    addPrice: 'Add Price',
    clearFilter: 'Clear filter',
    setfilter: 'Set filter',
    date: {
      first: 'First date',
      end: 'End date',
    },
    productStatus: {
      ACTIVE: 'Active',
      NON_ACTIVE: 'Inactive',
      INACTIVE: 'Inactive',
      DRAFT: 'Draft',
    },
    slug: 'Slug',
    addNewProduct: 'Add New Product',
    uploadTemplate: 'Upload Template',
    cancel: 'Cancel',
    next: 'Next',
    selectClient: 'Choose Client',
    mass_change: 'Bulk Update',
    all_select: 'All',
    selected: 'Selected',
    delete: 'Delete',
    export: 'Export',
    product_name: 'Product Name',
    categories: 'Categories',
    varian: 'Variant',
    variant_name: 'Variant Name',
    product_variant: 'Product Variants',
    variant_desc:
      'Choose one to set Unit Product type, Bundling Product, Variant Product',
    range_price: 'Price Range',
    channel: 'Channel',
    status: 'Status',
    product_empty: 'No Products Yet',
    have_no_product: 'You dont have product yet, please Add Product.',
    product_details: 'Product Details',
    photo_video: 'Photos and Videos',
    product_type_and_price: 'Product Type and Price',
    weight_product_shipping: 'Weight Product and Shipping',
    inventory: 'Inventory',
    product_information: 'Product Information',
    brand: 'Product Brand',
    item_condition: 'Item Condition',
    specification: 'Specification',
    used_condition: 'Used',
    description: 'Description',
    short_description: 'Short Description',
    photo_title: 'Product Photos',
    // 10MB
    foto_video_description:
      'Upload photos and variants that the product has. Minimum photo dimensions are {minWidth}x{minHeight} px. The maximum file size is 1MB. With JPG, JPEG, PNG, BMP, GIF formats.',
    foto_video_description_master:
      'Upload photos and variants that the product has. Minimum photo dimensions are {minWidth}x{minHeight} px. The maximum file size is 15MB. With JPG, JPEG, PNG, BMP, GIF formats.',
    video_title: 'Product Videos',
    video_description:
      'Upload a product video up to 30MB or enter the youtube video link of the product',
    picture: 'Picture',
    base_unit: 'Base Unit',
    gift_size: 'Box Size',
    unit: 'Unit',
    weight_unit: 'Weight Unit',
    weight: 'Weight',
    width: 'Width',
    height: 'Height',
    length: 'Length',
    additional_unit: 'Additional Unit',
    quantity_conversion: 'Quantity Conversion',
    add_new_unit: 'Add New Unit',
    add_warehouse: 'Add Warehouse',
    location: 'Location',
    make_main: 'Make Main',
    factory_sku: 'Factory SKU',
    set_wholesale_price: 'Set Wholesale Price',
    add_wholesale_price: 'Add Wholesale Price',
    error_empty_variant: 'Variant name should not be empty',
    use_formula: 'Use Formula Calculation',
    totalItemPrice: 'Total Item Price',
    productBundling: 'Bundling Products',
    calc_price: 'Item Calculation Price',
    import: 'Product Import',
    importLabel: 'Item Origin',
    'price-include-vat': 'Price Include VAT',
    'price-exclude-vat': 'Price Exclude VAT',
    'price-no-vat': 'Price Not Charge for VAT',
    write_something: 'Write something',
    import_export: 'Import / Export',
    import_change_price: 'Import Change Price',
    export_product_data: 'Export Product Data',
    price_area: 'Price Area',
    area: 'Area',
    customer_type: 'Customer Type',
    price: 'Price',
    effective_date: 'Effective Date',
    add_price: 'Add Price',
    stock_allocation: 'Stock Allocation',
    allocated_stock: 'Allocated Stock',
    catalog: 'Catalog Product',
    product_category: 'Product Category',
    category_name: 'Category Name',
    new_product: 'Newest Product',
    old_product: 'Oldest Product',
    view_variant: 'View Variant',
    close_variant: 'Close Variant',

    collection: {
      title: 'Product Collection',
      type: {
        featured: 'Featured',
        bestseller: 'Best Seller',
        newarrival: 'New Arrival',
        special: "Special",
      },
      confirm: {
        delete: 'Are you sure want to delete this product from product collection?',
      },
      'add-to-collection': 'Add to collection',
      'type-label': 'Type',
    },
  },
  global_variable: {
    add: 'Add Global Variable',
    empty_title: 'No global variable yet',
    empty_msg: 'You dont have global variable yet',
  },
  formula_variable: {
    add: 'Add Formula Variable',
    empty_title: 'No formula variable yet',
    empty_msg: 'You dont have formula variable yet',
  },
  product_variable: {
    add: 'Add Product Variable',
    empty_title: 'No product variable yet',
    empty_msg: 'You dont have product variable yet',
  },
  price_formula: {
    price_formula: 'Price Formula',
    search: 'Search',
    add_price_formula: 'Add Price Formula',
    all: 'Select All',
    formula_name: 'Formula Name',
    short_description: 'Short Description',
    cancel: 'Cancel',
    rounding: 'Rounding',
    empty_title: 'No price formula yet',
    empty_msg: 'You dont have price formula yet',
    error_add_variable: 'Please use the variable or constant',
    error_add_operator: 'Please use the operator',
    error_no_close: 'Please close the brackets',
    error_use_formula: 'This variable is used in the formula',
    error_close_bracket: 'Invalid close bracket',
    error_number_of_variable: 'Please use at least 2 variables or constant',
    error_get_global: 'Failed to fetch Global Variable data',
    error_get_formula: 'Failed to fetch Formula Variable data',
    error_get_product: 'Failed to fetch Product Variable data',
  },
  price_setting: {
    label_variable: 'Variable Name',
    label_value: 'Value',
    label_formula: 'Formula Name',
    label_description: 'Short Description',
    error_variable_name: 'Variable name should not be empty',
    error_max_variable_name: 'Variable name can only be 30 characters',
    error_variable_name_pattern:
      'Variable name can only be alphanumeric & underscore',
    error_max_formula: 'Formula name can only be 30 characters',
    error_formula_pattern:
      'The formula name can only be alphanumeric & underscore',
    error_formula_name: 'Formula name should not be empty',
    error_value_empty: 'Value should not be empty',
    error_description: 'Short Description should not be empty',
    error_formula_same: 'Variable cannot be more than 1 ',
    error_formula_empty: 'Formula should not be empty',
    confirm_delete_msg: 'Are you sure you want to delete {type}',
    confirm_delete_info: '{type} will not reappear in the list',
    success_delete_msg: '{name} successfully deleted',
    cant_delete: 'Cannot Delete',
    used_msg: 'used in following {type}',
  },
  utils: {
    back: 'Back',
    choose: 'Choose',
    filter: 'Filter',
    cancel: 'Cancel',
    save: 'Save',
    edit: 'Edit',
    add: 'Add',
    apply: 'Apply',
    variable: 'Variable',
    search: 'Search',
    insert: 'Insert',
    use: 'Use',
    all: 'All',
    categories: 'Category',
    channel: 'Channel',
    date: 'Date',
    example: 'Example',
    name: 'Name',
    price: 'Price',
    currency: 'Currency',
    wrong: 'Something went wrong',
    used: 'already used',
    yes: 'Yes',
    no: 'No',
    confirm_delete: 'Do you sure want to delete',
    change: 'Change',
    choose_a_tag: 'Choose a tag',
    choose_a_channel: 'Choose a channel',
    choose_a_warehouse: 'Choose a warehouse',
    schedule: 'Schedule',
    clearFilter: 'Clear Filter',
    reload: 'Reload',
    export: 'Export',
    status: 'Status',
    hour: 'Jam',
    not_found: 'Not Found',
    amount: 'Amount',
    failed: 'Failed',
    success: 'Success',
    delete: 'Hapus',
    deleted: 'Deleted',
    close: 'Close',
    formula: 'Formula',
    pagination_show_total: 'Showing ${perpage} from ${total} items',
    order_date: 'Order Date',
    no_data: 'No data',
  },
  no_valid: {
    required: 'should not be empty',
    alphanum_underscore: 'Can only be alphanumeric & underscore',
  },
  fulfillment: {
    totalFulfillment: 'Total All Fulfillment {value}',
    accept: 'Accept',
    reject: 'Reject',
    cancel: 'Cancel',
    confirm: 'Confirm',
    accept_msg: 'Are you sure to accept this order?',
    reject_msg: 'Are you sure to reject this order?',
    pick_confirmed_msg: 'Apakah Anda yakin akan mengambil pesanan berikut?',
    pack_confirmed_msg: 'Apakah Anda yakin akan mengemas pesanan berikut?',
    packing_completed_msg:
      'Apakah Anda yakin akan mengirimkan pesanan berikut?',
    good_issued_msg: 'Apakah Anda yakin pesanan sudah siap dikirim?',
    shipped_msg: 'Apakah Anda yakin pesanan sudah terkirim?',
    accept_order: 'Yes, Accept order',
    reject_order: 'Yes, Reject order',
    btn_caption_accept_pick_confirmed: 'Ya, ambil pesanan',
    btn_caption_accept_pack_confirmed: 'Ya, kemas pesanan',
    btn_caption_packing_completed: 'Ya, kirimkan pesanan',
    btn_caption_good_issued: 'Ya, Pesanan siap dikirim',
    btn_caption_shipped: 'Ya, Pesanan terkirim',
    accept_cancel_order: 'Accept Cancel Order',
    cancel_order: 'Cancel Order',
    reason_cancel: 'Reason Cancellation',
    description: 'Description',
    send: 'Send',
    min_char: 'Minimum 30 Characters',
    search: 'Search',
    all: 'All',
    order_list: 'Order List',
    print_label: 'Print Label',
    setting_label: 'Shipping Label Settings',
    paper_size: 'Paper Size',
    print_details: 'Print with item details',
    print_all_details: 'Print all with item details',
    packed: 'Packed',
    packing_completed: 'Dikirim',
    inventory_status: 'Inventory status',
    warehouse: 'Warehouse',
    didntHaveData: 'Belum Memiliki Data',
    didntHaveDataCaption: 'Saat ini anda \n belum memiliki data',
    exportExcelFile: 'Export Excel File',
    exportHistoryPage: 'Export History Page',
    captionNotifFilterBeforeExport:
      'Lakukan Filter sebelum melakukan export \n dengan jangka waktu maksimal 3 bulan',
    order_date: 'Order Date',
    order_id: 'Order ID',
    shipping_id: 'Shipping ID',
    business: 'Bisnis',
    channel: 'Channel',
    store: 'Nama Toko',
    totalItem: 'Total Item',
    inventory: 'Inventory',
    notification_success_accept_order: 'Success accept order',
    notification_success_update_order: 'Success update order',
    printLabel: 'Print Label',
    changeOrder: 'Ubah Pesanan',
    itemProduct: 'Item Produk',
    cancelItem: 'Cancel Item',
    changeItem: 'Change Item',
    splitShipment: 'Split Shipment',
    backToChangeOrder: 'Kembali Ubah Pesanan',
    booking_inventory: 'Booking Inventory',
    sla: 'SLA',
  },
  userManage: {
    search: 'Search',
    add_user: 'Add User',
    user_management: 'User Management',
  },
  validations: {
    ...validation.messages,
    no_space: '{_field_} should not contain space',
  },
  businessClient: {
    client: 'Client',
    add_client: 'Add Client',
    edit_client: 'Edit Client',
    detail_client: 'Detail Client',
    id_business_user: 'ID Business or Username',
    information: 'Information',
    currency: 'Currency',
    category: 'Business Category',
    business_identity: 'Business Identity',
    business_name: 'Business Name',
    company_information: 'Company Information',
    company_name: 'Company Name',
    tax_liability: 'Tax Liability',
    company_address: 'Company Address',
    warehouse: 'Warehouse',
    contact: 'Contact',
  },
  store_list_page: {
    store_list: 'Store List',
    product_confirm: 'Product Confirm',
    confirmed: 'Confirmed',
    unconfirmed: 'Unconfirmed',
    part_confirmed: 'Part Confirmed',
    mix_confirmed: 'Mix Confirmed',
    inactive: 'Inactive',
    store_code: 'Store Code',
    store_id: 'Store ID',
    store_name: 'Store Name',
    sales_channel: 'Sales Channel',
    store_status: 'Store Status',
    api_password: 'API Seller Key',
    auto_confirm: 'Auto Confirm',
    authorization_time: 'Authorization Time',
    country: 'Country',
    action: 'Action',
    store_products: 'Product Name',
    variant: 'Variant',
    variant_count: 'Number of variants',
    description: 'Description',
    product_catalog_mapping: 'Product Catalog Mapping',
    select_product: 'Select Product',
    empty_title: 'No product data yet',
    empty_msg: 'You dont have product data yet',
    search_placeholder: 'Search store or sales channel',
    add_store: 'Add Store',
    connected: 'Connect',
    disconnected: 'Disconnected',
    status: 'Status',
    all: 'All',
    product: 'Product',
    change_product: 'Detail Product',
    change_data: 'Change Data',
    deactivate_product: 'Deactivate Product',
    activate_product: 'Activate Product',
    search_products: 'Search Products',
    product_name: 'Product Name',
    channel_products_catalog: 'Channel Products Catalog',
    see_more: 'See More',
    modal_info: 'If the product is not in the master product, add a product',
    modal_empty_msg: 'Product not in product master',
    categories: 'Categories',
    range_price: 'Price Range',
    program_name: 'Program Name',
    voucher_name: 'Voucher Name',
    voucher_type: 'Voucher Code & Type',
    discount_rank: 'Discount Rate',
    program_type: 'Program Type',
    periode: 'Period',
    kuota: 'Quota',
    live: 'Active',
    activate: 'Active',
    draft: 'Draft',
    active: 'Active',
    notActive: 'Not Active',
    not_active: 'Not Active',
    view_detail: 'View Detail',
    product_mapping: 'Product Mapping',
    product_status: 'Product Status',
    mapping_status: 'Mapping Status',
    rules: {
      store_name: 'Store Name is required',
      store_id: 'Store ID is required',
      api_password: 'API Seller Key is required',
      main_warehouse: 'Main warehouse must be selected',
      channel_warehouse: 'Channel warehouse must be selected',
    },
  },
  export_history_page: {
    title: 'Export History',
    total_caption: 'Total All Orders {total}',
    date: 'Date',
    user: 'User',
    menu: 'Menu',
    file_export: 'File Export',
    status: 'Status',
    try_again: 'Try again',
    empty_title: 'No data',
    done: 'Done',
    failed: 'Failed',
    all: 'All',
    product: 'Product',
    order: 'Order',
    fulfillment: 'Fulfillment',
    inventory: 'Inventory',
    formula: 'Formula',
  },
  inventory: {
    productName: 'Product Name',
    inventory: 'Inventory - Client',
    firstPeriod: 'First Period',
    initialStock: 'Initial Stock',
    lastPeriod: 'Last Period',
    lastStock: 'Last Stock',
    baseUOM: 'Base UoM',
    date: 'Date',
    changeType: 'Change Type',
    changeStock: 'Change Stock',
    period: 'Period',
  },
  productAvailability: {
    search: 'Search',
    productName: 'Product Name',
    vendorName: 'Vendor Name',
    note: 'Note',
    productAvailability: 'Product Availability',
    goodsReceipt: 'Goods Receipt',
    gudang: 'Select Warehouse',
    inbound: 'Create Inbound',
    adjustment: 'Stock Adjustment',
    adjustmentType: 'Adjustment Type',
    allstatus: 'All Status',
    instock: 'In Stock',
    outofstock: 'Out of Stock',
    minusofstock: 'Minus Stock',
    movement: 'Movement History',
    location: 'location',
    available: 'Available',
    allocated: 'Allocated',
    grDate: 'GR Date',
    grAdjustment: 'Adjustment Date',
    warningGr: 'Select Warehouse To Make Goods Receipt',
    warningAdjustment: 'Select Warehouse To Make Stock Adjustment',
    reference: 'Document Reference Number',
    adjustmentNumber: 'Adjustment Number',
    warehouseName: 'Warehouse Name',
    warehouseStock: 'Warehouse Stock',
    inorder: 'On Order',
    safetyStock: 'Safety Stock',
    title: 'Product Name',
    sku: 'SKU',
    warehouseId: 'Warehouse Name',
    businessName: 'Business Name',
    availability: 'Available Stock',
    allocatable: 'Allocatable Stock',
    allocable: 'Allocable',
    onHand: 'On Hand Stock',
    inOrder: 'In Order Stock',
    unit: 'Unit',
    updatedAt: 'Date',
    IN_STOCK: 'In Stock',
    OUT_OF_STOCK: 'Out of Stock',
    MINUS_OF_STOCK: 'Minus Stock',
  },
  account: {
    profile: 'Your Profile',
    fullname: 'Full Name',
    noHp: 'Phone Number',
    setPassword: 'Use a Strong Passwords',
    setting: 'Account Settings',
    password: 'Change Password',
  },
  warehouse: {
    list_warehouse: 'List Warehouse',
    export_data: 'Export Data',
    add_warehouse: 'Add Warehouse',
    detail_warehouse: 'Detail Warehouse',
    edit_warehouse: 'Edit Warehouse',
    name_warehouse: 'Warehouse Code',
    ext_code: 'External Code',
    code_warehouse: 'Warehouse Code',
    category_warehouse: 'Category Warehouse',
    address: 'Address',
    action: 'Action',
    information_warehouse: 'Warehouse Information',
    address_warehouse: 'Warehouse Address',
    integration_warehouse: 'Warehouse Integration',
    connected_with: 'Connected with',
    update_warehouse: 'Update Warehouse',
  },
  discount: {
    discount: 'Discount',
    search: 'Search Discount',
    createDiscount: 'Create Discount',
    latest: 'Latest Data',
    program_name: 'Program Name',
    promotionName: 'Program Name',
    discount_level: 'Discount Level',
    period: 'Period',
    quota: 'Quota',
    status: 'Status',
    all: 'All',
    waiting_approval: 'Waiting for Approval',
    active: 'Active',
    not_active: 'Not Active',
    sort_by: 'Sort by',
    order_by: 'Order',
    newest: 'Newest',
    oldest: 'Oldest',
    basic_details: 'Basic Details',
    promotion_target: 'Promotion Target',
    setting_promotion: 'Setting Promotion',
    start_date: 'Start Date',
    end_date: 'End Date',
    promotion_type: 'Promotion Type',
    select_type: 'Select Promotion Type',
    select_level: 'Select Discount Level',
    national_program: 'National Program',
    local_program: 'Local Program',
    distributor_program: 'Distributor Program',
    program_type: 'Program Type',
    select_program: 'Select Program Type',
    product_type: 'Product Type',
    product_item: 'Product Item',
    select_item: 'Select Product Item',
    product_category: 'Product Category',
    product_brand: 'Product Brand',
    promotion_quota: 'Promotion Quota',
    program_buy: 'Program Type & Purchase',
    discount_format: 'Discount Format',
    promotion_value: 'Promotion Value',
    promotion: 'Promotion',
    products: 'Product',
    transaction: 'Transaction',
    purchase_range: 'Purchase Range',
    min_qty: 'Min. Qty',
    max_qty: 'Max. Qty',
    min_trx: 'Min. Transaction',
    max_trx: 'Max. Transaction',
    uom: 'UoM',
    promotion_format: 'Promotion Format',
    percentage: 'Percentage',
    currency: 'Currency',
    fixed: 'Nominal',
    product: 'Extra Stuff',
    product_name: 'Product Name',
    discount_max: 'Discount Max. Value',
    add_promotion: 'Add Promotion',
    voucher: 'Voucher',
    edit: 'Edit Discount',
    coin: 'Coin',
    coin_value: 'Coin Value',
    promo_cancellation: 'Promo Cancellation',
    cancellation_description:
      'By canceling the {value} Promo, the promo will be inactive and cannot be used',
    choose_cancellation: 'Choose a reason to cancel this promo',
    write_reason: 'Write other reasons here',
    inactive: 'Not Active',
    createdAt: 'Created Date',
    promotion_criteria: 'Promotion Criteria',
    purchase_transaction: 'Total Purchase Transactions',
    purchase_quantity: 'Total Purchase Quantity',
    promo_type: 'Promo Criteria Type',
    select_promo_type: 'Select Promo Criteria Type',
    range_promo: 'Range',
    multiple_promo: 'Multiple',
    multiple_value: 'Multiple Value',
  },
  voucher: {
    voucher: 'Voucher',
    createvoucher: 'Create Voucher',
    latest: 'Latest Data',
    allstatus: 'All',
    waiting: 'Waiting for Approval',
    active: 'Active',
    nonactive: 'Non Active',
    codeTypeVoucher: 'Kode & Type Voucher',
    create_voucher: 'Create Voucher',
    edit_voucher: 'Edit Voucher',
    voucher_lists: 'Voucher Lists',
    voucher_name: 'Voucher Name',
    voucher_code: 'Voucher Code',
    voucher_get: 'Period to get Voucher',
    voucher_usage: 'Period to use Voucher',
    same_period: 'Same with Voucher validity',
    voucher_combined: 'Voucher can be combined',
    edit: 'Edit Voucher',
    promo_cancellation: 'Voucher Cancellation',
    cancellation_description:
      'By canceling the {value} Voucher, the voucher will be inactive and cannot be used',
    choose_cancellation: 'Choose a reason to cancel this voucher',
    write_reason: 'Write other reasons here',
    inactive: 'Canceled',
  },
  redeem: {
    redeem_product: 'Redeem Product',
    search: 'Search Product',
    stock: 'Stock',
    redeem_count: 'Numbers of Redeem',
    minimum: 'Minimum',
    maximum: 'Maximum',
    reset_filter: 'Reset Filter',
    apply: 'Apply',
    category: 'Category',
    variant: 'Variant',
    status: 'Status',
  },
  addDiscount: {
    basic: 'Basic Details',
    programName: 'Program Name',
    startDate: 'Start Date',
    endDate: 'End Date',
    promotionType: 'Promotion Type',
    rate: 'Discount Rate',
    target: 'Promotion Target',
    area: 'Area',
    distributor: 'Distributor',
    shopType: 'Shop Type',
    shop: 'Shop',
    programType: 'Program Type',
    productType: 'Product Type',
    settingPromo: 'Promotion Setting',
    itemProduct: 'Item Product',
    kuota: 'Promotion Quota',
    typeBuy: 'Program & Purchase Type',
    format: 'Discount Format',
    nilaiPromosi: 'Promotion Value',
    promotion: 'Promotion',
    addPromotion: 'Add Promotion',
    purchaseRange: 'Purchase Range',
    promotionFormat: 'Promotion Format',
  },
  addVoucher: {
    basic: 'Basic Details',
    voucherName: 'Voucher Name',
    voucherCode: 'Voucher Code',
    startDate: 'Start Date',
    endDate: 'End Date',
    voucherType: 'Voucher Type',
    rate: 'Discount Rate',
    target: 'Promotion Target',
    area: 'Area',
    distributor: 'Distributor',
    shopType: 'Shop Type',
    shop: 'Shop',
    cutype: 'Cutting Type',
    productType: 'Product Type',
    settingVoucher: 'Promotion Voucher',
    itemProduct: 'Item Product',
    kuota: 'Promotion Quota',
    typeBuy: 'Program & Purchase Type',
    format: 'Discount Format',
    nilaiPromosi: 'Promotion Value',
    periode: 'Period',
    addPromotion: 'Add Promotion',
    purchaseRange: 'Purchase Range',
    promotionFormat: 'Promotion Format',
    usageQuota: 'Usage Quota',
    minimumReq: 'Minimum Requirement',
  },
  finance: {
    list_transfer: 'List of Transfer',
    search: 'Search Transfer',
    pay_number: 'Pay Number',
    method: 'Method',
    total_bill: 'Total Bill',
    rest_of_bill: 'Rest Of Bill',
    payment_deadline: 'Request Date',
    payment_status: 'Payment Status',
    invoice_number: 'No. Invoice',
    customer_name: 'Customer Name',
    order_number: 'No. Order',
    payment_confirm: 'Payment Confirm',
    payment_reject: 'Payment Reject',
    transaction_detail: 'Transaction Detail',
    order_id: 'Order ID',
    transaction_date: 'Transaction Date',
    bill_amount: 'Bill Amount',
    discount_use: 'Discount Use',
    point_use: 'Point Use',
    payment_date: 'Payment Date',
    payment_type: 'Payment Type',
    bank_name: 'Bank Name',
    account_number: 'Account Number',
    transfer_evidence: 'Transfer Evidence',
    input_payment: 'Payment Amount',
    mistake: 'Mistake',
    select_mistake: 'Select Mistake',
    expired_date: 'Expired Date',
    payment_amount: 'Payment Amount',
    need_confirmation: 'Need Confirmation',
    confirmed: 'Confirmed',
    waiting: 'Need Confirmation',
    cancelled: 'Cancelled',
    rejected: 'Rejected',
    outstanding: 'Outstanding',
    confirm_transfer: 'Confirm Transfer',
    payment_history: 'Payment History',
    create_payment: 'Create Payment',
    total_pay: 'Total Payment',
    pay_date: 'Payment Date',
    pay_method: 'Payment Method',
    pay_name: 'Payment Name',
    order_detail: 'Order Detail',
    create: 'Create',
    account_name: 'Account Name',
    payout_bank_name: 'Payout Bank Name',
    payout_account_number: 'Payout Account Number',
    payout_account_name: 'Payout Account Name',
  },
  withdrawal: {
    distributor_wallet: 'Distributor Wallet',
    withdraw_wallet: 'Withdraw Wallet',
    history_wallet: 'History Wallet',
    estimated_balance: 'Estimasi Total Saldo berikutnya',
    disclaimer_balance: 'Nilai total saldo diatas, belum termasuk biaya-biaya lain yang berlaku',
    total_balance: 'Total Balance',
    withdrawal_history: 'Withdrawal History',
    request_date: 'Request Date',
    nominal: 'Nominal',
    date: 'Date',
    status: 'Status',
    request_id: 'Request ID',
    bank_details: 'Bank Details',
    bank_name: 'Bank Name',
    bank_account: 'Bank Account',
    account_number: 'Account Number',
    account_name: 'Account Name',
    benificiary_name: 'Benificiary Name',
    processing_date: 'Processing Date',
    withdrawal_form: 'Withdrawal Form Request',
    withdrawal_amount: 'Withdrawal Amount',
    minimum_withdrawal: 'Minimum withdrawal',
    withdrawal_fee: 'Withdrawal fee',
    data_correct: 'All data is correct',
    select_bank: 'Select Bank',
    all: 'All',
    draft: 'Draft',
    confirmed: 'Approved',
    canceled: 'Canceled',
    completed: 'Completed',
    approved: 'Approved',
    rejected: 'Rejected',
    processed: 'Processed',
    failed: 'Failed',
    terms1: '* Maksimal penarikan {max_frequency}x dalam {frequency_time_value} {frequency_time_unit}',
    terms2: '* Proses transfer dilakukan maksimal 2x24 jam setelah request dikirim',
    terms3: '* Minimum penarikan {min_amount} dan maksimum penarikan {max_amount}',
    text_min_amount: 'Minimum penarikan {min_amount}',
    text_max_amount: 'Maksimum penarikan {max_amount}',
    month: 'month(s)',
    week: 'week(s)',
    day: 'day(s)',
  },
  helpdesk_page: {
    title: 'Is there anything we can help with?',
    info: `If you need assistance, please contact CS Kino Siap \nat the mobile number or email address listed.`,
  },
  error: {
    CL_NOT_ELIGIBLE: '',
    CL_INSUFFICIENT: 'Credit Limit Balance not enough',
    CL_EXCEED_MULTIPLEBOND: 'Oportunity for debt is over',
    CL_OVERDUE: 'Credit Limit Transaction with status Waiting is Overdue',
    WP_NOT_FOUND: 'No Waiting Payment Transaction Found',
  },
  tiktok: {
    categoryMaster: 'Product Master Category',
    categoryTiktok: 'Product Tiktok Category',
    categoryShopee: 'Product Shopee Category',
    description:
      'Upload size chart that the product has. Minimum photo dimensions are {minWidth}x{minHeight} px. The maximum file size is 1MB. With JPG, JPEG, PNG, BMP, GIF formats.',
    garansi: 'Warranty Period',
    policy: 'Warranty Policy',
  },
  adjustment: {
    stock: 'Stock Adjustment',
    create: 'Create Adjustment',
    id: 'Adjustment ID',
    date: 'Adjustment Date',
    type: 'Adjustment Type',
    search: 'Search Adjustment',
    detail: 'Detail Adjustment',
    chooseType: 'Choose Adjustment Type',
    note: 'Notes',
    detailItem: 'Detail Product Item',
    addItem: 'Add Item',
    stockAvailable: 'Stock Available',
    stockChanges: 'Stock Changes',
    stockAfterChange: 'Stock After Change',
    adjust: 'Adjust',
    sync: 'Sync Stock',
    choose_sync_warehouse: 'Select warehouses whose stock will be synchronized',
    all_warehouse: 'All Warehouse',
    is_syncing: 'Currently synchronizing',
    last_sync: 'Last sync',
  },
  channel: {
    category: {
      default: 'Channel Category',
      tiktok: 'Tiktok Product Category',
      tiktok_id: 'Tiktok Product Category',
      tokopedia_id: 'Tokopedia Product Category',
      shopee_id: 'Shopee Product Category',
    },
    product: {
      tiktok: 'Tiktok Product',
      tiktok_id: 'Tiktok Product',
      tokopedia_id: 'Tokopedia Product',
      shopee_id: 'Shopee Product',
    },
  },
  sales: {
    date_from: 'Periode',
    business_id_sales: 'Business',
    sales_channel_id: 'Channel',
    warehouse_id: 'Warehouse',
    customer_name: 'Customer Name',
    courier_name: 'Courier Name',
  },
}

export const localeAntd = antd
