export const types = {
  SET_LOADING: 'SET_LOADING',
  SET_USER_STORE: 'SET_USER_STORE',
  SET_BUSINESS_LIST: 'SET_BUSINESS_LIST',
  SET_WORKSPACE_ID_ACTIVE: 'SET_WORKSPACE_ID_ACTIVE',
  SET_WORKSPACE_ID_ACTIVE_NAME: 'SET_WORKSPACE_ID_ACTIVE_NAME',
  SET_ROLE_ID: 'SET_ROLE_ID',
  SET_ROLE_NAME: 'SET_ROLE_NAME',
  SET_RESTRICTION_BASE: 'SET_RESTRICTION_BASE',
  SET_USER_MENU: 'SET_USER_MENU',
  SET_USER_KEY: 'SET_USER_KEY',
  LOGOUT: 'LOGOUT',
}

const mutations = {
  [types.SET_LOADING]: (state, payload) => {
    state.loading = payload
  },
  [types.SET_USER_STORE]: (state, payload) => {
    Object.assign(state, {
      ...payload,
    })
  },
  [types.LOGOUT]: (state, payload) => {
    state.logout = payload
  },
  [types.SET_BUSINESS_LIST]: (state, payload) => {
    state.businessList = payload
  },
  [types.SET_WORKSPACE_ID_ACTIVE]: (state, payload) => {
    state.workspace_id_active = payload
  },
  [types.SET_WORKSPACE_ID_ACTIVE_NAME]: (state, payload) => {
    state.workspace_id_active_name = payload
  },
  [types.SET_ROLE_ID]: (state, payload) => {
    state.role_id = payload
  },
  [types.SET_ROLE_NAME]: (state, payload) => {
    state.role_name = payload
  },
  [types.SET_RESTRICTION_BASE]: (state, payload) => {
    state.restriction_base = payload
  },
  [types.SET_USER_MENU]: (state, payload) => {
    state.userMenu = payload
  },
  [types.SET_USER_KEY]: (state, payload) => {
    state.key = payload
  },
}

export default mutations
