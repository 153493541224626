export const types = {
  SET_CHANNEL_STORE: 'SET_CHANNEL_STORE',
}

const mutations = {
  [types.SET_CHANNEL_STORE]: (state, payload) => {
    Object.assign(state, {
      ...payload,
    })
  },
}

export default mutations
