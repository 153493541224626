var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[_vm.isFirst || _vm.isCollapsed ? 'is-collapsed' : '', 'sidebar']},[_c('a-layout-sider',{class:[_vm.$style.menu],attrs:{"collapsible":"","width":_vm.settings.leftMenuWidth,"collapsed":_vm.settings.isMenuCollapsed && !_vm.settings.isMobileView},model:{value:(_vm.isCollapsed),callback:function ($$v) {_vm.isCollapsed=$$v},expression:"isCollapsed"}},[(_vm.collapsed)?_c('div',{staticClass:"text-center"},[_c('img',{attrs:{"src":require("@/assets/images/logo_small.png"),"alt":"Powerbiz"}})]):_c('img',{class:[_vm.$style.logo],attrs:{"src":require("@/assets/images/logo.svg"),"alt":"Powerbiz","draggable":"false"}}),_c('a-skeleton',{class:{ 'mt-4': _vm.isLoading },attrs:{"active":"","title":false,"loading":_vm.isLoading,"paragraph":{ rows: 10, width: '100%' }}},[_c('a-menu',{class:_vm.$style.navigation,style:({
          paddingBottom: '15rem',
        }),attrs:{"force-sub-menu-render":"","mode":'inline',"selected-keys":_vm.selectedMenu,"open-keys":_vm.openKeys,"inline-indent":9},on:{"click":_vm.handleClick,"openChange":_vm.handleOpenChange}},[_vm._l((_vm.menuData),function(item){return [_c('a-menu-item',{key:item.key,staticClass:"group-menu"},[_vm._v(" "+_vm._s(item.title)+" ")]),_vm._l((item.children),function(menu){return [(!menu.children && !menu.category)?_c('item',{key:menu.key,attrs:{"menu-info":menu}}):_vm._e(),(menu.children)?_c('sub-menu',{key:menu.key,attrs:{"menu-info":menu}}):_vm._e()]})]}),(_vm.$route.query['business_id'] || _vm.$route.query['warehouse_id'])?[(_vm.permission.includes('WRITE'))?_c('a-menu-item',{key:"add-store",staticClass:"store mt-4"},[(
                _vm.$route.query['business_id'] || _vm.$route.query['warehouse_id']
              )?_c('router-link',{staticClass:"wrap-btn mb-4",attrs:{"to":_vm.to()}},[_c('a-icon',{staticStyle:{"font-size":"18px"},attrs:{"type":"plus-square"}}),_vm._v(" "+_vm._s(_vm.isWarehouse ? _vm.$t(`sideBar.add_warehouse`) : _vm.$t(`sideBar.add_store`))+" ")],1):_vm._e()],1):_vm._e(),_c('a-menu-item',{key:"search-store",staticClass:"store-search"},[_c('div',{staticClass:"wrap-input-search"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.valSearch),expression:"valSearch"}],attrs:{"type":"text","placeholder":_vm.isWarehouse
                    ? _vm.$t(`sideBar.type_warehouse_name`)
                    : _vm.$t(`sideBar.type_shop_name`)},domProps:{"value":(_vm.valSearch)},on:{"input":function($event){if($event.target.composing)return;_vm.valSearch=$event.target.value}}}),_c('a-icon',{attrs:{"type":"search"}})],1)]),(_vm.channelData)?[_vm._l((_vm.channelData),function(menu){return [(!menu.children && !menu.category)?_c('item',{key:menu.key,attrs:{"menu-info":menu,"selected-keys":_vm.selectedMenu}}):_vm._e(),(menu.children)?_c('sub-menu',{key:menu.key,attrs:{"selected-keys":_vm.selectedMenu,"menu-info":menu}}):_vm._e()]})]:_vm._e(),(_vm.isFetch && (!_vm.channelData || !_vm.channelData.length))?[_vm._v(" "+_vm._s(_vm.$t(`sideBar.no_shop`))+" ")]:_vm._e()]:_vm._e()],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }