<template>
  <a-modal :width="600" :visible="visible" :wrap-class-name="$style.modalSync" :closable="false">
    <template>
      <div class="w-100 text-center">
        <img
          class="image-spin"
          src="@/assets/images/work-in-progress.png"
          width="100"
          heigh="100"
          height="auto"
        />
      </div>
      <h4 class="text-center">
        Mohon menunggu
      </h4>
      <h5 class="text-center">
        Proses penyesuaian produk gudang sedang berlangsung
      </h5>
      <h6 class="text-center mt-4">
        Anda dapat menutup informasi ini dan proses tetap berjalan
      </h6>
    </template>
    <template #footer>
      <template>
        <a-button
          @click.prevent="onMinimizeNotification"
        >
          Tutup
        </a-button>
      </template>
    </template>
  </a-modal>
</template>

<script>
import getQueryParam from '@/utils/getQueryParam'

export default {
  name: 'ModalNotificationWaitingExport',
  components: {
  },
  mixins: [getQueryParam],
  props: {
  },
  data() {
    return {
      visible: false,
    }
  },
  computed: {
  },
  watch: {
    '$store.state.products.showModalAdjustWarehouseProduct': {
      deep: true,
      immediate: true,
      handler: function (value) {
        this.visible = value
      },
    },
  },
  methods: {
    onMinimizeNotification() {
      this.$store.commit('products/SET_STATE_ADJUST_WAREHOUSE_PRODUCT', {
        showModalAdjustWarehouseProduct: false,
        backgroundNotificationAdjustWarehouseProduct: true,
      })
    },
  },
}
</script>

<style lang="scss" module>
// @import './style.module.scss';
</style>
<style lang="scss">
.image-spin {
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}
</style>