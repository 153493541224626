import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import * as actions from './actions'

import Cookies from 'js-cookie'

Vue.use(Vuex)

const state = {
  loading: false,
  channel: {},
  channels: [],
  saleschannel: [],
  channelToSetup: Cookies.get('channelToSetup')
    ? JSON.parse(Cookies.get('channelToSetup'))
    : null,
  channelIdConnected: null || Cookies.get('channelIdConnected') || 16, // TODO: to be deleted
  selectChannelFromListIntegration: {},
  editTitle: {},
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
