<template>
  <a-dropdown
    v-model="visible"
    :overlay-class-name="$style.customeMenuUser"
    :trigger="['click']"
  >
    <template #overlay>
      <div :class="$style.wrapMenuUserNav + ' shadow-sm'">
        <div :class="$style.headUserMenu">
          <span>
            <a-icon type="user" />
          </span>
          <h5>{{ userData.full_name }}</h5>
          <h6>{{ userData.role_name }}</h6>
        </div>
        <div :class="$style.workSpaceActive">
          <a-icon type="desktop" /> {{ activeWorkspace.name }}
        </div>
        <div v-if="listWorkspace.length > 1" :class="$style.workSpaceSwitch">
          {{ $t('topBar.profileMenu.switch_to') }}
        </div>
        <div :class="$style.listWorkSpace">
          <a-menu @click="onClick">
            <template v-for="item in listWorkspace">
              <a-menu-item
                v-if="activeWorkspace.id !== item.workspace_id"
                :key="item.workspace_id"
                class="user-select-none workspace"
              >
                <router-link
                  :to="{
                    path: '/dashboard',
                    query: {
                      ...getQueryParam({ ...$route.query }),
                    },
                  }"
                >
                  <a-icon type="desktop" /> {{ item.workspace_name }}
                </router-link>
              </a-menu-item>
            </template>
          </a-menu>
        </div>
        <div :class="$style.otherMenu">
          <a-menu @click="onClick">
            <a-menu-item key="setting" class="setting user-select-none">
              <router-link
                :to="{
                  path: '/account-setting',
                  query: {
                    ...getQueryParam({ ...$route.query }),
                  },
                }"
              >
                <a-icon type="setting" style="font-size: 18px" /> {{ $t('topBar.profileMenu.account_setting') }}
              </router-link>
            </a-menu-item>
            <a-menu-item key="logout" class="user-select-none">
              <a-icon type="logout" style="font-size: 18px" /> {{ $t('topBar.profileMenu.logout') }}
            </a-menu-item>
          </a-menu>
        </div>
      </div>
    </template>
    <a-button :class="$style.buttonDropdownUser" @click.prevent>
      <span>
        <a-icon type="user" />
      </span>
      <a-icon type="down" style="font-size: 18px" />
    </a-button>
  </a-dropdown>
</template>

<script>
import dataNavbar from './../composables/navbar'
import getQueryParam from '@/utils/getQueryParam'

export default {
  mixins: [getQueryParam],
  setup(props, ctx) {
    const { handleLogout } = dataNavbar(ctx)
    return { handleLogout }
  },
  data() {
    return {
      visible: false,
      isTouch: false,
    }
  },
  computed: {
    userData() {
      return this.$store.state.user
    },
    listWorkspace() {
      return this.$store.state.user.user_workspaces
    },
    activeWorkspace() {
      return {
        id: this.$store.state.user.workspace_id_active,
        name: this.$store.state.user.workspace_id_active_name,
      }
    },
  },
  methods: {
    onClick(e) {
      if (this.isTouch) return

      this.visible = false
      this.isTouch = false

      if (e.key === 'logout') this.handleLogout()

      const notRedirect = ['0switch', 'active', 'setting', 'head', 'logout']
      if (!notRedirect.includes(e.key)) {
        // const { workspace_id, business_id, ...rest } = this.$route.query
        this.$store
          .dispatch('user/LOGINPROCESSING', {
            token: this.$store.state.user.accessToken,
            key: e.key,
            routeId: this.$route.params.id,
          })
      }
    },
  },
}
</script>

<style lang="scss" module>
@import './../style.module.scss';
</style>
