import Vue from 'vue'
import Vuex from 'vuex'

import actions from './actions'
import mutations from './mutations'

Vue.use(Vuex)
const columns = [
  {
    title: 'Voucher Code',
    dataIndex: 'voucher_code',
    key: 'voucher_code',
    class: 'text-center w-20',
    width: '250px',
  },
  {
    title: 'Voucher Type',
    dataIndex: 'voucher_type',
    key: 'voucher_type',
    scopedSlots: { customRender: 'voucher_type' },
    class: 'text-center w-30',
  },
  {
    title: 'Channel',
    dataIndex: 'channel_name',
    key: 'channel_name',
    scopedSlots: { customRender: 'channel_name' },
    class: 'text-center w-30',
  },
  {
    title: 'Available',
    dataIndex: 'available_booked',
    key: 'available_booked',
    scopedSlots: { customRender: 'available_booked' },
    class: 'text-center w-20',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    scopedSlots: { customRender: 'status' },
    class: 'text-center w-15',
    // width: 150,
  },
  {
    title: 'Action',
    scopedSlots: { customRender: 'action_button' },
    class: 'text-center w-15',
    // width: 75,
  },
]

const state = {
  isLoadingDiscount: false,
  isLoadingTable: false,
  voucherList: [],
  columns,
  productList: [],
  pagination: {},
  paginateParams: [],
  tableActive: 'voucher_list',
  resellerList: [],
  courierList: [],
  rangeDate: {},
  keywordSearch: '',
  brands: [],
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
