import Vue from 'vue'
import Vuex from 'vuex'

import * as actions from './actions'
import mutations from './mutations'

Vue.use(Vuex)
const columns = [
  {
    title: 'Name',
    dataIndex: 'seller_name',
    key: 'seller_name',
    class: '',
    // sorter: (a, b) => a.name - b.name,
  },
  {
    title: 'Store Name',
    dataIndex: 'store_name',
    key: 'store_name',
    class: '',
  },
  {
    title: 'Email Address',
    dataIndex: 'user_email',
    key: 'user_email',
    class: '',
    width: 300,
  },
  {
    title: 'Phone Number',
    dataIndex: 'user_phone',
    key: 'user_phone',
    class: '',
    width: 150,
  },
  {
    title: 'Level',
    dataIndex: 'level_id',
    key: 'level',
    scopedSlots: { customRender: 'level' },
    class: '',
    width: 175,
  },
  {
    title: 'Status',
    dataIndex: 'store_status',
    key: 'store_status',
    scopedSlots: { customRender: 'store_status' },
    class: 'text-center',
    width: 75,
    // sorter: (a, b) => a.is_active - b.is_active,
  },
  // {
  //   title: 'Date Created',
  //   dataIndex: 'created_at',
  //   key: 'created_at',
  //   scopedSlots: { customRender: 'created_at' },
  //   class: '',
  //   // sorter: (a, b) => a.date - b.date
  // },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
    scopedSlots: { customRender: 'actions' },
    class: 'text-right',
    width: 100,
  },
]

const state = {
  isLoading: false,
  reseller_list: [],
  search: [],
  columns,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
