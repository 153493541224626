const formatCategory = (items = []) => {
  return items.map(item => {
    const category = {
      label: item.title,
      value: item.id,
    }

    if (item.children && item.children.length) {
      category.children = formatCategory(item.children)
    }

    return category
  })
}

const unwrapCategories = (categories = []) => {
  const list = []

  for (const index in categories) {
    const { id, parent_id, title, subtitle, slug, children } = categories[index]

    list.push({
      id,
      parentId: parent_id,
      title,
      subtitle,
      slug,
    })

    if (children && children.length) {
      list.push(...unwrapCategories(children, list))
    }
  }

  return list
}

export const getCategoryTreeById = (id, categories) => {
  if (!categories || !categories.length) return null

  let tree = []

  const category = categories.find(item => item.id === id)

  if (category) {
    tree.push(category.id)

    if (category.parentId) {
      tree = [...getCategoryTreeById(category.parentId, categories), ...tree]
    }
  }

  return tree
}

export const categoryTreeOptions = ({ categories }) => formatCategory(categories)

export const categories = ({ categories }) => unwrapCategories(categories)
