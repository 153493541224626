import { types as MutationTypes } from './mutations'
import { getProductMasterList, getProductCategories, getProductBrands } from '@/api/product'

export const GETCATEGORIES = async ({ commit }, payload) => {
  try {
    commit(MutationTypes.SET_PRODUCTS_STORE, {
      loading: true,
    })
    const response = await getProductCategories(payload)
    const { data } = response.data

    commit(MutationTypes.SET_PRODUCTS_STORE, {
      categories: data,
    })

    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_PRODUCTS_STORE, {
      loading: false,
    })
  }
}

export const GETBRANDS = async ({ commit }, payload) => {
  try {
    commit(MutationTypes.SET_PRODUCTS_STORE, {
      loading: true,
    })
    const response = await getProductBrands(payload.business_id)
    const { data } = response.data

    commit(MutationTypes.SET_PRODUCTS_STORE, {
      brands: data,
    })

    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_PRODUCTS_STORE, {
      loading: false,
    })
  }
}

export const GETPRODUCTLISTMASTER = async (
  { commit }, 
  { signal, ...params },
) => {
  try {
    commit(MutationTypes.SET_PRODUCTS_STORE, {
      loading: true,
    })

    const response = await getProductMasterList({ business_id: params.business_id, params })
    const { data } = response?.data || {}

    commit(MutationTypes.SET_PRODUCTS_STORE, {
      products: data || [],
    })

    return Promise.resolve(response.data)
  } catch (err) {
    return Promise.reject(err)
  } finally {
    commit(MutationTypes.SET_PRODUCTS_STORE, {
      loading: false,
    })
  }
}
