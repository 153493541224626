var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-dropdown',{attrs:{"overlay-class-name":_vm.$style.customeMenuUser,"trigger":['click']},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{class:_vm.$style.wrapMenuUserNav + ' shadow-sm'},[_c('div',{class:_vm.$style.headUserMenu},[_c('span',[_c('a-icon',{attrs:{"type":"user"}})],1),_c('h5',[_vm._v(_vm._s(_vm.userData.full_name))]),_c('h6',[_vm._v(_vm._s(_vm.userData.role_name))])]),_c('div',{class:_vm.$style.workSpaceActive},[_c('a-icon',{attrs:{"type":"desktop"}}),_vm._v(" "+_vm._s(_vm.activeWorkspace.name)+" ")],1),(_vm.listWorkspace.length > 1)?_c('div',{class:_vm.$style.workSpaceSwitch},[_vm._v(" "+_vm._s(_vm.$t('topBar.profileMenu.switch_to'))+" ")]):_vm._e(),_c('div',{class:_vm.$style.listWorkSpace},[_c('a-menu',{on:{"click":_vm.onClick}},[_vm._l((_vm.listWorkspace),function(item){return [(_vm.activeWorkspace.id !== item.workspace_id)?_c('a-menu-item',{key:item.workspace_id,staticClass:"user-select-none workspace"},[_c('router-link',{attrs:{"to":{
                  path: '/dashboard',
                  query: {
                    ..._vm.getQueryParam({ ..._vm.$route.query }),
                  },
                }}},[_c('a-icon',{attrs:{"type":"desktop"}}),_vm._v(" "+_vm._s(item.workspace_name)+" ")],1)],1):_vm._e()]})],2)],1),_c('div',{class:_vm.$style.otherMenu},[_c('a-menu',{on:{"click":_vm.onClick}},[_c('a-menu-item',{key:"setting",staticClass:"setting user-select-none"},[_c('router-link',{attrs:{"to":{
                path: '/account-setting',
                query: {
                  ..._vm.getQueryParam({ ..._vm.$route.query }),
                },
              }}},[_c('a-icon',{staticStyle:{"font-size":"18px"},attrs:{"type":"setting"}}),_vm._v(" "+_vm._s(_vm.$t('topBar.profileMenu.account_setting'))+" ")],1)],1),_c('a-menu-item',{key:"logout",staticClass:"user-select-none"},[_c('a-icon',{staticStyle:{"font-size":"18px"},attrs:{"type":"logout"}}),_vm._v(" "+_vm._s(_vm.$t('topBar.profileMenu.logout'))+" ")],1)],1)],1)])]},proxy:true}]),model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('a-button',{class:_vm.$style.buttonDropdownUser,on:{"click":function($event){$event.preventDefault();}}},[_c('span',[_c('a-icon',{attrs:{"type":"user"}})],1),_c('a-icon',{staticStyle:{"font-size":"18px"},attrs:{"type":"down"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }