import Vue from 'vue'
import Vuex from 'vuex'

import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

Vue.use(Vuex)

const state = {
  isLoadingProduct: false,
  products: [],
  totalRows: 0,
  categories: [],
  productAttributes: [],
  vendorProductAttributes: [],
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
