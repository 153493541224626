import axios from '@/services/web-platform'
import axiosPB from '@/services/axios'
import * as MutationTypes from './mutation-types'

export const getChannels = async ({ state, commit, dispatch }, payload) => {
  const force = false
  if (!state.list || !state.list.length || force) {
    try {
      dispatch('getChannelPriceLevelDefaultValue', payload)
      const options = {
        method: 'get',
        url: 'users/private/channels',
        params: {
          ...payload,
        },
      }
      const { data: response } = await axios(options)
      commit(MutationTypes.SET_CHANNELS, response.message)
      return Promise.resolve(response.message)
    } catch (err) {
      return Promise.reject(err)
    }
  } else {
    return Promise.resolve(state.list)
  }
}

export const getChannelPriceLevelDefaultValue = async ({ state, commit }, payload) => {
  const priceLevelDefaultValue = state.priceLevels
  const force = false

  const { id } = payload
  const except = ['limit', 'page', 'business_id']
  Object.keys(payload).forEach(
    key => except.includes(key) || delete payload[key],
  )

  if (priceLevelDefaultValue || priceLevelDefaultValue.length || force) {
    try {
      const options = {
        method: 'get',
        url: '/channel/store/reseller/query/reseller/level',
        headers: {
          'Content-Type': 'application/json',
          'business-id': payload.business_id,
        },
        params: {
          channel_id: id,
          ...payload,
        },
      }
      const { data: response } = await axiosPB(options)
      const list = response.data.sort((a, b) => Number(a.level_id) - Number(b.level_id))
      commit(MutationTypes.SET_PRICE_LEVEL_DEFAULT_VALUE, list)
      return Promise.resolve(list)
    } catch (err) {
      return Promise.reject(err)
    }
  } else {
    return Promise.resolve(priceLevelDefaultValue)
  }
}
