import { types as MutationTypes } from '@/store/integration/mutations'
import apiClient from '@/services/axios'

export const SYNCTIKTOK = async ({ commit }, values) => {
  commit(MutationTypes.SET_LOADING_SYNC, true)
  try {
    const response = await apiClient({
      method: 'get',
      headers: {
        'company-aggregator-id': values,
      },
      url: '/channel/tiktok/integration/query/auth/authorized-url',
    })

    if(response.status === 200) {
      commit(MutationTypes.SET_LOADING_SYNC, false)
    }

    return Promise.resolve(response)
  } catch(e) {
    commit(MutationTypes.SET_LOADING_SYNC, false)
    console.error(e)
  }
}

export const SYNCSHOPEE = async ({ commit }, { sales_channel, redirect_url, business }) => {
  commit(MutationTypes.SET_LOADING_SYNC, true)
  try {
    const response = await apiClient({
      url: '/channel/shopee_id/integration/cmd/auth-url',
      method: 'post',
      data: {
        sales_channel,
        redirect_url,
      },
      headers: {
        ['Business-id']: business,
      },
    })

    if(response.status === 200) {
      commit(MutationTypes.SET_LOADING_SYNC, false)
    }
    return Promise.resolve(response)
  } catch(e) {
    commit(MutationTypes.SET_LOADING_SYNC, false)
    console.error(e)
  }
}

export const SYNCLAZADA = async ({ commit }, { redirect_url }) => {
  commit(MutationTypes.SET_LOADING_SYNC, true)
  try {
    const response = await apiClient({
      url: '/channel/lazada_id/integration/auth-url',
      method: 'post',
      data: {
        redirect_url,
      },
    })
    
    if(response.status === 200 || response.status.toLowerCase() === 'success') {
      commit(MutationTypes.SET_LOADING_SYNC, false)
    }
    return Promise.resolve(response)
  } catch(e) {
    commit(MutationTypes.SET_LOADING_SYNC, false)
    console.error(e)
  }
}

export const SYNCSHOPEECONFIRM = async ({ commit }, { sales_channel, client_id, business, shop_id, code }) => {
  commit(MutationTypes.SET_LOADING_SYNC, true)
  try {
    const response = await apiClient({
      url: '/channel/shopee_id/integration/cmd/authorize',
      method: 'post',
      data: {
        sales_channel,
        client_id,
        shop_id,
        code,
      },
      headers: {
        ['Business-id']: business,
      },
    })

    if(response.status === 200) {
      commit(MutationTypes.SET_LOADING_SYNC, false)
    }
    return Promise.resolve(response)
  } catch(e) {
    commit(MutationTypes.SET_LOADING_SYNC, false)
    console.error(e)
    return Promise.reject(e)
  }
}

export const SYNCLAZADACONFIRM = async ({ commit }, { sales_channel, client_id, business_id, code }) => {
  commit(MutationTypes.SET_LOADING_SYNC, true)
  try {
    const response = await apiClient({
      url: '/channel/lazada_id/integration/authorize',
      method: 'post',
      data: {
        sales_channel,
        client_id,
        code,
      },
      headers: {
        ['Business-id']: business_id,
      },
    })
    if(response.status === 200 || response.status.toLowerCase() === 'success') {
      commit(MutationTypes.SET_LOADING_SYNC, false)
    }
    return Promise.resolve(response)
  } catch(e) {
    commit(MutationTypes.SET_LOADING_SYNC, false)
    console.error(e)
    return Promise.reject(e)
  }
}

export const GETLISTSHOP = async ({ commit, rootState }) => {
  try {
    const { integration } = rootState
    const response = await apiClient({
      method: 'post',
      headers: {
        'company-aggregator-id': parseInt(integration.marketplace.aggregator_id),
        'Business-id': integration.marketplace.business_id,
      },
      url: `/channel/${integration.marketplace.name}/integration/cmd/shops`,
      data: {
        auth_code: integration.marketplace.code,
      },
    })

    if(response.status === 201) {
      commit(MutationTypes.SET_LIST_SHOP, response.data.data)
    }

    return Promise.resolve(response)
  } catch(e) {
    console.error(e)
    setTimeout(() => {
      commit(MutationTypes.SET_LOADING_SYNC, false)
    }, 2000)
  }
}

export const ADDSHOP = async ({ commit, dispatch, rootState }, values) => {
  try {
    const { integration } = rootState
    let payload = values.data
    const response = await apiClient({
      method: 'post',
      headers: {
        'company-aggregator-id': parseInt(integration.marketplace.aggregator_id),
        'Business-Id': values.business_id,
      },
      url: `/channel/${integration.marketplace.name}/integration/cmd/shops/${integration?.listShop?.client_id}`,
      data: payload,
    })

    if(response.status === 202) {
      commit(MutationTypes.SET_MODAL_ADD_SHOP, false)
      commit(MutationTypes.SET_MODAL_SUCCESS_ADD_SHOP, true)
    }
    
    if(response.data.data.channel_id) {
      await dispatch('SYNC_PRODUCT', {
        business_id: values.business_id,
        id: response.data.data.channel_id,
        channel: integration.marketplace.name,
        user_id: integration.marketplace.name === 'tokopedia_id'
          ? rootState.user.id + '/'
          : '',
        shop_id: values.data.shop_id,
      })
    }

    return Promise.resolve(response)
  } catch(e) {
    const status = e?.response?.status || 0
    const message = status === 409 ? 'Toko ini sudah dikonfirmasi' : e?.response?.data?.message
    commit(MutationTypes.SET_MESSAGE, message || null)
    console.error(e)
  }
}

export const ADDSTOREMARKETPLACE = async ({ commit, dispatch }, values) => {
  commit(MutationTypes.SET_MARKETPLACE, values)
  commit(MutationTypes.SET_LOADING_SYNC, true)
  const data = await dispatch('GETLISTSHOP')
  if(data.status === 201) {
    if (values?.name === 'tiktok') {
      const { data: authData } = data
      const confirmTiktok = await dispatch('ADDSHOP', {
        business_id: values.business_id,
        data: {
          shop_id: authData?.data.authorized_shops[0]?.shop_id,
          shop_name: authData?.data.authorized_shops[0]?.shop_name,
          channel_id: authData?.data.channel_id,
        },
      })
      commit(MutationTypes.SET_LOADING_SYNC, false)
      return Promise.resolve(data)
    } else {
      commit(MutationTypes.SET_LOADING_SYNC, false)
      return Promise.resolve(data)
    }
  }
}

export const CANCELSTOREMARKETPLACE = async ({ commit }) => {
  commit(MutationTypes.SET_MARKETPLACE, {})
  commit(MutationTypes.SET_LOADING_SYNC, false)
  commit(MutationTypes.SET_MODAL_SUCCESS_ADD_SHOP, false)
  commit(MutationTypes.SET_MODAL_ADD_SHOP, false)
}

export const SYNC_PRODUCT = (_, { business_id, id, channel, user_id, ...data }) => {
  return apiClient({
    method: 'post',
    url: `/channel/${channel}/product/cmd/${id}/${user_id}import`,
    data,
    headers: {
      'Business-Id': business_id,
    },
    notifications: true,
  })
}

export const INTEGRATIONSALESCHANNEL = async (
  { commit },
  { channelId, channelCode, business, ...values },
) => {
  try {
    commit(MutationTypes.SET_LOADING_SYNC, true)
    const response = await apiClient({
      method: 'post',
      url: `channel/${channelCode}/integration/cmd/connect`,
      data: {
        sales_channel: {
          id: channelId,
          code: channelCode,
        },
        ...values,
      },
      headers: {
        ['Business-Id']: business,
      },
    })
    const { data } = response.data
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_LOADING_SYNC, false)
  }
}

export const CONFIRMATIONINTEGRATIONSALESCHANNEL = async (
  { commit },
  { channelId, channelCode, business, aggregator_id, shop_id, warehouse_id },
) => {
  try {
    commit(MutationTypes.SET_LOADING_SYNC, true)
    const response = await apiClient({
      method: 'post',
      url: `channel/${channelCode}/integration/cmd/${channelId}/confirm`,
      data: {
        aggregator_id,
        shop_id,
        warehouse_id,
      },
      headers: {
        ['Business-id']: business,
      },
    })
    const { data } = response.data
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_LOADING_SYNC, false)
  }
}

export const UPDATEWAREHOUSECHANNEL = async (
  { commit },
  { channel_code, business_id, payload, params },
) => {
  try {
    commit(MutationTypes.SET_LOADING_SYNC, true)
    const url = channel_code.includes('blibli')
      ? '/v2'
      : ''
    const response = await apiClient({
      method: 'post',
      url: `channel/${channel_code}/integration/cmd/warehouse/map${url}`,
      params: params,
      data: payload,
      headers: {
        ['Business-id']: business_id,
      },
    })
    const { data } = response.data
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_LOADING_SYNC, false)
  }
}

export const UPDATEWAREHOUSELAZADA = async (
  { commit },
  { channel_id, business_id, payload },
) => {
  try {
    commit(MutationTypes.SET_LOADING_SYNC, true)
    const response = await apiClient({
      method: 'post',
      url: `channel/lazada_id/integration/${channel_id}/warehouse`,
      data: payload,
      headers: {
        ['Business-id']: business_id,
      },
    })
    const { data } = response.data
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_LOADING_SYNC, false)
  }
}