export const types = {
  SET_INTEGRATION_STORE: 'SET_INTEGRATION_STORE',
  SET_LOADING_SYNC: 'SET_LOADING_SYNC',
  SET_MARKETPLACE: 'SET_MARKETPLACE',
  SET_MODAL_ADD_SHOP: 'SET_MODAL_ADD_SHOP',
  SET_LIST_SHOP: 'SET_LIST_SHOP',
  SET_MODAL_SUCCESS_ADD_SHOP: 'SET_MODAL_SUCCESS_ADD_SHOP',
  SET_MODAL_INTEGRATION_ADD_WAREHOUSE: 'SET_MODAL_INTEGRATION_ADD_WAREHOUSE',
  SET_MESSAGE: 'SET_MESSAGE',
}

const mutations = {
  [types.SET_INTEGRATION_STORE]: (state, payload) => {
    Object.assign(state, {
      ...payload,
    })
  },
  [types.SET_LOADING_SYNC]: (state, payload) => {
    state.loadingSync = payload
  },
  [types.SET_MARKETPLACE]: (state, payload) => {
    state.marketplace = payload
  },
  [types.SET_MODAL_ADD_SHOP]: (state, payload) => {
    state.modalAddShop = payload
  },
  [types.SET_LIST_SHOP]: (state, payload) => {
    state.listShop = payload
  },
  [types.SET_MODAL_SUCCESS_ADD_SHOP]: (state, payload) => {
    state.modalSuccessAddShop = payload
  },
  [types.SET_MODAL_INTEGRATION_ADD_WAREHOUSE]: (state, payload) => {
    state.modalIntegrationAddWarehouse = payload
  },
  [types.SET_MESSAGE]: (state, payload) => {
    state.message = payload
  },
}

export default mutations
