import Axios from 'axios'

const ENDPOINT = 'https://upload.imagekit.io/api/v1/files/upload'

/**
 * @typedef {{
    file: File,
    publicKey: String,
    signature: String,
    expire: Number,
    token: String,
    useUniqueFileName?: Boolean
    folder?: String,
    isPrivateFile?: Boolean
    fileName?: String,
  }} ImageOption

  @typedef {{
    fileId : String,
    name: String,
    url: String,
    thumbnailUrl: String,
    height : Number,
    width : Number,
    size : Number,
    filePath: String,
    tags: "t-shirt" | "round-neck" | "sale2019",
    isPrivateFile: Boolean,
    customCoordinates?: null,
    fileType: String
  }} ImageKitResponse
 */

/**
 * @type {ImageOption}
 */
const DEFAULT_UPLOAD_OPTIONS = {
  isPrivateFile: false,
  useUniqueFileName: true,
}

/**
 * Upload File to imagekit
 *
 * @param {ImageOption} options
 *
 * @returns {Promise<ImageKitResponse>}
 */
export const upload = async (options) => {
  const payload = options.payload;
  delete options.payload;

  const data = {
    ...DEFAULT_UPLOAD_OPTIONS,
    ...options,
    fileName: options.fileName || options.file.name,
  }

  const form = new FormData()

  Object.keys(data).forEach(key => {
    form.append(key, data[key])
  })

  try {
    const { data: response } = await Axios.post(ENDPOINT, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        ...payload,
      },
    })
    return Promise.resolve(response)
  } catch (err) {
    return Promise.reject(err)
  }
}
