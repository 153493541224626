/* eslint-disable no-unused-vars */
import apiClient from '@/services/web-platform'
import bizClient from '@/services/axios'
import { types as MutationTypes } from './mutations'
import { requestToken } from '@/services/web-platform/image/token'
import { upload } from '@/services/web-platform/image/imagekit'

const DEFAULT_CURRENCY = {
  id: 111,
  code: 'IDR',
  symbol: 'Rp',
}

const DEFAULT_COUNTRY = {
  id: 228,
  name: 'Indonesia',
}

export const getProducts = async (
  { commit, state },
  payload,
) => {
  commit('SET_LOADING_PRODUCT', true)

  const { page = 1, limit = 35, query = null } = payload;

  try {
    const { data: response } = await apiClient.get('/products/private', {
      params: {
        ...payload,
        page,
        limit,
        q: query,
        // limit,
        // query,
      },
    })
    commit(MutationTypes.SET_PRODUCTS, response)
    return Promise.resolve(response)
  } catch (err) {
    return Promise.reject(err)
  } finally {
    commit('SET_LOADING_PRODUCT', false)
  }
}

export const getProduct = async ({ commit }, payload) => {

  const { uuid } = payload;

  try {
    const { data: response } = await apiClient.get(`/products/private/${uuid}`, {
      params: {
        ...payload,
      },
    })
    const product = response.data

    product.catalog_list = product.catalog_list.map(catalog => {
      catalog.inventories = product.inventories
        ? product.inventories.filter(item => item.catalog_id === catalog.uuid)
        : []

      return catalog
    })

    return Promise.resolve(product)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const GET_PRODUCT = async ({ commit, rootState }, { id, business_id, ...params } = {}) => {
  const userId = rootState.user?.id
  
  const { data: response } = await bizClient.get(`/channel/store/product/query/${id}`, {
    params,
    headers: {
      'user-id': userId,
      'business-id': business_id,
    },
  })

  return response.data
}

export const save = async ({ commit }, payload) => {

  const {
    attributes,
    images,
    inventories,
    skus,
    discount_list,
    indexShowImg,
    indexThumbImg,
    ...product
  } = payload

  const exists = !!product.id

  const getPriceDiscount = (sku, price) => {
    const findProductDiscount = discount_list.find(
      item => item.catalog_sku === sku,
    )
    if (findProductDiscount) {
      const priceDiscount =
        price * ((100 - findProductDiscount.discount_value) / 100)
      return Number(priceDiscount)
    } else {
      return Number(price)
    }
  }

  try {
    const newImages = images
      .map((image, index) => ({ ...image, _key: index }))
      .filter(image => !!image.file)
    const newImagesCount = newImages.length

    const tokens = await requestToken({ qty: newImagesCount, ...payload })
    const uploadResponses = await Promise.all([
      ...newImages.map((image, index) =>
        upload({
          file: image.file,
          expire: tokens[index].expire,
          publicKey: tokens[index].public_key,
          signature: tokens[index].signature,
          token: tokens[index].token,
          folder: tokens[index].image_path,
        }),
      ),
    ])

    uploadResponses.forEach((response, index) => {
      const image = images[newImages[index]._key]
      image.name = response.name
      image.path = tokens[index].image_path
      image.type = response.fileType
      image.size = response.size
      image.tags = null
      image.ref_id = response.fileId
      image.is_thumb = false
      image.is_show = false
      image.url = response.url

      delete image.file
    })

    const { data: saveProductResponse } = await apiClient({
      method: exists ? 'PUT' : 'POST',
      url: exists ? `/products/private/${product.id}` : '/products/private',
      data: {
        product_data: {
          name: product.name,
          category_id: product.category[product.category.length - 1],
          description: [
            {
              short: product.shortDescription,
              long: product.description,
              lang_code: 'id',
            },
          ],
          is_physical: product.productType === 'physical' ? 1 : 0,
        },
        catalog_list: skus.map(({ sku, options, price, minimal_qty }) => {
          const parsedOptions = options.reduce((result, opt) => {
            result[opt.index] = opt.value

            return result
          }, [])

          return {
            sku,
            brand_sku: sku,
            title: [process.name, ...parsedOptions].filter(i => !!i).join(' '),
            name: [process.name, ...parsedOptions].filter(i => !!i).join(' '),
            options: [...parsedOptions].map(item => item || null),
            option: [...parsedOptions].map(item => item || null),
            product_length: product.length,
            product_width: product?.width || 0,
            product_height: product?.height || 0,
            product_weight: product?.weight?.value || 0,
            countries: [
              {
                value: DEFAULT_COUNTRY.id,
                label: DEFAULT_COUNTRY.name,
              },
            ],
            price_list: [
              {
                channel_id: product.channel,
                price_msrp: Number(price.msrp),
                price_sales:
                  discount_list.length === 0
                    ? Number(price.msrp)
                    : getPriceDiscount(sku, price.msrp), // check if has discount or not
                country_id: DEFAULT_COUNTRY.id,
                currency_id: DEFAULT_CURRENCY.id,
                currency_code: DEFAULT_CURRENCY.code,
                levels: price.level || [],
                minimal_qty,
              },
            ],
          }
        }),
        // image_data: images.map((image, index) => ({
        //   ...image,
        //   is_thumb: Number(index === 0),
        //   is_show: Number(index === 0),
        // })),
        image_data: images.map((image, index) => {
          if (index === 0) {
            return {
              ...image,
              tags: 'show',
              is_show: true,
              is_thumb: false,
            }
          } else if (index === 1) {
            return {
              ...image,
              tags: 'thumb',
              is_show: false,
              is_thumb: true,
            }
          } else {
            return {
              ...image,
              tags: null,
              is_show: false,
              is_thumb: false,
            }
          }
        }),
      },
    })

    product.id = saveProductResponse.data.uuid || product.id

    const skuIds = saveProductResponse.data.catalog_list.reduce(
      (result, item) => {
        if (!Object.prototype.hasOwnProperty.call(result, item.sku)) {
          result[item.sku] = item.uuid
        }

        return result
      },
      {},
    )

    const promises = []

    if (discount_list.length > 0 && !exists) {
      promises.push(
        apiClient.post('/products/private/discounts', {
          product_uuid: product.id,
          discount_list,
          channel_id: payload.channel,
        }),
      )
    }

    if (attributes.length) {
      promises.push(
        apiClient({
          method: 'post',
          url: '/data/private/product_attribute',
          data: attributes.map(item => {
            item.product_uuid = product.id
            return item
          }),
        }),
      )
    }

    const update_stock_payload = inventories.map(
      ({ sku, qty, warehouse_id, preOrder }) => ({
        catalog_uuid: skuIds[sku],
        new_stock: qty,
        uom: 'PCS',
        warehouse_id,
      }),
    )

    if (product.productType !== 'digital') {
      promises.push(
        apiClient({
          method: 'put',
          url: `/products/private/update_stock/${payload.channel}`,
          data: update_stock_payload,
        }),
      )
    }

    if (exists) {
      promises.push(
        apiClient.post('/products/private/price-discounts', {
          // channel_id: product.channel,
          channel_id: payload.channel,
          product_uuid: product.id,
          price_list: skus.map(({ sku, price, minimal_qty }) => ({
            catalog_sku: sku,
            catalog_uuid: skuIds[sku],
            price_msrp: price.msrp,
            price_sales:
              discount_list.length === 0
                ? Number(price.msrp)
                : getPriceDiscount(sku, price.msrp), // check if has discount or not
            levels: price.level || [],
            minimal_qty,
          })),
          discount_list,
          params: payload,
        }),
      )
    }

    await Promise.all(promises)

    return Promise.resolve(saveProductResponse.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const getCategories = async ({ commit, state }, payload) => {

  const { force } = payload

  if (!state.categories || !state.categories.length || force) {
    commit(MutationTypes.SET_CATEGORIES, undefined)

    try {
      const { data: response } = await apiClient.get(
        '/products/private/categories',
        {
          params: payload,
        },
      )
      commit(MutationTypes.SET_CATEGORIES, response.data)
      return Promise.resolve(response.data)
    } catch (err) {
      return Promise.reject(err)
    }
  } else {
    return Promise.resolve(state.categories)
  }
}

export const getDiscountList = async ({ commit, state }, payload) => {
  const { id } = payload;

  try {
    const { data: response } = await apiClient.get(
      `/products/private/discounts/${id}`,
      {
        params: payload,
      },
    )
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getVendorAttributes = async ({ commit }, payload) => {
  try {
    const { data: response } = await apiClient({
      method: 'get',
      url: '/data/private/vendor_product_attribute',
      params: payload,
    })
    // console.log(response)
    commit(MutationTypes.SET_VENDOR_PRODUCT_ATTRIBUTES, response.list)
    Promise.resolve()
  } catch (error) {
    Promise.reject(error)
  }
}

export const getProductAttributes = async ({ commit }, payload) => {
  const { uuid } = payload;

  try {
    const { data: response } = await apiClient({
      method: 'get',
      url: `/data/private/product_attribute/${uuid}`,
      params: payload,
    })
    commit(MutationTypes.SET_PRODUCT_ATTRIBUTES, response.list)
    Promise.resolve()
  } catch (error) {
    Promise.reject(error)
  }
}

export const updatePublishedProduct = async (
  { commit },
  payload,
) => {
  const { uuid, is_valid } = payload;

  try {
    commit('SET_LOADING_PRODUCT', true)

    const { data: response } = await apiClient({
      method: 'post',
      url: `/products/private/${uuid}/validity`,
      data: {
        is_valid,
      },
      params: payload,
    })

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}
