const mutations = {
  SET_RESELLER_LIST (state, payload) {
    state.reseller_list = payload
  },
  SET_LOADING_RESELLER (state, payload) {
    state.isLoading = payload
  },
  SET_SEARCH_RESELLER (state, payload) {
    state.search = payload
  },
}

export default mutations
