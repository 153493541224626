import getters from './getters'
import state from './state'
import mutations from './mutations'
import * as actions from './actions'

import discounts from './discounts'
import reseller from './reseller'
import store_settings from './store-settings'
import products from './products'
import warehouse from './warehouse'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  modules: {
    discounts,
    reseller,
    store_settings,
    products,
    warehouse,
  },
  actions,
}
