import 'ant-design-vue/lib/style/index.less' // antd core styles
import './components/kit/vendors/antd/themes/default.less' // default theme antd components
import './global.scss' // app & third-party component styles

import Vue from 'vue'
import VuePageTitle from 'vue-page-title'
import NProgress from 'vue-nprogress'
import { Plugin } from 'vue-fragment'

import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from './i18n'
import Antd from './antd'

import MomentPlugin from '@/plugins/moment'
import Scrollspy from 'vue2-scrollspy'

import VueMeta from 'vue-meta'
import './vee-validate'

import VueLogger from 'vuejs-logger'
import Keycloak from 'keycloak-js'

import './registerServiceWorker'
import { useCurrency } from './composable/useCurrency'
import IdleVue from 'idle-vue'
import VueCropper from 'vue-cropper'

const { format } = useCurrency()
Vue.filter('currency', (value, currency = 'IDR') => format(value, currency))
// Vue.use(VueCurrencyFilter, {
//   symbol: 'Rp',
//   thousandsSeparator: '.',
//   fractionCount: 0,
//   fractionSeparator: ',',
//   symbolPosition: 'front',
//   symbolSpacing: true,
// })
// use default options
Vue.use(Scrollspy)
Vue.use(MomentPlugin)
Vue.use(Antd)
Vue.use(VueCropper)
Vue.use(VueMeta)
Vue.use(NProgress)
Vue.use(VuePageTitle, {
  router,
})

Vue.use(Plugin)

Vue.config.productionTip = false
const nprogress = new NProgress({ parent: 'body' })
Vue.prototype.$defaultImage = 'https://ik.imagekit.io/powerbiz/no-image.png'

Vue.directive('on-invisible-top', {
  bind(el, binding) {
    const bounding = () => {
      if (!window) return

      const rect = el.getBoundingClientRect()

      const out = rect.bottom <= 0

      if (out) {
        binding.value()
      }
    }

    window.addEventListener('scroll', bounding)
  },
})

const options = {
  isEnabled: true,
  logLevel: Vue.config.productionTip ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
}
Vue.use(VueLogger, options)

const initOptions = {
  // url: 'http://localhost:8080', realm: 'PowerRanger', clientId: 'vue-app', onLoad: 'login-required',
  // url: 'http://dev.auth.portal.powerbiz.asia', realm: 'PowerBiz-DEV', clientId: 'powerbiz',
  // url: 'http://dev.auth.portal.powerbiz.asia', realm: 'PowerBiz', clientId: 'powerbiz-cm-dev',
  // url: `${process.env.VUE_APP_KEYCLOAK_URL}`, realm: 'PowerBiz', clientId: `${process.env.VUE_APP_CLIENT_ID_KEYCLOAK}`,
  // url: 'https://rp-pca.auth.portal.powerbiz.asia',
  // realm: 'PowerBiz-DEV',
  // clientId: 'powerbiz',
  url: `${process.env.VUE_APP_KEYCLOAK_URL}`,
  realm: `${process.env.VUE_APP_KEYCLOAK_REALM}`,
  clientId: `${process.env.VUE_APP_KEYCLOAK_CLIENT_ID}`,
}

const keycloak = new Keycloak(initOptions)
Vue.prototype.$keycloak = keycloak
router.beforeEach((to, from, next) => {
  if (
    (to.meta.authRequired === true || to.meta.title === 'Powerbiz - Sign In') &&
    !keycloak.authenticated
  ) {
    return keycloak.login()
  }

  if (
    (to.meta.authRequired === false || to.meta.title === 'Powerbiz - Sign In') &&
    keycloak.authenticated
  ) {
    return next('/dashboard')
  }

  // if (
  //   to.meta.authRequired === true &&
  //   to.meta.title === 'User Confirm Invitation' &&
  //   keycloak.authenticated
  // ) {
  //   return next('/auth/invite/:uuid')
  // }

  next()
})

Vue.use(IdleVue, {
  store,
  // idleTime: 300000, // 5 Minutes
  idleTime: 86400000, // 5 Minutes Dev
  // idleTime: 10000, // 10 Seconds "TEST"
  startAtIdle: false,
})

keycloak
  .init({ onLoad: 'check-sso' })
  .then((auth) => {
    if (!auth) {
      Vue.$log.warn('Unauthenticated')
    } else {
      Vue.$log.info('Authenticated')
    }

    // const app = new Vue({
    //   render: h => h(App),
    //   router: router
    // });

    new Vue({
      router,
      store,
      nprogress,
      keycloak,
      i18n,
      render: (h) => h(App),
    }).$mount('#app')

    // TODO: Maybe dont store the token in the localstore, rather use it direct from the keycloak.token object
    localStorage.setItem('vue-token', keycloak.token)
    localStorage.setItem('vue-refresh-token', keycloak.refreshToken)

    setInterval(() => {
      const whiteListURLIgnoreKeycloakAuth = !['self-register', 'forgot-password'].includes(!router?.currentRoute?.path?.split('/'))
      if (whiteListURLIgnoreKeycloakAuth) {
        const expiry = keycloak.isTokenExpired()
        if (expiry === true) {
          localStorage.removeItem('idle')
          keycloak.logout()
        }
        if (localStorage.getItem('idle') === null) {
          keycloak.updateToken(70)
          // .then((refreshed) => {
          //   if (refreshed) {
          //     console.log('Token refreshed')
          //   } else {
          //     console.log(
          //       'Token not refreshed, valid for ' +
          //         Math.round(
          //           keycloak.tokenParsed.exp +
          //             keycloak.timeSkew -
          //             new Date().getTime() / 1000,
          //         ) +
          //         ' seconds',
          //     )
          //   }
          // })
          // .catch(() => {
          //   console.log('Failed to refresh token')
          // })
        }
      }
    }, 6000)
  })
  .catch(() => {
    Vue.$log.error('Authenticated Failed')
  })

// new Vue({
//   router,
//   store,
//   nprogress,
//   i18n,
//   render: (h) => h(App),
// }).$mount('#app')
